import i18n from 'services/server/functions/i18n';
import { AppColors } from '../components/themes';
import { getLastWeekRange, getLastMonthRange, getLastDay } from 'features/helpers/dateHelper';

export const getDaysFilters = () => [
    {
        label: i18n.resolve('StudyLanding.select-period-lastDay'),
        value: getLastDay()
    },
    {
        label: i18n.resolve('StudyLanding.select-period-lastWeek'),
        value: getLastWeekRange()
    },
    {
        label: i18n.resolve('StudyLanding.select-period-lastMonth'),
        value: getLastMonthRange()
    },
    {
        label: i18n.resolve('StudyLanding.select-period-all'),
        value: ''
    },
];

export const getStatusFilters = () => [
    {
        label: i18n.resolve(`fulfilment.order.status.PENDING`),
        bg: AppColors.lightAcuYellow,
    },
    {
        label: i18n.resolve(`fulfilment.order.status.IN_PROGRESS`),
    },
    {
        label: i18n.resolve(`fulfilment.order.status.COMPLETED`),
        bg: AppColors.lightAcuBlue
    },
    {
        label: i18n.resolve(`fulfilment.order.status.INCOMPLETE`),
        bg: AppColors.lightAcuRed,
        color: AppColors.acuWhite
    },
    {
        label: i18n.resolve(`global.all`),
    },
];
