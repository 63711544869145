import 'ui/theme/find.css';
import './style.css';

import * as Administration from 'modules/administration';
import * as Pages from 'modules/diagnosis';
import * as R from 'ramda';

import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import QuickFilters from 'ui/components/QuickFilters';

import Action from 'ui/components/Action';
import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Select } from 'ui/components/Select';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Patient } from 'services/server/functions/model/diagnosis/model/Patient';
import Table from 'ui/components/Table';
import {Text as I18N } from 'ui/components/Text';
import WithSnapshot from 'ui/components/WithSnapshot';
import history from 'history.js';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import DownloadStudyDetails from '../CreateStudyBatch/DownloadStudyDetails';
import { useStudies } from 'features/hooks/useStudies';

const fullfilmentLangTransform = (lang) => {
  try {
    return require(`./FulfilmentView.${lang}.json`);
  } catch {
    return require('./FulfilmentView.en.json');
  }
}
const Text = I18N.withContext({ page: "ManageFulfilment" }).withTranslations(
  ['en', 'es', 'de', 'it', 'pt'].reduce((locales, lang) => ({...locales, [lang]: _ => fullfilmentLangTransform(lang)}), {})
);

const FindStudyUI = ({status, statusCounts, organisation, healthcaresite, healthcaresites, reference, studies, onReferenceChanged, onStatusChanged, onHealthcareSiteChanged, onSelected, onUnSelected, onMarkSent }) => {
  const selected = studies.filter(s => s.selected);
  const healthcaresiteOptions  = healthcaresites?.data ? [{label: healthcaresites.data.name, value: healthcaresites.data.id}] : Object.values(healthcaresites || {}).map(s => ({label: s.data.name, value: s.data.id}));
  const isMarkSentDisabled = !studies.length || studies.every(s => !s.selected) || selected.some(s => s.status !== 'READY');

  return (
    <div id='ManageFulfilment' className='page'>
      <Back/>
      <div className='pageHeader'>
        <h1 className='title'><Text>Manage Fulfilment</Text></h1>
        <div className='actions'>
        </div>
      </div>
      <div className='pageContent'>
        <div className="pageSection">
          <div className='title'><Text>search-by</Text></div>
          <DetailsBlock>
            <div className='filter grid-row'>
              <div className="grid-col">
                <div className="grid-col">
                  <div>
                    <input id="reference" className="input" placeholder={Text.resolve("study-reference")} onChange={(e) => onReferenceChanged((e.target.value || '').toUpperCase())} value={reference || ''}/>
                  </div>                
                </div>
                <Select isClearable name="hcs" value={healthcaresite} options={healthcaresiteOptions} placeholder={Text.resolve("hcs")} onChange={e => onHealthcareSiteChanged(e.target.value)} />
              </div>
              <QuickFilters header={Text.resolve("status").concat(":")} >
                <QuickFilters.Exclusive onUnSelected={() => onStatusChanged(undefined)}>
                  {Object.keys(statusCounts).map((g, idx) =>     
                    <QuickFilters.Item key={'STUDY_STATUS' + idx} id={g.charAt(0).concat(g.slice(1).toLowerCase()).replace(" ", "")} className={g.concat(" expanded")} active={status === g} onSelected={() => onStatusChanged(g)}><Text>{g}</Text> ({statusCounts[g]})</QuickFilters.Item>
                  )}
                </QuickFilters.Exclusive>
                <QuickFilters.Select label='' className="selected collapsed" value={status} onChange={(e) => onStatusChanged(e.target.value)} options={Object.keys(statusCounts).map((key) => {return {label: Text.resolve(key).toUpperCase(), value: key}}).concat({label: Text.resolve('any').toUpperCase()})}/>
              </QuickFilters>
              <QuickFilters header="Filters:">
                <Administration.ViewOrganisation.AsLinkIcon hidden={!organisation}   {...{ organisation,   className: "quickfilter selected" }} />
                <Administration.ViewHCS.AsLinkIcon          hidden={!healthcaresite} {...{ healthcaresite, className: "quickfilter selected" }} />
              </QuickFilters>
            </div>
          </DetailsBlock>
        </div>
        <div className="pageSection">
          <div className='actions'>
            <Action.AsModal disabled={isMarkSentDisabled} heading={Text.resolve('Mark as sent')} label={Text.resolve('Mark as sent')} title={Text.resolve('Mark as sent')} secondaryAction={onMarkSent} >
              <p><Text variables={{ selected: selected.length }}>You are about to mark studies as sent</Text></p>
              <p><Text>Do you want to continue?</Text></p>
            </Action.AsModal>
            <DownloadStudyDetails.AsModalAction disabled={!studies.length || studies.every(s => !s.selected) || selected.some(s => s.status === 'PENDING')} btn studies={selected} csv={{ model: Study.queries.GET_STUDY_FULFILLMENT_DETAILS }} />
          </div>
          <Table
            i18n={Text.resolve}
            keyField='id'
            withViewAction={false}
            columns={{
              reference            : { content: "study-reference",     },
              providedPhone        : { content: "providedPhone",       },
              requiresOnboarding   : { content: "requiresOnboarding",  },
              status               : { content: "status",              formatter: s => <span className={"item expanded " + s}><Text>{s}</Text></span> },
              requestedTests       : { content: "tests",               },
              action               : {
                formatter    : (_, s) => {
                  return <div className='grid-col centered fitContent'>
                    <DownloadStudyDetails.AsModalAction btn hidden={s.status === 'PENDING'} label='Download' appearance='secondary' studies={[s]} onToggle={e => {e?.stopPropagation(); }} csv={{ model: Study.queries.GET_STUDY_FULFILLMENT_DETAILS }} />
                    <Action id='markSent' label="Mark sent" className="button secondary" handleAction={s.markSent && (e => { e?.stopPropagation(); s.markSent(); })} />
                    <Action id='open'     label="Open"      className="button secondary" handleAction={s.open && (e => { e?.stopPropagation(); s.open(); })} />
                  </div>
                },
                dataField    : "action",
                sort         : false,
              }
            }}
            selectRow = {{ 
              mode: 'checkbox',
              clickToSelect: true,
              selected: selected.map(s => s.id),
              onSelect: (row, isSelect) => { isSelect ? onSelected([row.id]) : onUnSelected([row.id]); },
              onSelectAll: (isSelect) => { isSelect ? onSelected(studies.map(s => s.id)) : onUnSelected(studies.map(s => s.id)); },
            }}
            data={ studies }
            defaultSortCol='lastUpdate'
            defaultSortDir={'desc'}
            emptyText="FindStudies.no-studies-found"
          />
        </div>
      </div>
    </div>
  );
}

const FindStudyPresenter = (props) => {
  const [currentUser]    = useCurrentUser(),
        // isAdmin          = currentUser.isAdmin(),
        // isManager        = currentUser.hasPermission(Study.roles.manager.id),
        canViewStudy     = currentUser.hasAccess(Pages.ViewStudy),
        canSendEquipment = currentUser.hasAccess(Study.commands.SEND_EQUIPMENT);
  
  const [{
    organisation  : org,
    healthcaresite: hcs,
    status        : st,
    reference     : ref,
    selected=[]
  }, setLocationState] = useLocationParams();

  const toFulfilmentStatus = status => Study.STATUS.compare(status, Study.STATUS.onboarded) < 0 ? 'PENDING' : status === Study.STATUS.onboarded ? 'READY' : 'SENT'
  const byReference        = study => ref ? Study.getReference(study.data).includes(ref) : true;
  const byStatus           = study => st  ? st === study.status : true;
  const byOwners           = study => !(hcs || org) || study.data.owners.some(o => (hcs && o === hcs)) || (org && Organisation.ownersFrom({owners: study.metadata.allOwners}).shift() === org);
  const byAllButStatus     = study => [ byReference, byOwners    ].every(f => f(study));
  
  const toTable  = ({data: { id, activationCode, patient, status, requestedTests, owners }}) => ({
    id,
    owners,
    activationCode, 
    requestedTests, 
    selected           : selected.includes(id),
    reference          : Study.getReference({ activationCode }),
    status             : toFulfilmentStatus(status), 
    providedPhone      : patient.instructions.providedPhone  ? 'Y':'N', 
    requiresOnboarding : Patient.requiresOnboarding(patient) ? 'Y':'N',
    markSent           : (canSendEquipment && status === Study.STATUS.onboarded) ? _ => props.markSent({id}) : undefined,
    open               : canViewStudy ? _ =>  history.push(Pages.ViewStudy.PATH, {study: id}) : undefined,
  });
  
  const all = Object.values(props.snapshots)
                    .filter(s => Patient.requiresFulfillment(s.data.patient))
                    .filter(s => Study.STATUS.compare(s.data.status, Study.STATUS.sent) <= 0)
                    .filter(byAllButStatus)
                    .map(toTable);

  const studies      = all.filter(byStatus);
  const statusCounts = {
    PENDING: 0, READY: 0, SENT: 0,
    ...R.countBy(R.prop('status'))(all)
  };

  const [healthcaresites] = useSnapshot(HealthcareSite, studies.map(s => HealthcareSite.ownersFrom(s).shift()).filter((h, i, a) => a.indexOf(h) === i)); 
  // TODO: ugly?. it returns an object sometimes, and a instance other times ... useSnapshot should probably be more consistent, aligned with arguments ?
  // examples:
  //   [instance]                       = useSnapshot(HealthcareSite, "urn:..."); 
  //   [[instance]]                     = useSnapshot(HealthcareSite, ["urn:..."]);
  //   [instance, instance, instance ]  = useSnapshot(HealthcareSite, "urn:...", "urn:..", "urn:...");
  //   [[instance, instance, instance] ]  = useSnapshot(HealthcareSite, ["urn:...", "urn:..", "urn:..."]);
  //   [{ "urn:...": instance, "urn:...": instance }] = useSnapshot(HealthcareSite, { "urn:...": instance, "urn:...": instance }); ???

  const setReference      = reference      => setLocationState({ reference,      selected: [] })
  const setHealthcareSite = healthcaresite => setLocationState({ healthcaresite, selected: [] })
  const setStatus         = status         => setLocationState({ status,         selected: [] });
  const onSelected        = ids            => setLocationState({ selected: [...selected, ...ids].filter((id, idx, a) => a.indexOf(id) === idx) });
  const onUnSelected      = ids            => setLocationState({ selected: selected.filter(id => !ids.includes(id)) });

  const markSentBatch = () => { // TODO: handle errors properly
    return props.markSentBatch(studies.filter(s => s.selected).map(({id}) => ({id})))
                .then(rs => rs.filter(r => r.status === "fulfilled").map(r => r.value.data.id))
                .then(onUnSelected);
  }

  return <FindStudyUI {...{
      healthcaresite          : hcs,
      healthcaresites,
      onHealthcareSiteChanged : setHealthcareSite,
      onReferenceChanged      : setReference,
      onStatusChanged         : setStatus,
      onMarkSent              : canSendEquipment ? markSentBatch : undefined,
      onSelected,
      onUnSelected, 
      organisation            : org,
      reference               : ref,
      status                  : st,
      statusCounts,
      studies,
    }} />;
};

const ManageFulfilment = WithSnapshot(Study, undefined, undefined, useStudies)(FindStudyPresenter);
ManageFulfilment.displayName = 'ManageFulfilment';
ManageFulfilment.roles = [ Study.roles.manager.id ]; // TODO: properly configure roles and tesst it properly
export default ManageFulfilment;