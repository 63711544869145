import FileDrop from 'ui/components/FileDrop';
import { useEffect, useState, useCallback, useContext } from 'react';
import { UserContext } from 'features/providers/userContextProvider';
import { Text } from 'ui/components/Text';
import { Product, HealthcareSite } from 'services/server/functions/model/administration/model';
import { User } from 'services/server/functions/model/authentication/model';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Select } from 'ui/components/Select';
import useSnapshot from 'ui/hooks/useSnapshot';
import Spinner from '@atlaskit/spinner';
import Action from 'ui/components/Action';
import DownloadStudyDetails from './DownloadStudyDetails';
import ReactTooltip from 'react-tooltip';
import Table from 'ui/components/Table';

import './style.css';
import useProjection from 'ui/hooks/useProjection';
import { EditCSVRow } from './EditCSVRow';
import { groupBy } from 'services/server/functions/helpers/utils';
import Input from 'ui/components/Input';
import useCurrentUser from 'ui/hooks/useCurrentUser';

const R = require('ramda');
const set = (field, value, object) => R.set(R.lensPath(field.split('.')), value, object);
const get = (field, object) => R.view(R.lensPath(field.split('.')), object);

const getDownloadCSVProp = (model, csv, batchId, currentUser) => {
  const csvProp = { model, fields: [...csv.metadata?.fields || [], ...model.describe(model.schema.tailor('csv').tailor('export')).fields.flatten().withMeta(meta => meta?.csv?.header).map(f => f.field)].filter((f, i, a) => a.indexOf(f) === i), prefs: { context: { delimiter: csv?.metadata?.delimiter } } };
  if (currentUser.isFromAcurable() && batchId?.length) csvProp.append = { batchId };
  return csvProp;
}

export default ({ createStudyBatch, I18n }) => {
  const { hasRole } = require('services/server/functions/iam');
  const Study = require('services/server/functions/model/diagnosis/model').Study;
  const { selectedProduct } = useContext(UserContext);
  const [currentUser] = useCurrentUser();
  const model = Study.commands.CREATE_STUDY;
  const schema = model.schema.tailor('csv').tailor('import').prefs({ abortEarly: false, noDefaults: true }); // defaults comes from the HCS settings, and not the model! TODO: we should remove "some/all" defaults from the model ?

  // CSV template
  const { fields } = model.describe(schema);
  const template = fields.flatten().withMeta(meta => meta?.csv?.header).map(f => f.field).join(',');
  const url = URL.createObjectURL(new Blob([Buffer.from(template)], { type: 'text/csv' }));
  useEffect(() => () => { URL.revokeObjectURL(url) }, [url]);

  const [sites, setSites] = useState([]);
  const [hcsToSettings, loadingHCSSettings, _errors, _start, _stop, reset] = useProjection(sites.map(s => ({
    id: s,
    queryRequest: Product.queries.GET_INHERITED_PREFERENCES.newRequest({ ownerId: HealthcareSite.entities.Product.newURN(s.split("/").pop(), selectedProduct.key), preferenceId: 'StudyConfigSettings' }),
  })));

  const [siteSnaps = {}, loadingSites] = useSnapshot(HealthcareSite);
  const allSiteSnaps = Object.values(siteSnaps);
  const [usersSnaps = {}, loadingClinicians] = useSnapshot(User);
  const allClinicianSnaps = Object.values(usersSnaps);
  const [batchId, setBatchId] = useState(`${(new Date().toISOString()) + (currentUser.isFromAcurable() ? "_Acu" : "")}`);
  const [csv, setCsv] = useState({ rows: [], metadata: {}, errors: [] });
  const onCsvUpload = (rows, metadata) => {
    if (sites.length) setSites([]);
    setCsv({ rows, metadata, errors: [] });
  };

  const [overrides = {}, setOverrides] = useState({});
  const { site, clinician } = overrides;
  const setOverride = e => setOverrides(state => {
    if (e.target.value === get(e.target.field, state)) {
      return state;
    } else {
      // TODO: this might need to change
      if (['site', 'clinician'].includes(e.target.field)) { // any changes to these will reset the whole process
        setCsv({ rows: [], metadata: {}, errors: [] });
        return { site: state.site, clinician: undefined, [e.target.field]: e.target.value };

      } else {
        return set(e.target.field, e.target.value, state);
      }
    }
  });

  const isClinicianCompatible = (site) => (u) => u.data.status !== User.STATUS.disabled && !u.metadata.impersonator && u.data.owners.includes(site) && hasRole(Study.roles.CREATE_STUDY.id)(u.data.roles);

  const getHCS = useCallback((row) => {
    const errors = [];
    if (siteSnaps[row.instance.site]) return [siteSnaps[row.instance.site], errors];
    if (row.data.siteName) {
      const siteSnap = allSiteSnaps.find(s => s.data.name === row.data.siteName);
      if (!siteSnap) {
        if (row.errors.some(e => e.field === "siteName")) return [undefined, errors];
        errors.push({
          row: row.row,
          type: "custom.invalid",
          field: "siteName",
          message: "HealthcareSite could not be found",
        });
        return [undefined, errors];
      }
      return [siteSnap, errors];
    }
    if (site) {
      return [siteSnaps[site], errors];
    }
    return [undefined, errors];
  }, [siteSnaps, site]);

  const getClinician = useCallback((siteSnap, row) => {
    const errors = [];
    const isClinicianValid = isClinicianCompatible(siteSnap?.aggregate.id);
    if (usersSnaps[row.instance.clinician] && isClinicianValid(usersSnaps[row.instance.clinician])) return [usersSnaps[row.instance.clinician], errors];
    if (row.data.clinicianEmail) {
      const clinicianSnap = allClinicianSnaps.find(s => s.data.mail === row.data.clinicianEmail);
      if (!clinicianSnap) {
        if (row.errors.some(e => e.field === "clinicianEmail")) return [undefined, errors];
        errors.push({
          row: row.row,
          type: "custom.invalid",
          field: "clinicianEmail",
          message: "Clinician could not be found",
        });
        return [undefined, errors];
      }
      if (!isClinicianValid(clinicianSnap)) {
        errors.push({
          row: row.row,
          type: "custom.invalid",
          field: "clinicianEmail",
          message: "Clinician does not belong to the site provided",
        });
        return [undefined, errors];
      }
      return [clinicianSnap, errors];
    }
    if (clinician) {
      if (!isClinicianValid(usersSnaps[clinician])) {
        errors.push({
          row: row.row,
          type: "custom.invalid",
          field: "clinicianEmail",
          message: "Default clinician does not belong to the site provided",
        });
        return [undefined, errors];
      }
      return [usersSnaps[clinician], errors];
    }
    return [undefined, errors];
  }, [usersSnaps, clinician]);

  // TODO: make more efficient
  const rowHasChanges = (originalRow, newRow) => {
    return JSON.stringify(originalRow.instance) !== JSON.stringify(newRow.instance) ||
      originalRow.invalid !== newRow.invalid ||
      JSON.stringify(originalRow.errors) !== JSON.stringify(newRow.errors) ||
      JSON.stringify(originalRow.missingFields) !== JSON.stringify(newRow.missingFields) ||
      JSON.stringify(originalRow.invalidFields) !== JSON.stringify(newRow.invalidFields);
  };

  const customValidations = (row, siteSnap, siteSettings = {}, clinicianSnap) => {
    const globals = { settings: siteSettings };
    const instance = R.mergeDeepRight(R.mergeDeepRight(globals, row.instance), row.settings);
    const { value, error } = schema.validate(instance) // TODO move validation out of the CSV component
    value.siteName = siteSnap?.data.name;
    value.clinicianEmail = clinicianSnap?.data.mail;
    if (value.freezeTestCancelledStatus === undefined) delete value.freezeTestCancelledStatus;

    let errors = [
      ...row.errors.filter(e => !e.schemaError && !e.type.startsWith('custom.')),
      ...(error?.details || []).map(({ type, path, message }) => ({ row: row.row, type, field: path.join('.'), message, schemaError: true, })) // new errors if any
    ];
    const missing = errors.filter(e => e.type.endsWith(".required")).map(f => f.field).filter((f, i, a) => a.indexOf(f) === i);
    const unknown = errors.filter(e => e.type.endsWith(".unknown")).map(f => f.field).filter((f, i, a) => a.indexOf(f) === i);
    const invalid = errors.filter(e => !e.type.endsWith(".unknown")).map(f => f.field).filter((f, i, a) => f && a.indexOf(f) === i);
    errors = errors.filter(e => !e.type.endsWith(".unknown")); // actual errors we care about    
    const newRow = { ...row, instance: value, invalid: Boolean(errors.length), errors, missingFields: missing, unknownFields: unknown, invalidFields: invalid };

    console.log("customValidations", row, row.row, row.errors);
    if (rowHasChanges(row, newRow)) return [newRow, true];
    return [newRow, false];
  };

  useEffect(() => {
    const newSites = new Set([...sites]);

    csv.rows.forEach(row => {
      const [siteSnap] = getHCS(row);
      if (siteSnap && !newSites.has(siteSnap.aggregate.id)) {
        newSites.add(siteSnap.aggregate.id);
      }
    });

    if (sites.length !== newSites.size || sites.some(s => !newSites.has(s))) {
      // retrieves the settings of each hcs
      setSites([
        ...newSites,
      ]);
    }
  }, [csv, sites, hcsToSettings, getHCS, getClinician]);

  useEffect(() => {
    let hasChanges = false;
    csv.rows.forEach(row => {
      if (row.errors.length) return;
      const [siteSnap, siteErrors] = getHCS(row);
      if (siteSnap && siteSnap.aggregate.id !== row.instance.site) {
        row.instance.site = siteSnap.aggregate.id;
        hasChanges = true;
      }
      const [clinicianSnap, clinicianErrors] = getClinician(siteSnap, row);
      if (clinicianSnap && clinicianSnap.aggregate.id !== row.instance.clinician) {
        row.instance.clinician = clinicianSnap.aggregate.id;
        hasChanges = true;
      }
      const [newRow, withChanges] = customValidations(row, siteSnap, hcsToSettings[siteSnap?.aggregate.id], clinicianSnap);
      if (siteErrors.length || clinicianErrors.length) {
        newRow.errors = [...siteErrors, ...clinicianErrors];
        hasChanges = true;
      }
      csv.rows[newRow.row] = newRow;
      hasChanges = hasChanges || withChanges;
    });

    if (hasChanges) {
      setCsv(csv => ({ ...csv }));
    }
  }, [sites, hcsToSettings, getHCS, getClinician]);

  useEffect(() => {
    reset();
  }, [sites]);

  const isClinicianValid = isClinicianCompatible(site);
  const clinicians = Object.values(usersSnaps || {})
    .filter(u => isClinicianValid(u))

  const siteOptions = Object.values(siteSnaps || {}).map(s => ({ label: s.data.name, value: s.data.id })).sort((s1, s2) => s1.label.localeCompare(s2.label));
  const clinicianOptions = clinicians.map(c => ({ label: [c.data.lastName, c.data.name].join(', '), value: c.data.id }));

  const validate = ({ row, data, settings = {}, errors = [], ...other }) => {
    const instance = R.mergeDeepRight(data, settings);
    const { value, error } = schema.validate(instance) // TODO move validation out of the CSV component
    if (value.freezeTestCancelledStatus === undefined) delete value.freezeTestCancelledStatus;

    errors = [
      ...errors.filter(e => !e.schemaError && !e.type.startsWith('custom.')), // keep other type of errors not related to the schema validation, removes old errors asociated with this row 
      ...(error?.details || [])
        .filter(e => !e.message.endsWith("clinician, clinicianEmail]") && !e.message.endsWith("site, siteName]")) // these are optional as we allow for manual input
        .map(({ type, path, message }) => ({ row, type, field: path.join('.'), message, schemaError: true })), // new errors if any
    ];

    const missing = errors.filter(e => e.type.endsWith(".required")).map(f => f.field).filter((f, i, a) => a.indexOf(f) === i);
    const unknown = errors.filter(e => e.type.endsWith(".unknown")).map(f => f.field).filter((f, i, a) => a.indexOf(f) === i);
    const invalid = errors.filter(e => !e.type.endsWith(".unknown")).map(f => f.field).filter((f, i, a) => f && a.indexOf(f) === i);

    errors = errors.filter(e => !e.type.endsWith(".unknown")); // actual errors we care about    
    return ({ ...other, row, instance: value, data, settings, invalid: Boolean(errors.length), errors, missingFields: missing, unknownFields: unknown, invalidFields: invalid })
  }

  const runRowValidations = (rowIndex) => {
    const csvRow = csv.rows[rowIndex];
    const [siteSnap] = getHCS(csvRow);
    const validations = customValidations(csvRow, siteSnap, hcsToSettings[siteSnap.aggregate.id], getClinician(siteSnap, csvRow)[0]);
    return validations;
  };

  const updateRowSettings = ({ row, instance, settings }) => e => {
    if (get(e.target.field, instance) !== e.target.value) {
      setCsv(csv => {
        csv.rows[row].settings = set(e.target.field, e.target.value, settings);
        csv.rows[row] = runRowValidations(row)[0];
        return { ...csv };
      });
    }
  }

  const updateRowField = (row, fieldPath, value) => {
    const rowIndex = row.row;
    const currentValue = get(fieldPath, row);
    if (currentValue !== value) {
      csv.rows[rowIndex] = set(fieldPath, value, csv.rows[rowIndex]);
      csv.rows[rowIndex] = runRowValidations(rowIndex)[0];
      return setCsv(csv => ({ ...csv }));
    }
  };

  const [showInvalid, setShowInvalid] = useState(false);

  const columns = csv.metadata?.fields?.reduce((cols, field) => ({
    ...cols, [`instance.${field}`]: {
      content: field, isSortable: false,
      headerClasses: csv.rows.some(row => row.unknownFields.includes(field)) ? 'ignored' : '',
      classes: (_, row) => row.unknownFields.includes(field) ? 'ignored' : row.invalidFields.includes(field) ? 'withError' : '',
      formatter: (cell, row, rowIdx) => {
        const msgs = row.errors.filter(e => e.field === field).map(e => e.message);
        return !msgs.length ? cell : <>
          <span data-tip data-for={`error${rowIdx}${field}`} className={'withError'}>{cell}</span>
          <ReactTooltip className="errorTooltip" borderColor="rgb(255, 216, 216)" backgroundColor="rgb(255, 216, 216)" id={`error${rowIdx}${field}`} place="top" type="light" effect="solid" border multiline>
            {msgs.map((m, i) => <span key={i}><Text>{m}</Text></span>)}
          </ReactTooltip>
        </>
      }
    }
  }), { row: { content: 'row', isSortable: true, } });

  const submit = () => {
    setCsv(c => {
      csv.rows.filter(r => r.status !== 'fulfilled').forEach(r => r.status = 'pending');
      return { ...c }
    });

    const onProgress = (result, row) => {
      setCsv(c => {
        c.rows[row].status = result.status
        c.rows[row].invalid = result.status !== 'fulfilled';
        if (c.rows[row].invalid) {
          c.rows[row].errors.push({ row, type: result.reason?.data?.code, field: 'N/A', message: result.reason?.data?.details }) // declaring "field" would make de validation to remove this error. not ideal solution
        } else {
          c.rows[row].instance = R.mergeDeepRight(c.rows[row].instance, result.value.data);
        }
        return { ...c }
      });
    }

    // Commented to avoid fulfilment code being executed
    // const onOrderProgress = (result, row) => {
    //   setCsv(c => {
    //     if (result.status === 'fulfilled') {
    //       c.rows[row].instance.orderReference = result.value.data.reference;
    //     } else {
    //       console.error(`Failed creating order for study`, {
    //         row,
    //         type: result.reason?.data?.code,
    //         message: result.reason?.data?.details
    //       });
    //     }
    //     return {...c}
    //   });
    // };

    const metadata = batchId?.length ? { batchId } : {};
    return createStudyBatch(csv.rows.map(({ row, status, instance }) => status !== 'fulfilled' ? ({ key: row, productId: selectedProduct?.id, ...instance, metadata }) : undefined).filter(Boolean), onProgress, undefined/*, onOrderProgress*/);
  }

  // csv.rows = csv.rows.map(r => r.status === 'fulfilled' ? r : validate(r)); // keep IDs, etc .. revalidate will "destry" the instance
  csv.errors = csv.rows.reduce((a, r) => [...a, ...r.errors], []);

  const invalidRows = csv.rows.filter(r => r.invalid)
  const createdRows = csv.rows.filter(r => r.status === 'fulfilled');

  const siteToRows = groupBy(csv.rows, 'instance.site');

  const handleSiteChanges = ({ target }) => {
    setOverride({ target: { ...target, field: target.name } });
  };

  return (
    <div className='pageSection'>
      <DetailsBlock id='DefaultSettings'>
        <div className='header'>
          <div className='title'><Text>Default Settings</Text></div>
        </div>
        <div className='grid-row vcentered'>
          <div className='grid-col vcentered'>
            <Details id="siteField" label="hcs" help={siteOptions.length === 0 ? <span><Text>CreateStudy.only-users-hcs</Text><br /><Text>CreateStudy.if-an-expected-hcs</Text></span> : undefined}>
              {loadingSites ? <Spinner size="small" />
                : <Select
                  name="site"
                  options={siteOptions}
                  placeholder={Text.resolve("CreateStudy.select-hcs")}
                  isSearchable={true}
                  isSortable={true}
                  onChange={handleSiteChanges}
                  value={site} />}
            </Details>
            <Details id="clinicianField" label="global.clinician.label" help={clinicianOptions.length === 0 ? <span><Text>CreateStudy.only-users-study</Text><br /><Text>CreateStudy.if-an-expected-clinician</Text></span> : undefined}>
              {loadingClinicians ? <Spinner size="small" />
                : <Select
                  disabled={!site}
                  name="clinician"
                  options={clinicianOptions}
                  placeholder={Text.resolve("CreateStudy.select-clinician")}
                  isSearchable={true}
                  onChange={({ target }) => setOverride({ target: { ...target, field: target.name } })}
                  value={clinician} />}
            </Details>
            {currentUser.isFromAcurable() ? <Details id="batchIdField" label="global.batchId.label" >
              <Input
                onChange={(e) => { setBatchId(e.target.value); }}
                value={batchId}
              />
            </Details> : <></>}
          </div>
          <Details id="FileDrop">
            <FileDrop.CSV id="csv" transformRow={validate} onComplete={onCsvUpload} annotation={<Text variables={{ "template-link": <a id='studiesTemplateLink' href={url} download="studies-template.csv">{I18n("template-link")}</a> }} context={{ "page": "CreateStudyBatch" }}>instructions</Text>} />
          </Details>
        </div>
      </DetailsBlock>
      <DetailsBlock id="Errors" hidden={!csv.errors.length} >
        <div className='header'>
          <div className='title'><Text>Errors</Text></div>
        </div>
        <div className='grid-col'>
          <Details id="Table">
            <Table
              id="Errors"
              withViewAction={false}
              columns={['row', 'type', 'field', 'message'].reduce((a, c) => ({ ...a, [c]: { content: c } }), {})}
              data={csv.errors}
              keyField={'row'}
              defaultSortCol={'row'}
              defaultSortDir={'asc'}
              emptyText="No Errors found"
            />
          </Details>
        </div>
      </DetailsBlock>

      <DetailsBlock id="Batch" hidden={!csv.rows.length}>
        <div className='header'>
          <div className='title'><Text>Batch</Text></div>
          <div className='actions'>
            <DownloadStudyDetails.AsModalAction btn appearance='primary' hidden={!createdRows.length} data={createdRows.map(r => r.instance)} csv={getDownloadCSVProp(model, csv, batchId, currentUser)} />
            <Action hidden={createdRows.length === csv.rows.length} disabled={invalidRows.length} handleAction={submit} label={Text.resolve("Create Batch")} className={`button primary`} />
            <Action hidden={!invalidRows.length} handleAction={_ => setShowInvalid(!showInvalid)} label={showInvalid ? Text.resolve('Show All') : `${Text.resolve('Show Invalid')} (${invalidRows.length})`} className={`button primary`} />
          </div>
        </div>
        {
          Object.keys(siteToRows).map(siteId => {
            const rows = siteToRows[siteId];
            const invalidRows = rows.filter(r => r.invalid);
            const hasSite = siteId !== "undefined";
            return <>
              <h2 style={{ color: hasSite ? 'black' : 'red' }}>{!hasSite ? "Undefined HealthcareSites" : siteSnaps[siteId]?.data.name || siteId}</h2>
              <Details id="Data">
                <Table
                  id='CSV'
                  i18n={_ => _}
                  keyField={'row'}
                  withViewAction={false}
                  columns={columns}
                  data={showInvalid && invalidRows.length ? invalidRows : rows}
                  rowClasses={row => row.invalid ? 'withError' : row.status || ''}
                  expandRow={{
                    showExpandColumn: true,
                    onlyOneExpanding: true,
                    renderer: row => <EditCSVRow
                      row={row}
                      loadingSites={loadingSites}
                      loadingClinicians={loadingClinicians}
                      loadingHCSSettings={loadingHCSSettings}
                      siteOptions={siteOptions}
                      usersSnaps={usersSnaps}
                      onSiteChange={(siteId) => updateRowField(row, "instance.site", siteId)}
                      onClinicianChange={(clinicianId) => updateRowField(row, "instance.clinician", clinicianId)}
                      onStudySettingsChange={updateRowSettings(row)}
                      settings={hcsToSettings[row.instance.site]}
                    />
                  }}
                  defaultSortCol={'row'}
                  defaultSortDir={'asc'}
                  emptyText="Empty"
                />
              </Details>
            </>;
          })
        }
      </DetailsBlock>
    </div>
  );
}