import { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';

import history from 'history.js';
import * as Pages from '../../index';

import { Text } from "ui/components/Text";
import { FlexBox, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";

import '../styles.css';
import { Table } from "features/fulfilment/components/molecules/Table";
import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import { SectionInfo } from "features/fulfilment/components/atoms/SectionInfo";
import i18n from "services/server/functions/i18n";

import { KitField } from "features/fulfilment/components/molecules/ProcessOrder/Fields/Kit";
import { OrderField } from "features/fulfilment/components/molecules/ProcessOrder/Fields/Order";
import { PhoneField } from "features/fulfilment/components/molecules/ProcessOrder/Fields/Phone";

import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";

import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

import { getScannedOrdersColumns } from "./tableConfig";
import { Device } from 'services/server/functions/model/devices/model';
import FulfilmentConfig from "services/server/functions/model/fulfilment/config";
import { Kit, Order } from "services/server/functions/model/fulfilment/model";
import { getItemsRequiredByStudy, isKitTemplateValidForItems } from 'services/server/functions/model/fulfilment/connector/study.connector';

export const ScanStep = ({
    goToPreviousStep, kits = [], orders = [], studiesSnaps = {}, phones, processOrder
}) => {
    const [kitReference, setKitReference] = useState(null);
    const [orderReference, setOrderReference] = useState(null);
    const [phoneReference, setPhoneReference] = useState(null);

    const [validKits, setValidKits] = useState([]);
    const [validOrders, setValidOrders] = useState([]);

    const [kitOptions, setKitOptions] = useState([]);
    const [orderOptions, setOrderOptions] = useState([]);
    const [phoneOptions, setPhoneOptions] = useState([]);

    const [requiresPhone, setRequiresPhone] = useState(false);

    const [scannedOrderedRows, setScannedOrderedRows] = useState([]);

    const [enableAddButton, setEnableAddButton] = useState(false);

    const clearInputs = () => {
        [setKitReference, setOrderReference, setPhoneReference].forEach(fn => fn(null));
    };

    const startProcessingOrder = async () => {
        const devices = requiresPhone ? [Device.newURN(phoneReference)] : [];
        const processedEvent = await processOrder({ reference: orderReference, kitReference, devices });
        const selectedOrder = orders.find(o => o.reference === orderReference);
        const kit = kits.find(k => k.id === processedEvent.data.kit);
        const scannedOrder = {
            id: selectedOrder?.id || processedEvent.data.id,
            orderReference: selectedOrder?.reference || orderReference,
            kitReference: kit?.reference,
            shippingOutbound: selectedOrder.shipping.outbound,
            shippingInbound: selectedOrder.shipping.inbound,
            phoneReference,
            template: {
                name: kit?.template.name,
                hexColorCode: kit?.template.hexColorCode,
            },
        };
        setScannedOrderedRows([...scannedOrderedRows, scannedOrder]);
        clearInputs();
    };

    const getSelectedStudySnap = useCallback(() => {
        const selectedOrder = orders.find(o => o.reference === orderReference);
        const studySnap = studiesSnaps[selectedOrder?.owner];
        return studySnap;
    }, [orders, studiesSnaps, orderReference]);

    const getCompatibleKits = useCallback((studySnap) => {
        const kitContent = getItemsRequiredByStudy(studySnap);
        const compatibleKits = validKits.filter(k => isKitTemplateValidForItems(k.template, kitContent));
        return compatibleKits;
    }, [validKits]);

    const setCompatibleKitOptions = useCallback((studySnap) => {
        let compatibleKits = validKits;
        if (studySnap) {
            compatibleKits = getCompatibleKits(studySnap);
        }
        setKitOptions(compatibleKits.map(k => k.reference));
        return { compatibleKits };
    }, [validKits, getCompatibleKits]);

    // We don't want this callback to depend on kitReference, otherwise it will cause unwanted behavior
    const canSelectedKitReferenceBeCompatible = useCallback((compatibleKits, kitReference) => {
        if (kits.some(k => k.reference === kitReference)) {
            return compatibleKits.some(k => k.reference === kitReference);
        }
        return true;
    }, [kits]);

    const isKitValidForOrder = useCallback((studySnap, kit) => {
        const kitContent = getItemsRequiredByStudy(studySnap);
        return isKitTemplateValidForItems(kit.template, kitContent);
    });

    const getCompatibleOrders = useCallback((kit) => {
        // The studySnap must be there in order for the order to be compatible
        const compatibleOrders = validOrders.filter(o => studiesSnaps[o.owner] ? isKitValidForOrder(studiesSnaps[o.owner], kit) : false);
        return compatibleOrders;
    }, [validOrders, studiesSnaps]);

    const setCompatibleOrderOptions = useCallback(() => {
        let compatibleOrders = validOrders;
        if (kitReference) {
            compatibleOrders = getCompatibleOrders(kits.find(k => k.reference === kitReference));
        }
        setOrderOptions(compatibleOrders.map(o => o.reference));
        return { compatibleOrders };
    }, [validOrders, kitReference, getCompatibleOrders]);

    // We don't want this callback to depend on orderReference, otherwise it will cause unwanted behavior
    const canSelectedOrderReferenceBeCompatible = useCallback((compatibleOrders, orderReference) => {
        if (orders.some(o => o.reference === orderReference)) {
            return compatibleOrders.some(o => o.reference === orderReference);
        }
        return true;
    }, [orders]);

    const shouldRequirePhone = useCallback(() => {
        const studySnap = getSelectedStudySnap();
        if (studySnap) {
            return Boolean(studySnap?.data.patient.instructions.providedPhone);
        }
        const kit = kits.find(k => k.reference === kitReference);
        const includesPhone = kit?.template.items?.includes(FulfilmentConfig.items.phoneSIM.id);
        // if the provided kit is unkown then we trust whatever the order states
        return kit && includesPhone;
    }, [kits, kitReference, getSelectedStudySnap]);

    const updateRequiresPhone = useCallback(() => {
        const needsPhone = shouldRequirePhone();
        if (requiresPhone !== needsPhone) setRequiresPhone(needsPhone);
    }, [requiresPhone, shouldRequirePhone]);

    const resetKitReferenceIfIncompatible = useCallback((kitReference) => {
        const { compatibleKits } = setCompatibleKitOptions(getSelectedStudySnap());
        if (kitReference && !canSelectedKitReferenceBeCompatible(compatibleKits, kitReference)) {
            console.log("Clearing Kit because it's no longer compatible", kitReference);
            setKitReference(null);
        }
        // We want to exclude kitReference from the dependency array
    }, [getSelectedStudySnap, canSelectedKitReferenceBeCompatible])

    const resetOrderReferenceIfIncompatible = useCallback((orderReference) => {
        const { compatibleOrders } = setCompatibleOrderOptions();
        if (orderReference && !canSelectedOrderReferenceBeCompatible(compatibleOrders, orderReference)) {
            console.log("Clearing Order because it's no longer compatible", orderReference);
            setOrderReference(null);
        }
        // We want to exclude orderReference from the dependency array
    }, [setCompatibleOrderOptions, canSelectedOrderReferenceBeCompatible]);

    useEffect(() => {
        const handlePaste = event => {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text').trim();
            if (Kit.isAKitReference(pastedData)) return setKitReference(pastedData);
            if (Order.isAOrderReference(pastedData)) return setOrderReference(pastedData);
            if (pastedData.length > 0 && pastedData.length < 50) return setPhoneReference(pastedData);
            console.error("The pasted data does not match any type of reference", pastedData);
        };
        window.addEventListener('paste', handlePaste);
        return () => {
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    useEffect(() => {
        setValidKits(kits.filter(k => k.status === Kit.STATUS.PREPARED && !scannedOrderedRows.some(r => r.kit?.id === k.id)));
    }, [kits, scannedOrderedRows]);
    
    useEffect(() => {
        // Only orders with status === READY should reach this view
        setValidOrders(orders.filter(o => !scannedOrderedRows.some(r => r.id === o.id)));
    }, [orders, scannedOrderedRows]);
    
    useEffect(() => {
        // TODO: set only compatible phones
        setPhoneOptions(["P34D86610-A08B-4466-9D51-2C195AFD499F"]);
    }, [phones, scannedOrderedRows]);
    
    useEffect(() => {
        if (!kitReference?.length || !orderReference?.length || (requiresPhone && !phoneReference?.length)) {
            if (enableAddButton) setEnableAddButton(false);
            return;
        }

        return setEnableAddButton(true);
    }, [kitReference, orderReference, requiresPhone, phoneReference]);

    // onOrderReferenceChange (or any of the dependencies)
    useEffect(() => {
        resetKitReferenceIfIncompatible(kitReference);
    }, [orderReference, resetKitReferenceIfIncompatible]);
    
    // onKitReferenceChange (or any of the dependencies)
    useEffect(() => {
        resetOrderReferenceIfIncompatible(orderReference);
    }, [kitReference, resetOrderReferenceIfIncompatible]);
    
    useEffect(() => {
        updateRequiresPhone();
    }, [updateRequiresPhone]);

    useEffect(() => {
        if (phoneReference) setPhoneReference(null);
    }, [requiresPhone]);

    const { assetsURl } = useAssetsURL();
    return (
        <>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => goToPreviousStep()}
            />
            <div className="fulfilment page order-landing">
                <h1 className="title"><Text>fulfilment.order.process.title</Text></h1>
                <section className="section">
                    <FlexBox sx={{ width: '80%' }}>
                        <SectionInfo label={i18n.resolve('fulfilment.order.process.scan.description')} />
                    </FlexBox>
                    <div style={{ position: 'relative', display: 'grid', gridGap: '12px', marginTop: '20px' }}>
                        <img
                            className="process-order-scan-img"
                            alt="process order scan"
                            src={getImgUrl(
                                assetsURl,
                                'process_order_scan.png'
                            )}
                            style={{ position: 'absolute', left: '500px', bottom: '-145px' }}
                        />
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <KitField
                                value={kitReference || null}
                                options={kitOptions}
                                onChange={(e, newValue) => setKitReference(newValue === null ? newValue : newValue.trim())}
                                sx={{ width: '300px' }}
                            />
                            <span className="bold-yellow">or</span>
                            <Button
                                variant={Variant.Outlined} color={Color.Secondary}
                                onClick={() => console.log("TODO:")}
                                sx={{ padding: '5px 20px' }}
                                disabled
                            >
                                Scan
                            </Button>
                            <span className="bold-yellow">or</span>
                            <Button
                                variant={Variant.Contained} color={Color.Secondary}
                                onClick={() => { window.open("/kit/create", "_blank") }}
                            >
                                Create new kit
                            </Button>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="bold-yellow">and</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <OrderField
                                value={orderReference}
                                options={orderOptions}
                                onChange={(e, newValue) => setOrderReference(newValue === null ? newValue : newValue.trim())}
                                sx={{ width: '300px' }}
                            />
                            <span className="bold-yellow">or</span>
                            <Button
                                variant={Variant.Outlined} color={Color.Secondary}
                                onClick={() => { console.log("TODO:") }}
                                sx={{ padding: '5px 20px' }}
                                disabled
                            >
                                Scan
                            </Button>
                        </div>
                        {
                            requiresPhone ? <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="bold-yellow">and</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                    <PhoneField
                                        value={phoneReference || null}
                                        options={phoneOptions}
                                        onChange={(e, newValue) => setPhoneReference(newValue === null ? newValue : newValue.trim())}
                                        sx={{ width: '300px' }}
                                    />
                                    <span className="bold-yellow">or</span>
                                    <Button
                                        variant={Variant.Outlined} color={Color.Secondary}
                                        onClick={() => { console.log("TODO:") }}
                                        sx={{ padding: '5px 20px' }}
                                        disabled
                                    >
                                        Scan
                                    </Button>
                                </div>
                            </> : ''
                        }
                    </div>
                    <Button
                        variant={Variant.Contained} color={Color.Secondary}
                        onClick={() => startProcessingOrder()}
                        disabled={!enableAddButton}
                        sx={{ marginTop: '40px', padding: '5px 40px' }}
                    >
                        <Text>add</Text>
                    </Button>
                    <div className="create-kit-table-section">
                        <Table
                            disableColumnSelector={true}
                            disableColumnFilter={true}
                            withHeader={false}
                            checkboxSelection={false}
                            columns={getScannedOrdersColumns()}
                            getRowId={(r) => r?.id}
                            rows={scannedOrderedRows}
                        // onCellEditStop={onCellEditStop}
                        />
                    </div>
                </section>
                <FlexBox sx={flexRowJustifyItemsEnd}>
                    <Button
                        variant={Variant.Contained} color={Color.Secondary}
                        onClick={() => { history.push(Pages.OrderLanding.PATH); }}
                        disabled={orders.length !== scannedOrderedRows.length}
                        sx={{ marginLeft: '8px', padding: '5px 40px' }}
                    >
                        <Text>done</Text>
                    </Button>
                </FlexBox>
            </div>
        </>
    );
};