import { useState, useEffect } from 'react';

import {
    List, ListItem, ListItemText,
    Checkbox, FormGroup, FormControlLabel
} from '@mui/material';

import { flexRow } from '../../atoms/Layout';
import { Color } from '../../constants/color';

const isChecked = (selecteds, id, value) => selecteds?.[id] === value;

export const CheckList = ({ items, handleSelectionChange }) => {
    const [selecteds, setSelecteds] = useState({});
    const handleChange = (e, item) => {
        const value = selecteds[item.id] === e.target.value ? undefined : e.target.value;
        setSelecteds({
            ...selecteds,
            [item.id]: value,
        });
    };

    useEffect(() => {
        if (selecteds) {
            handleSelectionChange(selecteds)
        }
    }, [selecteds]);

    return (
        <List>
            {items?.map(item => (
                <ListItem key={item.id}>
                    <ListItemText color={Color.Primary}
                        sx={{ span: { fontWeight: 600 } }}
                    >
                        {item.label}
                    </ListItemText>
                    <FormGroup sx={flexRow}>
                        {item.values?.map(({ label, value }) => (
                            <FormControlLabel
                                key={value}
                                control={
                                    <Checkbox
                                        color={Color.Secondary}
                                        checked={isChecked(selecteds, item.id, value)}
                                    />
                                }
                                onChange={(e) => handleChange(e, item)}
                                value={value}
                                label={label} />
                        ))}
                    </FormGroup>
                </ListItem>
            ))}
        </List>
    );
};
