import { aggregateURN } from '../../executor/urn';

const context = "fulfilment";
const itemAggregateName = "Item";

// Duplicated in device
const MODEL_TYPE = Object.freeze({
  ACU_PEBBLE_V1: 'ACU_PEBBLE_V1',
  PHONE: 'PHONE',
  RING_SENSOR: 'RING_SENSOR',
});

export default {
  context,
  items: {
    phoneSIM: {
      id: aggregateURN({ context, name: itemAggregateName }, "PHONE_SIM"),
      model: MODEL_TYPE.PHONE, // not part of schema
    },
    acuPebbleV1: {
      id: aggregateURN({ context, name: itemAggregateName }, "ACU_PEBBLE_V1"),
      model: MODEL_TYPE.ACU_PEBBLE_V1, // not part of schema
    },
    ringSensor: {
      id: aggregateURN({ context, name: itemAggregateName }, "RING_SENSOR"),
      model: MODEL_TYPE.RING_SENSOR, // not part of schema
    },
  },
  // It's okay to have this hardcoded as everytime we want to add a new model we need to make a new release
  getItemURNFromModel(model) {
    const item = Object.values(this.items).find(i => i.model === model);
    return item.id;
  },
};
