import i18n from "services/server/functions/i18n";

import { Button } from "@mui/material";
import { Text } from "ui/components/Text";

import { getBaseColumnsLayer } from "features/fulfilment/components/molecules/Table/uiLayers";
import { InfoBoxLabel } from "features/fulfilment/components/atoms/InfoBoxLabel";
import { AppColors } from "features/fulfilment/components/themes";
import { FlexBox, flexRowJustifyItemsCenter } from "features/fulfilment/components/atoms/Layout";

import { Variant } from "../../components/constants/variant";
import { Color } from "../../components/constants/color";

const getInputShippingDetailsActions = ({ saveCourierLabelsForRow }) => (params) => [
    <Button
        key={`${params.row.id}_save`}
        variant={Variant.Contained}
        color={Color.Secondary}
        onClick={() => saveCourierLabelsForRow(params.row)}
        disabled={!params.row.edited}
    >
        <Text>Save</Text>
    </Button>
];

export const getInputShippingDetailsColumns = ({ saveCourierLabelsForRow }) => getBaseColumnsLayer([
    {
        field: 'template',
        headerName: i18n.resolve('fulfilment.kitTemplate.box'),
        renderCell: (cellProps) => <div style={{ display: "flex", alignItems: "center", gap: "5px" }}><InfoBoxLabel label={cellProps.row.template?.name} bgColor={cellProps.row.template?.hexColorCode} /><span>{cellProps.row.kit ? '📦' : ''}</span></div>,
    },
    {
        field: 'reference',
        headerName: i18n.resolve('global.ref'),
        renderCell: (cellProps) => cellProps?.value
    },
    {
        field: 'shipping.inbound',
        headerName: i18n.resolve('fulfilment.order.inboundTrackingNumber'),
        width: 300,
        editable: true,
        renderCell: (cellProps) =>
            <FlexBox sx={flexRowJustifyItemsCenter}>
                <InfoBoxLabel label={cellProps?.row?.shipping?.inbound} bgColor={AppColors.extraLightGrey} sx={{ width: "100% !important" }} />
            </FlexBox>,
    },
    {
        field: 'shipping.outbound',
        headerName: i18n.resolve('fulfilment.order.outboundTrackingNumber'),
        width: 300,
        editable: true,
        renderCell: (cellProps) =>
            <FlexBox sx={flexRowJustifyItemsCenter}>
                <InfoBoxLabel label={cellProps?.row?.shipping?.outbound} bgColor={AppColors.extraLightGrey} sx={{ width: "100% !important" }} />
            </FlexBox>,
    },
    {
        field: 'actions',
        headerName: i18n.resolve('global.actions'),
        sortable: false, type: 'actions',
        getActions: getInputShippingDetailsActions({ saveCourierLabelsForRow }),
    },
]);

export const getScannedOrdersColumns = () => getBaseColumnsLayer([
    {
        field: 'orderReference',
        renderCell: (cellProps) => cellProps?.value,
        // width: 120,
    },
    {
        field: 'kitReference',
        renderCell: (cellProps) => cellProps?.value,
        // width: 120,
    },
    {
        field: 'shippingOutbound',
        renderCell: (cellProps) => cellProps?.value
    },
    {
        field: 'shippingInbound',
        renderCell: (cellProps) => cellProps?.value
    },
    {
        field: 'phoneReference',
        renderCell: (cellProps) => cellProps?.value
    },
    {
        field: 'template',
        renderCell: (cellProps) => <InfoBoxLabel label={cellProps?.row?.template?.name} bgColor={cellProps?.row?.template?.hexColorCode} />,
    },
]);