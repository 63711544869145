import { DUPLICATE_ERROR } from '../../../errors/codes';
import { FORBIDDEN_POLICY, NOT_EXIST_POLICY, VALID_DATA_POLICY } from "../../policies";
import { getResolution } from '../../../errors/messages';
import Order from "./Order";
import Kit from "./Kit";
import { Study } from "../../diagnosis/model";
import { Device } from "../../devices/model";
import { getItemsRequiredByStudy, isKitTemplateValidForItems } from '../connector/study.connector';
import { resolve } from '../..';

export const UNIQUE_ORDER_POLICY = (details) => ({ code: DUPLICATE_ERROR, reason: 'Order already exists', resolution: getResolution(DUPLICATE_ERROR), details });

export const isOrderUnique = async (executor, studyURN) => {
    const uniqueOrders = await executor.execute(Order.queries.LIST.newRequest({
        all: [
            { field: "data.owners", operator: "array-contains", value: studyURN },
            { field: "data.status", operator: "!=", value: Order.STATUS.CANCELLED },
        ]
    }));
    if (uniqueOrders.length) throw UNIQUE_ORDER_POLICY(`Order with owner ${studyURN} already exists`);
};

export const updateShippingDetailsPolicies = async (executor, requestData, existingOrderSnap) => {
    const allowedStatuses = new Set([Order.STATUS.CREATED, Order.STATUS.PENDING, Order.STATUS.READY]);
    if (!allowedStatuses.has(existingOrderSnap.data.status)) throw VALID_DATA_POLICY(`Can not perform operation from the existing order status`);
};

// const hasEvent = (allEvents, event) => allEvents.some(e => event.isInstance(e.id));

// For Order Model
export const orderAssignKitPolicies = async (executor, requestData, existingOrderSnap) => {
    if (!existingOrderSnap) {
        if (!requestData.reference) {
            throw NOT_EXIST_POLICY(`Unable to find unique identifier ${requestData.id}.`);
        }
        const orders = await executor.execute(Order.queries.LIST.newRequest({ where: { field: 'data.reference', operator: '=', value: requestData.reference } }));
        if (orders.length === 0) throw NOT_EXIST_POLICY(`Unable to find unique identifier by reference ${requestData.reference}`);
        if (orders.length !== 1) {
            console.log(`There exists more than 1 Order with the same reference ${requestData.reference}`, { orders });
            throw VALID_DATA_POLICY(`There exists more than 1 Order with the same reference ${requestData.reference}`);
        }
        existingOrderSnap = orders[0];
    }
    if (existingOrderSnap.data.status !== Order.STATUS.CREATED) throw VALID_DATA_POLICY(`This operation is only possible from the ${Order.STATUS.CREATED} status`);

    let kitSnap;
    if (requestData.kit) {
        kitSnap = await executor.execute(Kit.queries.GET.newRequest({ id: requestData.kit }));
    } else {
        const kits = await executor.execute(Kit.queries.LIST.newRequest({ where: { field: 'data.reference', operator: '=', value: requestData.kitReference } }));
        if (kits.length > 1) {
            console.log(`There exists more than 1 Kit with the same reference ${requestData.kitReference}`, { kits });
            throw VALID_DATA_POLICY(`There exists more than 1 Kit with the same reference ${requestData.kitReference}`);
        }
        kitSnap = kits[0];
    }

    // await executor.execute(Study.queries.GET.newRequest({ id: existingOrderSnap.data.owners[0] })),

    if (!kitSnap) throw NOT_EXIST_POLICY(`Kit with id ${requestData.kit} does not exist`);
    if (kitSnap.data.status !== Kit.STATUS.PREPARED) throw VALID_DATA_POLICY(`Kit is not in status ${Kit.STATUS.PREPARED}`);
    validateModelSharesOwnersWithSnapshot(existingOrderSnap, kitSnap);
    // if (!hasEvent(studySnap.metadata.events, Study.events.PATIENT_WILL_CONFIRMED)) throw VALID_DATA_POLICY(`The Study patient has not confirmed yet that he is willing to conduct the test`);

    const devicesSnaps = await Promise.all(requestData.devices.map(d => executor.execute(Device.queries.GET.newRequest({ id: d }))));
    let nonExistingDeviceIndex = devicesSnaps.findIndex(d => !d);
    if (nonExistingDeviceIndex !== -1) {
        throw NOT_EXIST_POLICY(`Unable to find unique identifier ${requestData.devices[nonExistingDeviceIndex]}`);
    }

    await validateOrderStudyWithKitTemplate(executor, existingOrderSnap, kitSnap);
};

export const markOrderAsValidatedPolicies = async (executor, existingOrderSnap) => {
    const kitSnap = await executor.execute(Kit.queries.GET.newRequest({ id: existingOrderSnap.data.kit }));
    if (kitSnap.data.status !== Kit.STATUS.VALIDATED) throw VALID_DATA_POLICY(`Kit is not in status ${Kit.STATUS.VALIDATED}`);
};

// For Kit Model
export const assignKitPolicies = async (executor, requestData, existingKitSnap) => {
    if (existingKitSnap.data.status !== Kit.STATUS.PREPARED) throw VALID_DATA_POLICY(`Kit is not in status ${Kit.STATUS.PREPARED}`);

    const orders = await executor.execute(Order.queries.LIST.newRequest({ where: { field: "data.kit", operator: "=", value: existingKitSnap.aggregate.id } }));
    if (orders.length) throw UNIQUE_ORDER_POLICY(`Kit is already asigned to order ${orders[0].aggregate.id}`);

    const orderSnap = await executor.execute(Order.queries.GET.newRequest({ id: requestData.orderId }));
    if (!orderSnap) throw NOT_EXIST_POLICY(`Unable to find unique identifier ${requestData.orderId}`);
    validateModelSharesOwnersWithSnapshot(orderSnap, existingKitSnap);
}

// Precondition: requestItems.length > 0
export const validateInventoryMatches = (requestItems, kitItems = []) => {
    if (requestItems.length !== kitItems.length) throw VALID_DATA_POLICY(`The Kit expects ${kitItems.length} items when only ${requestItems.length} are being provided in the request`);

    const requestInventory = Kit.getInventory(requestItems);
    const kitInventory = Kit.getInventory(kitItems);

    for (const key of Object.keys(kitInventory)) {
        if (kitInventory[key] !== requestInventory[key]) throw VALID_DATA_POLICY(`The Kit expects ${kitInventory[key]} of ${key} when ${requestInventory[key] || 0} are being provided in the request`);
    }
};

export const validateDevicesExist = async (executor, devicesURNs) => {
    const devicesExist = await Promise.all(devicesURNs.map(urn => executor.execute(Device.queries.ID_EXISTS.newRequest({ id: urn }))));

    if (devicesExist.includes(false)) {
        throw VALID_DATA_POLICY(`Some of the devices provided do not exist`);
    }
};

const validateOrderStudyWithKitTemplate = async (executor, orderSnap, kitSnap) => {
    const studySnap = await executor.execute(Study.queries.GET.newRequest({ id: orderSnap.data.owners[0] }));

    const kitContent = getItemsRequiredByStudy(studySnap);
    if (!isKitTemplateValidForItems(kitSnap.data.template, kitContent)) {
        console.log('Study is not compatible with the Order Kit device types', orderSnap.aggregate.id, kitContent);
        throw VALID_DATA_POLICY(`Study is not compatible with the Order Kit device types`);
    }
};

export const runOwnersExistsAndUserHasOwnershipPolicy = async (executor, owners = [], userSnap) => {
    const ownersSnapshots = await Promise.all(owners.map(urn => {
        const model = resolve(urn);
        return executor.execute(model.queries.GET.newRequest({ id: urn }));
    }));

    return ownersSnapshots.forEach(snap => validateModelHasOwnershipOfSnapshot(userSnap, snap));
};

const validateModelHasOwnershipOfSnapshot = (modelSnap, snap) => {
    if (snap.metadata.allOwners.some(uid => modelSnap.data.owners.includes(uid))) return true;
    throw FORBIDDEN_POLICY(`${modelSnap.aggregate.name} does not have ownership of the requested resource ${snap.aggregate.id}`);
};

const validateModelSharesOwnersWithSnapshot = (modelSnap, snap) => {
    if (snap.data.owners.some(uid => modelSnap.metadata.allOwners.includes(uid))) return true;
    throw FORBIDDEN_POLICY(`${modelSnap.aggregate.name} does not share owners with the requested resource ${snap.aggregate.id}`);
};