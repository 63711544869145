import { FlexBox } from "features/fulfilment/components/atoms/Layout";
import { AppColors } from 'features/fulfilment/components/themes';

const panelStyles = {
    width: '100%',
    height: '100%',
    border: `2px solid ${AppColors.lightAcuGrey}`,
    borderRadius: '8px',
    backgroundColor: AppColors.extraLightGrey,
    padding: '20px'
};
const getTabStyles = ({ value, index }) => {
    const selected = value === index;
    return ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100px',
        height: '28px',
        marginLeft: index === 0 ? '16px' : 0,
        marginRight: '1px',
        color: selected ? AppColors.acuWhite: AppColors.lightAcuGrey,
        backgroundColor: selected ? AppColors.lightAcuGrey : AppColors.mediumAcuGrey,
        paddingLeft: '8px',
        paddingRight: '8px',
        cursor: 'pointer',
        fontWeight: 700,
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    });
};

export const TabPanel = ({ children, index, value, ...rest }) => {
    if (value !== index) {
        return null;
    }

    return (
        <FlexBox
            role="tabpanel"
            aria-labelledby={`tab-panel-${index}`}
            sx={panelStyles}
            {...rest}
        >
            {children}
        </FlexBox>
    );
};

export const Tab = ({ label, value, index, ...rest }) => (
    <FlexBox sx={getTabStyles({
        index, value
    })} {...rest}>
        {label}
    </FlexBox>
)