
export const getMuiButtonOverrides = (colors) => ({
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        }
    },
    MuiButton: {
        variants: [
            {
                props: { variant: 'contained', color: 'secondary' },
                style: {
                    color: `${colors.acuWhite}`,
                    fontWeight: '600',
                },
            },
            {
                props: { variant: 'contained', color: 'alternative' },
                style: {
                    color: `${colors.acuWhite}`,
                    fontWeight: '600',
                },
            },
            {
                props: { variant: 'outlined', color: 'secondary' },
                style: {
                    color: colors.mediumAcuGrey,
                    borderColor: colors.mediumAcuGrey,
                    fontWeight: '600',
                    '&:hover': {
                        color: colors.mediumAcuGrey,
                        borderColor: colors.mediumAcuGrey,
                        backgroundColor: colors.acuWhite
                    },
                },
            },
            {
                props: { variant: 'selected' },
                style: {
                    color: colors.mediumAcuGrey,
                    border: `4px solid ${colors.acuYellow}`,
                    fontWeight: '600',
                    '&:hover': {
                        borderColor: colors.acuYellow,
                        backgroundColor: 'transparent'
                    },
                },
            },
        ]
    }
});