import Config from './config';
import { publish } from 'modules/router';
import * as Pages from './ui';
import { withNotifications, rxExecute as execute } from 'modules/executor';
import { execute as backgroundExec } from 'services/server/functions/executor/executor.client';

const toAction  = (ctx, aggr, action) => (args, options) => (dispatch) => import(`services/server/functions/model/${ctx}/model`).then(model => execute(model[aggr].actions[action].newRequest(args), options)(dispatch)).catch(e => console.error(e) || Promise.reject(e));
const actions = {
  activate: withNotifications(require('services/iam').signInWithCode, {action: 'Activation', successDisabled: true}),
  getStudy: (...args) => (_dispatch) => import('services/server/functions/model/diagnosis/model').then(model => backgroundExec(model.Study.queries.GET.newRequest(...args))),
  sendQuestionnaire: toAction('diagnosis', 'Study', 'FILL_PATIENT_QUESTIONNAIRE'),
  setDeviceModel: toAction('diagnosis', 'Study', 'SET_PATIENT_DEVICE_MODEL'),
  sendShippingDetails: toAction('diagnosis', 'Study', 'FILL_SHIPPING_DETAILS'),
  sendConfirmation: toAction('notifications', 'Email', 'SCHEDULE_CONFIRM_ONBOARDING_EMAIL')
};

export const Onboarding = publish(Config.routes.Onboarding, actions)(Pages.Onboarding);
export const ThankYou = publish(Config.routes.ThankYou)(Pages.ThankYou);