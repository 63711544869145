import { createTheme } from '@mui/material/styles';
import {getMuiButtonOverrides} from './muiButton';

export const AppColors = Object.freeze({
    darkAcuBlue: '#2f2f2f',
    lightAcuBlue: '#b4cfdd',
    transparentLightAcuBlue: 'rgba(3, 89, 136, 0.302)',
    acuBlue: '#004366',

    darkAcuGrey: '#2f2f2f',
    mediumAcuGrey: '#656464',
    lightAcuGrey: '#979797',
    extraLightGrey: '#F7F5F5',

    transparentLightAcuYellow: 'rgba(243, 186, 0, 0.2)',
    lightAcuYellow: '#FFF1CF',
    acuYellow: '#f3ba00',

   
    lightAcuRed: 'rgb(245, 110, 110)',
    acuRed: 'red',
    darkAcuRed: 'rgb(207, 7, 7)',

    acuWhite: 'white',
    acuBlack: 'rgba(0, 0, 0, 0.781)',
    acuLink: '#9CBCFF',
});



const baseTheme = {
    palette: {
        primary: {
            main: AppColors.mediumAcuGrey,
        },
        secondary: {
            main: AppColors.acuYellow,
        },
        alternative: {
            main: AppColors.lightAcuGrey
        }
    },
    components: {
        MuiPaper: {
            variants: [
                {
                    props: { color: 'primary' },
                    style: {
                        background: AppColors.mediumAcuGrey,
                        border: `1px solid ${AppColors.lightAcuGrey}`
                    },
                },
                {
                    props: { color: 'alternative' },
                    style: {
                        background: AppColors.acuWhite,
                        border: `1px solid ${AppColors.darkAcuGrey}`
                    },
                }
            ],
        },
        MuiInputLabel: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        textTransform: 'capitalize'
                    },
                },
            ],
        },
       ...getMuiButtonOverrides(AppColors)
    }
};
 

export const fulfilmentTheme = createTheme(baseTheme);
