const priority = 800;

export default {
  context: 'onboarding',
  priority,
  routes: {
    Onboarding: { priority: priority + 1, relpath: `/onboard`, public: true, hideNav: true },
    ThankYou:   { priority: priority + 2, relpath: `/thanks`,  public: true, hideNav: true },
  }
};
