import { useContext, useEffect, useState } from "react";

import { ThemeProvider } from '@mui/material/styles';

import useLocationParams from "ui/hooks/useLocationParams";
import { PageNavContext } from "ui/acukit/Page";
import Loading from "ui/components/Loading";
import { fulfilmentTheme } from "features/fulfilment/components/themes";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import i18n from "services/server/functions/i18n";
import history from 'history.js';
import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { Tabs } from "features/components/Tabs";
import { getKitDetailsContent, getOrdersContent, getShippingDetailsContent, getCommentsContent } from "../tabs.config";
import useProjection from "ui/hooks/useProjection";
import { User } from "services/server/functions/model/authentication/model";
import { Item, Order } from "services/server/functions/model/fulfilment/model";
import useSnapshot from "ui/hooks/useSnapshot";

export const ViewOrder = ({ addOrderComment }) => {
    const [locState, setLocState] = useLocationParams();
    const orderId = locState?.order;

    const [order, loading] = useProjection(orderId && Order.queries.VIEW_ORDER.newRequest({ id: orderId }), { autoStart: orderId !== undefined });
    const [orderCommentsSnaps = []] = useProjection(Order.entities.Comment.queries.LIST.newRequest({ where: { field: 'data.owners', operator: 'array-contains', value: orderId} }), { autoStart: orderId !== undefined });
    const [userSnaps] = useSnapshot(User);
    const [itemsSnaps, loadingItems] = useSnapshot(Item);
    const isLoading = loading || loadingItems;

    const pageNav = useContext(PageNavContext);
    const [shipingSelectedTab, setShipingSelectedTab] = useState(locState?.shipingSelectedTab ?? 0);
    const [kitSelectedTab, setKitSelectedTab] = useState(locState?.kitSelectedTab ?? 0);

    useEffect(() => {
        pageNav.enterFullScreen();
    }, []);
    useEffect(() => setLocState({ ...locState, shipingSelectedTab, kitSelectedTab }), [shipingSelectedTab, kitSelectedTab]);

    return <>
        {isLoading && <Loading loading={isLoading} />}
        <ThemeProvider theme={fulfilmentTheme}>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => history.goBack()}
            />
            <div className="fulfilment page">
                <h1 className="title">
                    {
                        `${i18n.resolve('fulfilment.order.ref')} ${order?.reference}`
                    }
                </h1>
                <section className="section">
                    <Tabs
                        label={i18n.resolve('fulfilment.order.label')}
                        value={0} items={order && getOrdersContent(order)} />
                    <Tabs
                        sx={{ marginTop: '60px' }}
                        label={i18n.resolve('global.shipping')}
                        items={order && getShippingDetailsContent(order)}
                        value={shipingSelectedTab}
                        handleChange={setShipingSelectedTab} />
                    <Tabs
                        sx={{ marginTop: '60px' }}
                        label={i18n.resolve('fulfilment.kit.title')}
                        items={order && getKitDetailsContent(order.kit, itemsSnaps)}
                        noTabsText={order?.kit ? false : i18n.resolve('fulfilment.kit.not_assigned')}
                        value={kitSelectedTab}
                        handleChange={setKitSelectedTab} />
                    <Tabs
                        sx={{ marginTop: '60px' }}
                        label={i18n.resolve('global.comments')}
                        items={getCommentsContent(orderId, orderCommentsSnaps, userSnaps, addOrderComment)}
                    />
                </section>
            </div>
        </ThemeProvider>
    </>;
};