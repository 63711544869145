
import { Joi } from '../../../validation/rules';

import Config from '../config';
import { withDefaults } from '../..';
import { VALID_DATA_POLICY, when, runAlreadyExistsPolicy, runNotExistsPolicy } from '../../policies';
import Comment from '../../system/model/Comment';
import KitTemplate from './KitTemplate';
import { assignKitPolicies, validateInventoryMatches, validateDevicesExist, runOwnersExistsAndUserHasOwnershipPolicy } from './policies';
import { hash } from '../../../helpers/crypto';

const STATUS = {
    CREATED: "CREATED",
    PREPARED: "PREPARED",
    ASSIGNED: "ASSIGNED", // Should a kit know about an order? Or should the Order store the kit reference?
    LOST: "LOST",
    VALIDATED: "VALIDATED",
};
const STATUSES = Object.values(STATUS);

const VALIDATION_STATUS = {
    "RETURNED": "RETURNED",
    "LOST": "LOST",
    "DISPOSED": "DISPOSED",
};
const VALIDATION_STATUSES = Object.values(VALIDATION_STATUS);

const getInventory = (items) => {
    return items.reduce((acc, curr) => {
        if (!(curr in acc)) acc[curr] = 0;
        acc[curr]++;
        return acc;
    }, {});
};

const generateKitReference = (kitId) => {
    const ref = hash(kitId, 8);
    const chunks = ref.match(/.{1,4}/g); // splits the hashed value into 2 chunks of 4 characters each
    return `K-${chunks[0]}-${chunks[1]}`.toUpperCase();
};

const isAKitReference = (kitReference) => {
    const regex = /^K-\w{4}-\w{4}$/;
    return regex.test(kitReference);
};

const simplifyItems = (kit) => {
    const kitItems = kit.items || [];
    const kitValidations = kit.validations || [];
    const items = kit.template.items.map((itemURN, index) => {
        const included = kitItems[index];
        return {
            type: itemURN,
            included: Boolean(included),
            validationStatus: kitValidations[index],
            device: typeof included === "string" ? included : undefined,
            index,
        };
    });
    return items;
};

let Kit = {
    context: Config.context,
    name: 'Kit',
    STATUS,
    VALIDATION_STATUS,
    generateKitReference,
    isAKitReference,
    getInventory,
    simplifyItems,
    schema: Joi.object().keys({
        name: Joi.string(),
        reference: Joi.string(),
        template: Joi.object().keys({
            id: KitTemplate.schema.extract('id'), // in case we need it, but it could have been modified/deleted
            name: KitTemplate.schema.extract('name'),
            description: KitTemplate.schema.extract('description'),
            asset: KitTemplate.schema.extract('asset'), // Web URL
            hexColorCode: KitTemplate.schema.extract('hexColorCode'),
            items: KitTemplate.schema.extract('items'),
        }),
        // each item follows the order of template.items and consists of a boolean/URN indicating if the item is IN the kit
        items: Joi.array().items(Joi.alternatives().try(Joi.boolean(), Joi.string().uri())),
        // each item follows the order of template.items and consists of a boolean/validation status
        validations: Joi.array().items(Joi.alternatives().try(Joi.boolean().valid(false), Joi.valid(...VALIDATION_STATUSES))),
        status: Joi.string().valid(...STATUSES),
    }),
    entities: {
        get Comment() {
            delete this.Comment;
            this.Comment = Comment(Kit);
            return this.Comment;
        },
    },
    snapshot: (event, prevKit) => {
        const prevKitSnap = prevKit || { data: {}, metadata: {} };

        let status = prevKitSnap.data.status;
        if (event.data.status && [STATUS.LOST, STATUS.VALIDATED].every(s => s !== status)) {
            status = event.data.status;
        }

        return {
            data: {
                status,
            },
        };
    },
    events: {
        NEW_KIT_PREPARED: {},
        ITEMS_TRACKED: {},
        MARKED_AS_ASSIGNED: {},
        MARKED_AS_LOST: {},
        MARKED_AS_VALIDATED: {},
    },
    commands: {
        PREPARE_NEW_KIT: {
            checkPolicies: async (requestData, existingKit, executor, { user }) => await Promise.all([
                runAlreadyExistsPolicy(requestData, existingKit),
                runOwnersExistsAndUserHasOwnershipPolicy(executor, requestData.owners, user),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Kit.schema.extract('id'),
                    name: Kit.schema.extract('name'),
                    template: Joi.string().uri().required(), // reference of KitTemplate
                    owners: Joi.array().items(Joi.string().uri()).default((_, helpers) => {
                        const user = helpers?.prefs?.context?.user;
                        if (!user) throw new Error('owners required');
                        return user.data.owners;
                    }), // reference of Organisation/HealthcareSite
                });
            },
            event: (action, _prevKit, res) => {
                return Kit.events.NEW_KIT_PREPARED.new(res.data, res.metadata);
            },
        },
        TRACK_ITEMS: {
            checkPolicies: async (requestData, existingKit) => await Promise.all([
                runNotExistsPolicy(requestData, existingKit),
                // TODO: min status is PREPARED
                when(![STATUS.PREPARED, STATUS.ASSIGNED, STATUS.LOST, STATUS.VALIDATED].includes(existingKit?.data.status)).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.CREATED} status`)),
                // TODO: validate items entered match the kit items types + consider scenario in which override happens
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Kit.schema.extract('id').required(),
                    devices: Joi.array().items(Joi.string()).min(1).required(), // Ids of Devices
                });
            },
            event: (action, _prevKit, data) => {
                return Kit.events.ITEMS_TRACKED.new(data);
            },
        },
        MARK_AS_ASSIGNED: {
            checkPolicies: async (requestData, existingKit, executor) => await Promise.all([
                runNotExistsPolicy(requestData, existingKit),
                assignKitPolicies(executor, requestData, existingKit),
                when(existingKit?.data.status !== STATUS.PREPARED).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.PREPARED} status`)),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Kit.schema.extract('id').required(),
                    orderId: Joi.string().uri(), // Reference of Order
                });
            },
            event: (action) => {
                return Kit.events.MARKED_AS_ASSIGNED.new({ ...action.data, owners: [action.data.orderId], status: STATUS.ASSIGNED });
            },
        },
        MARK_AS_LOST: {
            checkPolicies: async (requestData, existingKit) => await Promise.all([
                runNotExistsPolicy(requestData, existingKit),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Kit.schema.extract('id').required(),
                });
            },
            event: (action, _prevKit, data) => {
                return Kit.events.MARKED_AS_LOST.new({ ...data, status: STATUS.LOST });
            },
        },
        MARK_AS_VALIDATED: {
            // We allow the possibility to pass a device that is not being tracked by the kit
            checkPolicies: async (requestData, existingKit, executor) => await Promise.all([
                runNotExistsPolicy(requestData, existingKit),
                when(existingKit?.data.status !== STATUS.ASSIGNED).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.ASSIGNED} status`)),
                when(existingKit?.data.template.items.length !== requestData.validations.length).rejectWith(VALID_DATA_POLICY(`The Kit expects ${existingKit?.data.template.items.length} validations when only ${requestData.validations.length} are being provided in the request`)),
                when(requestData.validations.map(v => v.device).filter(Boolean).length !== new Set(requestData.validations.map(v => v.device).filter(Boolean)).size).rejectWith(VALID_DATA_POLICY(`The same device is being validated multiple times. Only provide 1 validation per device`)),
                validateDevicesExist(executor, requestData.validations.map(v => v.device).filter(Boolean)),
                validateInventoryMatches(requestData.validations.map(v => v.item), existingKit?.data.template.items),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Kit.schema.extract('id').required(),
                    validations: Joi.array().items(Joi.object().keys({
                        item: Joi.string().uri().required(), // reference of Item
                        device: Joi.string().uri(), // reference of Device
                        status: Joi.string().valid(...VALIDATION_STATUSES).required(),
                    })).required().min(1),
                });
            },
            event: (action, _prevKit, data) => {
                return Kit.events.MARKED_AS_VALIDATED.new({ ...data, status: STATUS.VALIDATED });
            },
        },
    },
    queries: {
        GET_LABEL: {
            get schema() {
                return Joi.alternatives().try(
                    ...[
                        Joi.object().keys({
                            id: Kit.schema.extract('id').required(),
                        }),
                        Joi.object().keys({
                            reference: Kit.schema.extract('reference').required(),
                            template: Joi.object().keys({
                                name: Kit.schema.extract('template.name').required(),
                                hexColorCode: Kit.schema.extract('template.hexColorCode').required(),
                            }).required(),
                        }),
                    ].map(s => s.keys({
                        format: Joi.string().valid('png', 'pdf').default('pdf'),
                    })),
                );
            },
            newRequest: (args, metadata) => ({
                action: Kit.queries.GET_LABEL.new(args, metadata),
                depends: [], // NECESSARY! Otherwise it sends the handler all the snapshots!
            }),
        },
    },
    reports: {
        labels: {
            type: 'isPDF',
            query: args => Kit.queries.GET_LABEL.newRequest(args),
        },
    },
};
Kit = withDefaults()(Kit);

export default Kit;