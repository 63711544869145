import Config from '../config';
import { Joi } from '../../../validation/rules';
import { withDefaults } from '../..';

const Comment = (parent) => withDefaults()({
  context: Config.context,
  name: 'Comment',
  label: `${parent?.label} comment`,
  schema: Joi.object().keys({
    text: Joi.string(),
    owner: Joi.string().uri(), // reference of User
  }),
  events: {
    COMMENT_SET: {},
  },
  commands: {
    SET_COMMENT: {
      label: `Set comment for ${parent?.label.toLowerCase()}`,
      get schema() { return Joi.object().unknown(true); },
      get event() { return Comment(parent).events.COMMENT_SET; }
    }
  },
  queries: {},
}, parent);

export default Comment;