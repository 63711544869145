import { useState, useEffect } from "react";
import { decodeTime } from 'ulid';

import i18n from "services/server/functions/i18n";
import { FieldsViewer, FieldsViewerType } from "../components/molecules/FieldsViewer";
import { CommentSection } from "../components/molecules/Comments";
import { Order } from 'services/server/functions/model/fulfilment/model';

/* BLOCK ORDER SECTION */

const OrderDetails = (order) => () => {
    const items = [
        {
            label: i18n.resolve('Study'),
            type: FieldsViewerType.Info,
            value: order.ownerReference
        }, {
            label: i18n.resolve('fulfilment.kit.reference.title'),
            type: FieldsViewerType.Info,
            value: order.kit?.reference
        }, {
            label: i18n.resolve('status'),
            type: FieldsViewerType.Box,
            value: order.status
        },
    ];
    return <FieldsViewer items={items} />
};

export const getOrdersContent = (order) => [{
    index: 0, label: i18n.resolve('details'),
    content: OrderDetails(order)
}];

/* BLOCK SHIPPING SECTION */

const ShippingDetails = (order) => () => {
    const items = [{
        label: i18n.resolve('fulfilment.order.shipping.outbound'),
        type: FieldsViewerType.Info,
        value: order.shipping?.outbound
    },
    {
        label: i18n.resolve('fulfilment.order.shipping.inbound'),
        type: FieldsViewerType.Info,
        value: order.shipping?.inbound
    },
    {
        label: i18n.resolve('global.sender'),
        type: FieldsViewerType.Info,
        value: "Acurable"
    },
    ];
    return <FieldsViewer items={items} sx={{ gridTemplateColumns: 'repeat(3, 1fr)' }} />;
};

// const generateSenderAdress = (address) => [{
//     label: i18n.resolve('fulfilment.order.address.line1'),
//     type: FieldsViewerType.Info,
//     value: address.addressLine1
// }, {
//     label: i18n.resolve('fulfilment.order.address.line2'),
//     type: FieldsViewerType.Info,
//     value: address.addressLine2
// }, {
//     label: i18n.resolve('fulfilment.order.address.postcode'),
//     type: FieldsViewerType.Info,
//     value: address.postCode
// }, {
//     label: i18n.resolve('fulfilment.order.address.city'),
//     type: FieldsViewerType.Info,
//     value: address.city
// }, {
//     label: i18n.resolve('fulfilment.order.address.country'),
//     type: FieldsViewerType.Info,
//     value: address.country
// }, {
//     label: i18n.resolve('fulfilment.order.address.phone'),
//     type: FieldsViewerType.Info,
//     value: address.phone
// }];

// const generateRecipientAdress = (address) => [{
//     label: i18n.resolve('fulfilment.order.address.line1'),
//     type: FieldsViewerType.Info,
//     value: address.addressLine1
// }, {
//     label: i18n.resolve('fulfilment.order.address.line2'),
//     type: FieldsViewerType.Info,
//     value: address.addressLine2
// }, {
//     label: i18n.resolve('fulfilment.order.address.postcode'),
//     type: FieldsViewerType.Info,
//     value: address.postCode
// }, {
//     label: i18n.resolve('fulfilment.order.address.city'),
//     type: FieldsViewerType.Info,
//     value: address.city
// }, {
//     label: i18n.resolve('fulfilment.order.address.country'),
//     type: FieldsViewerType.Info,
//     value: address.country
// }, {
//     label: i18n.resolve('fulfilment.order.address.phone'),
//     type: FieldsViewerType.Info,
//     value: address.phone
// }, {
//     label: i18n.resolve('Email'),
//     type: FieldsViewerType.Info,
//     value: address.email
// }, {
//     label: i18n.resolve('global.name'),
//     type: FieldsViewerType.Info,
//     value: address.name
// }];

// const ShippingSender = (order) => () => <FieldsViewer items={generateSenderAdress(order.sender)} />;
// const ShippingRecipient = (order) => () => <FieldsViewer items={generateRecipientAdress(order.recipient)} />;

export const getShippingDetailsContent = (order) => [
    {
        index: 0, label: i18n.resolve('details'),
        content: ShippingDetails(order)
    },
    // {
    //     index: 1, label: i18n.resolve('global.sender'),
    //     content: ShippingSender(order)
    // },
    // {
    //     index: 2, label: i18n.resolve('global.recipient'),
    //     content: ShippingRecipient(order)
    // }
];


/* BLOCK KIT SECTION */

const KitDetails = (kit) => () => {
    const items = [{
        label: i18n.resolve('global.name'),
        type: FieldsViewerType.Box,
        value: kit?.template.name
    }, {
        label: i18n.resolve('fulfilment.kit.create.steps.kit_model.short_desc'),
        type: FieldsViewerType.Info,
        value: kit?.template.description
    }];
    return <FieldsViewer items={items} sx={{ gridTemplateColumns: 'repeat(3, 1fr)' }} />;
};

const KitItems = (kitItems = [], itemsSnaps = {}) => () => {
    const items = [{
        label: i18n.resolve('global.item'),
        type: FieldsViewerType.List,
        values: kitItems.map(i => ({ label: itemsSnaps[i.type]?.data?.name || i.type.split("/").pop() }))
    }, {
        label: i18n.resolve('global.ref'),
        type: FieldsViewerType.List,
        values: kitItems.map(i => ({ label: i.reference || 'N/A' }))
    }, {
        label: i18n.resolve('status'),
        type: FieldsViewerType.List,
        itemType: FieldsViewerType.Box,
        values: kitItems.map(i => ({ label: i.validationStatus }))
    }];
    return <FieldsViewer items={items} sx={{ gridAutoFlow: 'column', gridTemplateColumns: 'none' }} />;
};

export const getKitDetailsContent = (kit, itemsSnaps) => [{
    index: 0, label: i18n.resolve('details'),
    content: KitDetails(kit)
}, {
    index: 1, label: i18n.resolve('global.items'),
    content: KitItems(kit?.items, itemsSnaps)
}];

const CommentsDetails = (orderId, orderCommentsSnaps, userSnaps, addOrderComment) => () => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        setComments(orderCommentsSnaps.map((commentSnap) => {
            const user = userSnaps[commentSnap.data.owner]?.data;
            return {
                id: commentSnap.aggregate.id,
                user,
                text: commentSnap.data.text,
                date: new Date(decodeTime(commentSnap.metadata.lastEvent)),
            };
        }));
    }, [orderCommentsSnaps, userSnaps]);

    const onAddComment = async ({ userId, text }) => {
        const id = Order.entities.Comment.newURN(orderId);
        const addedCommentEvent = await addOrderComment({
            id,
            text,
            owner: userId,
        });
        const commentUser = userSnaps[userId]?.data;
        setComments([...comments, {
            id,
            user: commentUser,
            text,
            date: new Date(decodeTime(addedCommentEvent.seq)),
        }]);
        return true;
    };

    return <CommentSection
        comments={comments}
        onAddComment={onAddComment}
    />
};

export const getCommentsContent = (orderId, orderCommentsSnaps, userSnaps, addOrderComment) => [{
    index: 0, label: i18n.resolve('details'),
    content: CommentsDetails(orderId, orderCommentsSnaps, userSnaps, addOrderComment),
}];