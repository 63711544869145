import { isBrowser } from '../env';
import { dataFrom, aggregateURN } from '../executor/urn';
import * as codes from '../errors/codes';
import { ascend, descend, prop, sort } from 'ramda';

const PUBLIC_COLLECTIONS = ['Role', 'Item', 'KitTemplate'];
// Firestore requirement: inequality operators require use of orderBy method on fields filtered by inequalities if order by different field not using inequality operator is required
const INEQUALITY_PREFIXES = ['<', '>', 'starts-with'];

const splitArrayIntoChunks = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

const resolve  = (urn) => {
  const {resource, id, path} = dataFrom(urn);
  if (resource !== "aggregate" && resource !== "event")
    throw new Error(`Resource type '${resource}' not supported.`)
  return resource === "event" ? id : path;
}

const getAuditTTL = (eventTimestamp /* milliseconds */) => {
  const config = require('./config').config;
  const currentDate = new Date(eventTimestamp);
  const ttl = new Date(currentDate.getTime() + (config.environment === 'production' ? 365 : 30) * 24 * 60 * 60 * 1000); // Add one month (30 days) in milliseconds for non-production env and 1 year (365 days) for production
  const timestamp = Math.floor(ttl.getTime() / 1000); // Convert to UNIX timestamp (seconds)
  return timestamp;
};

const urn2DocPath = urn => {
  // "aggregate"         ==> /snapshots/{context}/{aggregate}/{id} or /snapshots/{context}/{aggregate}/{id}/{entity}
  // "event"             ==> /events/{id}
  // "command"/"query"   ==> /audit/... (TO BE DEFINED?)
  // "role"/"permission" ==> are not persisted in any way or form
  const { resource, path, id } = dataFrom(urn);
  if (resource !== "aggregate" && resource !== "event")
    throw new Error(`Resource type '${resource}' not supported.`)

  return resource === "event" ? `events/${id}`: `snapshots/${path}`;
}

// FirestoreErrorCode: https://firebase.google.com/docs/reference/js/firebase.firestore#firestoreerrorcode
export const mapFirestoreErrorCode = (firestoreErrorCode) => firestoreErrorCode === "permission-denied" || firestoreErrorCode === "unauthenticated" ? codes.PERMISSION_ERROR
                                                           : firestoreErrorCode === "deadline-exceed"    ? codes.TIMEOUT_ERROR
                                                           : firestoreErrorCode === "not-found"          ? codes.NOT_FOUND_ERROR
                                                           : firestoreErrorCode === "resource-exhausted" ? codes.QUOTA_ERROR
                                                           : firestoreErrorCode === "unavailable"        ? codes.UNAVAILABLE_SERVICE_ERROR
                                                           : firestoreErrorCode === "already-exists"     ? codes.DUPLICATE_ERROR
                                                           : codes.INTERNAL_SYSTEM_ERROR;
const sanitize = (doc)  => {
  for (var i in doc ) {
    if (doc[i] === undefined)       { delete doc[i];                 continue; }
    if (doc[i] instanceof Function) { delete doc[i];                 continue; }
    if (doc[i] instanceof Array)    { doc[i] = doc[i].map(sanitize); continue; }
    if (doc[i] instanceof Object)   { doc[i] = sanitize(doc[i]);     continue; }
  }
  return doc;
}

/* TODO: design APIs to use both instances of models and aggregates...
      aggrInst: { context: { name: -- }, aggregate: { name: ---, id: --- } }
      Aggr: { context: --- name: --- }
*/
const makeDataBase = (db) => Object.freeze({
  dispatch : (event) => isBrowser() ? require('./database.client').dispatch(event): require('./database.server').dispatch(event),
  audit    : ()      => makeCollection(db.collection('audit'), undefined, undefined, db),
  events   : (id, type='events') => id ? makeDocument(db.doc(urn2DocPath(id)), db) : makeEvents(db.collection(type), db), // Could be atm: events, audit or client-events
  snapshots: (modelOrId, parentId) => {
    if (modelOrId?.aggregate?.id || modelOrId?.urn || (typeof modelOrId === "string" && modelOrId.startsWith('urn:')) ) {
      const aid = modelOrId?.aggregate?.id || modelOrId?.urn || modelOrId;
      return makeSnapshotDocument(db.doc(urn2DocPath(aid), db));
    }

    let colpath = 'snapshots';
    const model = modelOrId;
    if (model) {
      const { context, aggregate, name } = model || {};
      const ctx   = context?.name || context;
      const aname = aggregate?.name || name || aggregate;
      
      const namepath = aname.split('/');
      const entity = namepath.length > 1 ? aname.split('/').pop() : undefined;
      if (entity && parentId) {
        if (!parentId.includes(`${ctx}/`) || namepath.slice(0, -1).some(parentName => !parentId.includes(`/${parentName}/`))) 
          throw new Error("Wrong parent id for given snapshot model", model.name, parentId);
        colpath = `${colpath}/${ctx}/${parentId.split(`${ctx}/`)[1]}/${entity}`;
      } else if (entity) {
        //console.debug(`Quering entity '${ctx}/${aname}'. CollectionGroup: ${entity}, Context: ${ctx}, Aggregate: ${aname}`)
        return makeSnapshotCollection(db.collectionGroup(entity), entity, namepath.slice(0, namepath.length-1), db).from({ context: { name: ctx }, aggregate: { name: aname }}); // dodgy
      } else colpath = `${colpath}/${ctx}/${aname}`;
    }

    return makeSnapshotCollection(db.collection(colpath), undefined, undefined, db);
  }
});

const makeSnapshotDocument = (fireDoc, db) => ({
  ...makeDocument(fireDoc, db),
  refresh:  (eventsToSnapshotFn) => fireDoc.firestore.runTransaction(async t => {
    // ensures this snapshot document is always updated from the events list ensuring events order
    // Transaction failures: https://firebase.google.com/docs/firestore/manage-data/transactions#transaction_failure
    // - this transaction is retried automatically if the current snapshot is updated by a different transaction
    const _doc  = await t.get(fireDoc); // the target document may not exists, so we can't rely on it.
    
    const [, context, ...entities] = fireDoc.path.split('/');
    const model = { // TODO: a hack ATM but we need generals rules so the database can convert paths 2 urns and urn 2 paths (and urn 2 model object)
      context: { name: context },
      aggregate: {
        id: `urn:com:acurable:apsa:aggregate:${context}/${entities.join('/')}`, 
        name: entities.filter((_, i) => i%2 === 0).join('/')
      }      
    };
    const transform = require('../event').transform(model, { skipOtherInstances: true });
    const events = await DataBase(fireDoc.firestore).events().from(model).list().then(transform); // this always loads the full list of events for the given snapshot
    const [curr, prev] = await eventsToSnapshotFn(events, t);
    if (curr.metadata?.deleted) { 
      t.delete(fireDoc);
      return [undefined, prev];
    }

    t.set(fireDoc, sanitize(curr));
    return [curr, prev];
  })
});

const makeSnapshotCollection = (fireCol, name, parentPath, db) => ({
  ...makeCollection(fireCol, name, parentPath, db), // collectionGroup (used for entities) returns a query, so collection methods cannot be used
  ...makeSnapshotQuery(fireCol, name, parentPath, db),
});

const makeSnapshotQuery = (fireCol, name, parentPath, db) => {
  let _query = makeQuery(fireCol, name, parentPath, db);

  const toSnapPred = ([field, op, val]) => [field === "id" ? "aggregate.id" : field.startsWith('data.') || field.startsWith('metadata.') ? field : `data.${field}`, op, val];

  const _this = () => Object.freeze({
    from        : ({context, aggregate}) => { _query = _query.withContext(context).withAggregate(aggregate); return _this(); },
    orderBy     : (field, order)        => { _query = _query.orderByKey(field, order); return _this(); },
    startAfter  : (...args)      => { _query = _query.orderByKey('metadata.lastEvent').startAfter(...args); return _this(); },
    ownedBy     : (...args)      => { _query = _query.ownedBy(...args); return _this(); },
    isParent    : (...args)      => { _query = _query.isParent(...args); return _this(); },
    with        : (field, valueOrOp, value) => { _query = _query.and(field === "id" ? "aggregate.id" : field.startsWith('data.') || field.startsWith('metadata.') ? field : `data.${field}`, value != undefined ? valueOrOp : '==' , value != undefined ? value : valueOrOp); return _this(); }, // eslint-disable-line eqeqeq
    withAny     : (...constrainsts)         => { _query = _query.andAny(...constrainsts.map(predOrConjuction => Array.isArray(predOrConjuction[0]) ? predOrConjuction.map(toSnapPred) : [toSnapPred(predOrConjuction)])); return _this(); },
    or          : (field, valueOrOp, value) => { _query = _query.or(field === "id" ? "aggregate.id" : `data.${field}`, value != undefined ? valueOrOp : '==' , value != undefined ? value : valueOrOp); return _this(); }, // eslint-disable-line eqeqeq
    withMetadata: (field, valueOrOp, value) => { _query = _query.and(`metadata.${field}`, value != undefined ? valueOrOp : '==' , value != undefined ? value : valueOrOp); return _this(); }, // eslint-disable-line eqeqeq
    count       : _query.count,
    get         : _query.get,
    subscribe   : _query.subscribe,
    paginate    : _query.paginate,
    onDeleted   : (...args)      => { _query = _query.onDeleted(...args); return _this(); },
    onCreated   : (...args)      => { _query = _query.onCreated(...args); return _this(); },
    onUpdated   : (...args)      => { _query = _query.onUpdated(...args); return _this(); },
    onReady     : (...args)      => { _query = _query.onReady(...args); return _this(); },
    onError     : (...args)      => { _query = _query.onError(...args); return _this(); },
    limit       : (amount)       => { _query = _query.limit(amount); return _this(); },
    asQuery     : ()             => _query,
    //getLast     : ()             => _query.orderByKey('metadata.lastEvent', 'descending').limit(1).get()
    toString    :  _query.toString
  });

  return _this();
}

const makeEvents = (fireCol, db) => {
  
  let _afterSeq  = undefined;
  let _maxSeq    = undefined;
  let _aggregate = undefined;
  let _context   = undefined;

  const asQuery  = () => {
    let eventsQuery = makeQuery(fireCol, db).withContext(_context || {}).withAggregate(_aggregate || {});
    if (_afterSeq !== undefined || _maxSeq !== undefined) eventsQuery = eventsQuery.orderByKey('seq');
    if (_afterSeq !== undefined) eventsQuery = eventsQuery.startAfter(_afterSeq);
    if (_maxSeq !== undefined)   eventsQuery = eventsQuery.endAt(_maxSeq);
    return eventsQuery;
  }

  const _this    = () => Object.freeze({
    endAt       : (event)                => {_maxSeq = (event || {}).seq ? event.seq : event; return _this();},
    startAfter  : (event)                => {_afterSeq = (event || {}).seq ? event.seq : event; return _this();},
    from        : (reference)            => {
      _context = reference?.context && (reference?.context?.name ? reference?.context : {name: reference?.context}); 
      _aggregate = reference?.aggregate ? (reference?.aggregate?.name ? reference?.aggregate : {name: reference?.aggregate}) : reference?.name ? {name: reference?.name} : undefined; 
      
      return _this();
    },
    forContext  : (context)              => {_context = context; return _this();},
    forAggregate: (aggregate)            => {_aggregate = aggregate; return _this();},
    asQuery,
    set         : (id, data)             => makeDocument(fireCol.doc(resolve(id)), db).set(data),
    setBatch    : (events)               => makeCollection(fireCol).setBatch(events),
    get         : (id)                   => makeDocument(fireCol.doc(resolve(id)), db).get(),
    list        : ()                     => asQuery().get(), //By default, a query retrieves all documents that satisfy the query in ascending order by document ID so events are always ordered  https://firebase.google.com/docs/firestore/query-data/order-limit-data#order_and_limit_data
    ownedBy     : (user)                 => asQuery().ownedBy(user)
  });

  return _this();
}

const makeCollection = (fireCol, name, parentPath, db=fireCol.firestore) => Object.freeze({
  asQuery     : ()                      => makeQuery(fireCol, name, parentPath, db), // TODO: deprecate. provide high level funcs only
  from        : ({context, aggregate})  => makeQuery(fireCol, name, parentPath, db).withContext(context).withAggregate(aggregate),
  forContext  : (context)               => makeQuery(fireCol, name, parentPath, db).withContext(context),
  forAggregate: (aggregate)             => makeQuery(fireCol, name, parentPath, db).withAggregate(aggregate),
  set         : (id, data)              => { if (fireCol.path) return makeDocument(fireCol.doc(resolve(id)), db).set(data); else throw new Error("Cannot set data on collection groups"); },
  setBatch    : async (events)          => { 
    if (!fireCol.path) throw new Error("Cannot set data on collection groups");
    const batch = db.batch();
    await Promise.allSettled(events.map(event => batch.set(fireCol.doc(event.id.split('/').pop()), event)));
    await batch.commit();
  },
  list        : ()                      => makeQuery(fireCol, name, parentPath, db).get(),
  delete      : async (options)         => {
    const onWriteResults = []
    const NULL_BULKWRITER = { close: _ => {}, flush: _ => {}, delete: ref => { console.log('[DATABASE]: DELETE - ', ref.path); onWriteResults.forEach(f => f(ref))}, onWriteResult: f => onWriteResults.push(f) };
    const bulkWriter = options?.bulkWriter || (options?.dryRun ? NULL_BULKWRITER : fireCol.firestore.bulkWriter());
    if (options?.onDeleted) {
      bulkWriter.onWriteResult(doc => options.onDeleted(doc, options));
    }

    try {
      await deleteCollection({...options, bulkWriter})(fireCol);
    } finally {
      await bulkWriter.close(); // never raises errors
    }
  }
});

function deleteCollection(options={}) {
  return async collection => {
    let lastDeleted;
    do {
      try {

        // this could be speed up significantly by partitioning the collection into multiple streams. 
        // We would need to count the total documents (count() query was added in firebase-admin@11.2.0) and then create as many partitions as desired.

        // eslint-disable-next-line no-loop-func
        await new Promise((_resolve, reject) => { // eslint-disable-line no-await-in-loop
          const query = lastDeleted ? collection.orderBy('__name__').startAfter(lastDeleted) : collection.orderBy('__name__');
          lastDeleted = undefined;
          query.limit(options.queryLimit || 100000).stream() // only retrieves existing documents within this collection.
            .on('data',  async r => { lastDeleted=r; await deleteDocument(options)(r); })
            .on('error', reject) 
            .on('end',   _resolve);
        });
      } catch(e) {
        if (e.code !== 9) { throw e; } //  error.code === 9 => error.details === 'The requested snapshot version is too old.' ... for very large collections. Pagination solves this (reduce the limit to avoid it)
        console.warn('Resuming after error 9 from', lastDeleted.path);
        lastDeleted = await lastDeleted.get(); // eslint-disable-line no-await-in-loop
      }
      await options.bulkWriter.flush(); // eslint-disable-line no-await-in-loop
    } while(lastDeleted)
    
    if (options.recursive && collection.listDocuments) {   // list "missing" documents === documents without data that contain nested collections https://googleapis.dev/nodejs/firestore/latest/CollectionReference.html#listDocuments
      const refs = await collection.listDocuments(); // this may need pagination too, but previous step should remove large collections
      const docs = (await Promise.all(refs.map(r => r.get()))).filter(d => !d.exists); // nested collections
      await Promise.all(docs.map(deleteDocument(options)));
    }

    // the collection itself will likely need to be deleted if it has no documents left?
  }
}

function deleteDocument(options) {
  const { bulkWriter, where: shouldDelete, recursive, onDelete } = options;  
  return async doc => {
    doc = await doc;
    if (doc.exists) {
      if (!shouldDelete || shouldDelete?.(doc.data())) {
        if (recursive) {
          await Promise.all((await doc.ref.listCollections()).map(deleteCollection(options)))
        }
        await onDelete?.(doc, options); // satisfy preconditions first just in case the actual delete fails ??
        bulkWriter.delete(doc.ref);
      }

    } else if (recursive) { // Delete document's subcollections if any
      await Promise.all((await doc.ref.listCollections()).map(deleteCollection(options)))
    }
  }
}

const makeDocumentTransaction = (fireDoc, t) => {
  return {
    set: (d) => t.set(fireDoc, sanitize(d)),
  };
};

const makeDocument = (fireDoc, db) => Object.freeze({
  id     : fireDoc.id,
  path   : fireDoc.path,
  get    : ()     => fireDoc.get().then(content => content.data() || null),
  delete : ()     => fireDoc.delete(),
  exists : ()     => fireDoc.get().then(content => content.exists),
  set    : (data) => {
    const storedData = data;
    if ((fireDoc.path.split('/')[0] === 'events' && (data.type === "USER_AUTHENTICATED" || data.type === "USER_LOGOUT")) // TODO: deprecate once these user events are moved to structured logs and login event is taken from cloud provider
      || fireDoc.path.split('/')[0] === 'audit') storedData.metadata.ttl = getAuditTTL(data.timestamp); // TODO: Deprecate once audit is implemented as structured logs and audit collection deleted
    return fireDoc.set(sanitize(storedData));
  },
  asQuery: ()     => {
    const [, context, name, id] = fireDoc.path.split('/');
    return makeQuery(fireDoc.parent, undefined, undefined, db).withAggregate({id: aggregateURN({ context, name }, id)})
  },
  runTransaction: (transactionFn) => fireDoc.firestore.runTransaction(async (t) => {
    const _doc = await t.get(fireDoc);
    return transactionFn(makeDocumentTransaction(fireDoc, t));
  }),
  subscribe: (onChange, onError) => { // returns unsubscribe function
    return fireDoc.onSnapshot((doc) => {
      onChange({
        exists: doc.exists,
        get snapshot() { return doc.data() },
      });
    }, (error) => onError?.(error));
  },
});

const makeQuery = (fbcol, colName=fbcol.path?.split('/').pop(), parentPath, db) => {
  let _queries     = [[]]; // outer list represents an OR and each internal list is a conjunction of predicates/constraints (i.e. ANDS). Each predicate/constraint is represented as [field, operator, value]: e.g. ['name', '==', 'APSA'] (supported operators same as firestore operators atm)
  let _handlers    = [];
  let _endAt       = undefined;
  let _startAfter  = undefined;
  let _orderByKey  = undefined;
  let _order       = undefined;
  let _limitAmount = undefined;
  let _collection  = colName;
  let _ownedBy     = undefined;

  const requiresOrder = pred => _orderByKey !== undefined && pred[0] !== _orderByKey && INEQUALITY_PREFIXES.some(op => pred[1].startsWith(op));
  const orderFields = idx => (idx === undefined ? _queries.reduce((orderCons, conjunc) => orderCons.concat(conjunc.filter(requiresOrder).map(pred => pred[0])), []) 
                                                : _queries[idx].reduce((orderCons, pred) => orderCons.concat(requiresOrder(pred) ? pred[0] : []), []))
                             .filter((field, fidx, all) => all.findIndex(f => f === field) === fidx);
  
  const _this     = ()    => Object.freeze({ isParent, count, ownedBy, orderByKey, startAfter, endAt, and, or, andAny, withContext, withAggregate, get, paginate, onDeleted, onCreated, onUpdated, onReady, onError, limit, subscribe, toString });
  const numConstraints = () => _queries.reduce((cs, and) => cs + and.length, 0);
  const _print = (qs) => `${_collection}${qs.filter(q => q && q.length > 0).length > 0 ? ` WHERE ${qs.map(q => q.map(cs => cs.map(JSON.stringify).join(' ')).join(' AND ')).join(' OR ')}` : ''}${_orderByKey ? ` ORDER BY ${orderFields().map(field => field + ' ASC').concat(_orderByKey ? `${_orderByKey}${_order ? ` ${_order.toUpperCase()}` : ''}` : [])}` : ''}${_startAfter ? ` START AFTER ${_startAfter}` : ''}${_endAt ? ` END AT ${_endAt}` : ''}${_limitAmount ? ` LIMIT ${_limitAmount}` : ''}`;
  const toString  = ()    => _print(_queries);

  const _getContraintsFor = (attr) => _queries.reduce((all, cs) => all.concat(cs.filter(([at]) => at === attr)), []);

  const and = (attr, operator, value) => {
    _queries.forEach(q => q.push([attr, operator, value]));
    
    return _this();
  }

  const or = (attrOrPredOrConjunction, operator, value) => {
    const applyPred = (attr, op, val) => {
      if (numConstraints() === 0) return and(attr, op, val);
    
      _queries.push([[attr, op, val]]);

      return _this();
    }

    if (typeof attrOrPredOrConjunction === "string") {
      return applyPred(attrOrPredOrConjunction, operator, value);
    } else if (Array.isArray(attrOrPredOrConjunction) && !Array.isArray(attrOrPredOrConjunction[0])) { // is pred
      return applyPred(...attrOrPredOrConjunction);
    } else { // is conjunction
      if (numConstraints() === 0) return attrOrPredOrConjunction.reduce((_, pred) => and(...pred), _this());
      _queries.push(attrOrPredOrConjunction);
      return _this();
    }
  }

  const andAny = (...disjunction) => {
    if (disjunction.length === 0) return _this();
    if (numConstraints() === 0) return disjunction.reduce((_, predOrConjunction) => or(predOrConjunction), _this());
    
    const newConjunctions = [];
    _queries.forEach(conjunction => {
      const originalConjunction = [...conjunction];
      disjunction.forEach((predOrConjunction, idx) => { 
        if (idx === 0) conjunction.push(...(Array.isArray(predOrConjunction[0]) ? predOrConjunction : [predOrConjunction]));
        else newConjunctions.push(originalConjunction.concat(Array.isArray(predOrConjunction[0]) ? predOrConjunction : [predOrConjunction]));
      });
    });
    newConjunctions.forEach(conjuncion => _queries.push(conjuncion));
    
    return _this();
  }

  const ownedBy = (user) => {
    _ownedBy = user;

    return _this();
  };

  const _applyOwnedBy = () => {
    // Skipping ownedBy in case of Acurable admin users because of Firestore limitation on array-contain* operator (can only be used once per query)
    // TODO: remove (!isBrowser && isSuperAdmin) temporal workaround once Firestore limitation of array-contains operator is fixed (see TODO in _compile method)
    if (!_ownedBy || (!isBrowser() && _ownedBy?.isSuperAdmin()) || PUBLIC_COLLECTIONS.some(c => c === _collection)) return _this();

    const {data: {owners}, metadata: {allOwners}} = _ownedBy;

    const hasOwnership = oid => oid.includes('/Organisation/') || oid.includes('/HealthcareSite/') || oid.includes('/User/') || oid.includes(`/${_collection}/`) || oid.includes(`/${parentPath?.pop()}/`);
    const directOwners = owners.filter(hasOwnership);
    const parentOwners = allOwners.filter(o => hasOwnership(o) && !owners.includes(o));
    const isQueryForParent  = _getContraintsFor('aggregate.id').concat(_getContraintsFor('data.id')).some(([_attr, _op, id]) => parentOwners.includes(id));
    const checkforChildrens = !_getContraintsFor('metadata.allOwners').length && !isQueryForParent;
    
    const ownershipDisjunction = [];
    if (directOwners.length > 0 && checkforChildrens)  ownershipDisjunction.push(['metadata.allOwners', 'array-contains-any', directOwners]);
    if (_collection === 'Preferences') ownershipDisjunction.push(['data.owners', 'array-contains-any', allOwners]);
    
    andAny(...ownershipDisjunction);
    
    return _this();
  }

  const isParent = (owner) => and('metadata.allOwners', 'array-contains', typeof owner === "string" ? owner : (owner.aggregate || owner.data).id);

  const limit = (amount) => { _limitAmount = amount; return _this(); };

  const orderByKey = (key, order)      => { _orderByKey = key; _order = order; return _this(); }
  const endAt      = (orderKey) => { _endAt = orderKey; return _this(); };
  const startAfter = (orderKey) => { _startAfter = orderKey; return _this(); };

  const withContext = ({name}) => (name) ? and('context.name', '==', name) : _this(); // TODO: if this is called for same query several times, we need to use or the second time
  const withAggregate = ({ name, id }) => { // TODO: if this is called for same query several times, we need to use or the second time
    if (name) and('aggregate.name', '==', name);
    return (id) ? and('aggregate.id', '==', id) : _this();
  }

  
  const wrapError = (error, message) => {
    const code = mapFirestoreErrorCode(error.code);
    return {
      isError: true,
      code,
      reason: require('../errors/messages').getReason(code),
      resolution: require('../errors/messages').getResolution(code),
      message: message || error.message, stack: error.stack
    };
  };

  const _on = (predicate, handler) => {
    _handlers.push((docs, type, docsMetadata) => predicate(docs, type, docsMetadata) && Boolean(handler(docs)));
    return _this();
  }
  let _onSubscriptionReady = _size => false;
  let _onSubscriptionError = _error => false;

  const onDeleted   = (handler = () => false) => _on((_, type) => type === "removed",  handler);
  const onCreated   = (handler = () => false) => _on((_, type) => type === "added",    handler);
  const onUpdated   = (handler = () => false) => _on((_, type) => type === "modified", handler);
  const onReady     = (handler = _size => false)  => {_onSubscriptionReady = handler; return _this();};
  const onError     = (handler = _error => false) => {_onSubscriptionError = handler; return _this();};

  // Compiles internal query structure into firebase query/ies (firebase does not implement ORs, so each OR is a different Firebase query)
  // TODO: workaround for Firestore limitation on the usage of array-contains* operator (con only be used once per query): compile queries into multiple queries (one per this type of operator) and intersect results??
  const _customOps = (WHERE) => ({
    'starts-with': (key, val) => [WHERE(key, '>=', val), WHERE(key, '<=', val + '\uf8ff')] // see as reference: https://stackoverflow.com/questions/46568142/google-firestore-query-on-substring-of-a-property-value-text-search
  });

  const _compileWhere = ([key, op, val], WHERE) => _customOps(WHERE)[op]?.(key, val) || [WHERE(key, op, val)];
  
//////////////////
///////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
/////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
////////////

  const _compileBrowser = (opts={}) => {
    const {or: OR, and: AND, where: WHERE, orderBy: ORDER_BY, startAfter: START_AFTER, endAt: END_AT, limit: LIMIT } = require('firebase/firestore')

    const {startAfter: startAfterOpt, visited = [], setVisited, ...options} = opts;
    
    const startAfterRef = startAfterOpt !== undefined ? [startAfterOpt] : visited.length ? visited.at(-1) : [_startAfter].filter(Boolean);
    const endAtRef = startAfterRef.length ? undefined : _endAt;
    const orderBy  = _orderByKey !== undefined  ? _orderByKey : [...startAfterRef, endAtRef].some(Boolean) ? 'data.id' : undefined;
    
    const compileConstraints = idx => (idx === undefined ? [OR(..._queries.map(cs => AND(...cs.reduce((all, c) => all.concat(_compileWhere(c, WHERE)), []))))] 
    : [AND(..._queries[idx].reduce((all, c) => all.concat(_compileWhere(c, WHERE)), []))])
    .concat(orderFields(idx).concat(orderBy || []).map(field => ORDER_BY(field, field === _orderByKey ? _order : undefined)))
    .concat(startAfterRef.length ? [START_AFTER(...startAfterRef)] : [])
    .concat(endAtRef !== undefined      ? [END_AT(endAtRef)] : [])
    .concat(_limitAmount !== undefined  ? [LIMIT(_limitAmount)] : []);
    
    const {query: buildQuery, collection, collectionGroup, getCountFromServer, getDocs, onSnapshot} = require('firebase/firestore');
    const col = 'path' in fbcol ? collection(fbcol.firestore, fbcol.path) : collectionGroup(db, colName);
    
    const queries = options?.runORAsMultipleRequests ? _queries.map((_, idx) => buildQuery(col, ...compileConstraints(idx))) : [buildQuery(col, ...compileConstraints())];

    return {
      count: _ => Promise.all(queries.map(query => getCountFromServer(query)
                    .then(c => c.data().count)))
                  .catch(e => Promise.reject(new Error(`Query count failed: ${wrapError(e, `${toString()} failed: ${e.toString()}`).message}`)))
                  .then(counts => counts.reduce((sum, c) => sum + c, 0)),
      get: _ => Promise.all(queries.map(query => getDocs(query)
                  .then(qsnap => qsnap.docs.map(d => d.data()))))
                .catch(e => Promise.reject(new Error(`Query fetch failed: ${wrapError(e, `${toString()} failed: ${e.toString()}`).message}`)))
                .then(results => results.reduce((all, docs) => {all.push(...docs); return all;}, [])),
      paginate: () => ((opts?.debug && console.debug("DB paginate", { startAfterRef })) || Promise.all(queries.map(query => getDocs(query)))
        .catch(e => Promise.reject(new Error(`Query pagination failed: ${wrapError(e, `${toString()} failed: ${e.toString()}`).message}`)))
        .then(results => results.reduce((all, qsnap) => { all.push(...qsnap.docs); return all; }, []))
        .then(docs => docs.map(d => { d.orderValue = (orderBy || 'data.id').split('.').reduce((prev, f) => prev?.[f], d.data()); return d; }))
        .then(docs => _limitAmount >= 0 ? docs.slice(0, _limitAmount) : docs)
        .then(docs => {
          opts?.debug && console.debug("DB new page docs", docs.map(d => d.data()));
          //TODO: I don't think it's still the final solution but it works for now... What happens if the nextRef values are the same? It continues fetching the same page? When passing the docs.at(-1) and filtering by created date it adds a 3rd field, but it's a reference, don't know how to add it...
          const nextRef = docs.at(-1) ? queries[0]._query.explicitOrderBy.reduce((acc, curr) => {
            const value = curr.field.segments.reduce((prev, f) => prev?.[f], docs.at(-1).data());
            if (value !== undefined) acc.push(value);
            return acc;
          }, []) : [];
          return {
            //! Sorting must be done at the end! Otherwise we might not really be getting the last doc!
            docs: sort((_order === 'asc' ? ascend : descend)(prop('orderValue')))(docs).map(d => d.data()),
            prev: function () { visited.pop(); setVisited(visited); return _compileBrowser({ ...options, visited, setVisited }).paginate() }, // Assuming we are not going to navigate too many pages with next, otherwise this implementation is not performant and might not scale
            next: nextRef && function () { visited.push(nextRef); setVisited(visited); return _compileBrowser({ ...options, visited, setVisited }).paginate() },
          };
        })),
      subscribe: _ => {
        let numReady = 0;
        return queries.reduce((unsubscribeAc, query) => {
          let subscription;

          // When query includes already existing documents, first call to next returns a list of changes all of type "added"
          const next  = snapshot => {
            //console.debug("Subscription next", toString(), snapshot.size, snapshot.metadata)
            const changes = {};
            snapshot.docChanges().forEach(c => changes[c.type] = (changes[c.type] || []).concat(c.doc.data()));
            Object.entries(changes).forEach(([type, docs]) => _handlers.forEach(f => f(docs, type, snapshot.metadata)));
            if (subscription && !subscription.ready) {
              subscription.ready = true;
              if (++numReady === queries.length) _onSubscriptionReady(snapshot.size);
            }
          };

          const error = e  => { 
            subscription(); // unsubscribe
            _onSubscriptionError(new Error(`Error while subscribed to ${toString()}: ${wrapError(e).message}`));
          };    
    
          //console.debug("Starting subscription", toString())
          subscription = onSnapshot(query, { includeMetadataChanges: options?.useCache }, next, error);
          
          return () => { unsubscribeAc(); if (subscription && (typeof subscription === 'function')) subscription(); subscription = undefined; };
        }, () => {})
      }
    }
  }

  const _applyArrayConstraints = (queries) => {
    if (!queries.length) return;

    const additionalQueries = [];

    queries.forEach(query => {
      query.forEach((op, opIndex) => {
        const value = op[2];
        if (value instanceof Array && value.length > 10) {
          const chunkOfValues = splitArrayIntoChunks(value, 10);
          chunkOfValues.forEach((chunkValue, chunkIndex) => { // eslint-disable-line consistent-return
            if (chunkIndex === 0) return query[opIndex] = [op[0], op[1], chunkValue];
            const newQuery = [...query];
            newQuery[opIndex] = [op[0], op[1], chunkValue];
            additionalQueries.push(newQuery);
          });
        }
      });
    });

    if (!additionalQueries.length) return;
    
    _queries.push(...additionalQueries);
    _applyArrayConstraints(additionalQueries);
  };

  const _compile = (options) => {
    _applyOwnedBy();
    _applyArrayConstraints(_queries);
    // console.debug("database query toString", toString());
////////////////////
//////////////////////////////////////////////////////
//////////////
    if (isBrowser()) return _compileBrowser(options);

    return undefined;
  }
  
  const count = (options) => _compile(options).count();
  
  const get = (options={source: 'default'}) => _compile(options).get(); // Check GetOptions in Firestore reference

  const paginate = (options={source: 'default'}) => _compile(options).paginate(); // Check GetOptions in Firestore reference

  const subscribe = (options={useCache: false}) => ({ unsubscribe: _compile(options).subscribe() });
  
  return _this();
}

export const DataBase = db => {
  if (isBrowser() && !db) {
    db = require('../../../firebase').app.firestore();
    if (process.env.REACT_APP_USE_EMULATOR === "1") {
      db.useEmulator("localhost", process.env.REACT_APP_FIRESTORE_EMULATOR_PORT);
    }
  }
//////////////////
////////////////////////////
/////////////////////////////////////////////////////
///
////////////

  return makeDataBase(db);
}