import { useState, useEffect } from "react";

import useLocationParams from "ui/hooks/useLocationParams";

import { SummaryOverviewStep } from "./SummaryOverviewStep";

import './styles.css';
import { SummaryLabelsStep } from "./SummaryLabelsStep";

const Step = Object.freeze({
    Labels: {
        name: 'labels',
        index: 0
    },
    Summary: {
        name: 'summary',
        index: 1
    },
});

const navigationComponents = {
    [Step.Summary.name]: SummaryOverviewStep,
    [Step.Labels.name]: SummaryLabelsStep
};
const orderedBoxSteps = [Step.Labels.name, Step.Summary.name];

export const CreateKitSummaryStep = ({ selectedBoxes, selectedOwners, ...props }) => {
    const [locState, setLocState] = useLocationParams();
    const [boxIndex, setBoxIndex] = useState(locState?.boxIndex ?? 0);
    const [upcomingBoxIds, setUpcomingBoxIds] = useState([]);

    const currentBoxLayout = orderedBoxSteps[boxIndex] ?? Step.Summary.name;
    const StepComponent = navigationComponents[currentBoxLayout];

    const currentBox = selectedBoxes[0] ?? locState?.selectedBoxes[0];

    const goToLabelsStep = () => setBoxIndex(Step.Labels.index);
    const goToOverviewStep = () => setBoxIndex(Step.Summary.index);

    useEffect(() => setLocState({ ...locState, boxIndex }), [boxIndex]);

    return (
        <StepComponent
            box={currentBox}
            owners={selectedOwners.map(o => o.id)}
            goToOverviewStep={goToOverviewStep}
            goToLabelsStep={goToLabelsStep}
            upcomingBoxIds={upcomingBoxIds}
            setUpcomingBoxIds={setUpcomingBoxIds}
            {...props}
        />
    );
};
