import { Reports } from "features/reports/helpers/tableReportConfig";
import { Roles } from 'services/server/functions/iam/roles';
import ServerConfig from 'services/server/functions/model/diagnosis/config';
import { Study } from 'services/server/functions/model/diagnosis/model';

const modulePriority = 500;
const basePath = Study.name.toLowerCase();

const FindStudy = { priority: modulePriority + 1,  relpath: `/${basePath}/find`,       roles: [Study.queries.FIND_SLEEP_STUDY.roles, ...Object.values(Reports).map(r => r.query.roles)] };

export default {
  context: ServerConfig.context,
  priority: modulePriority,
  routes: {
    StudyLanding        : { priority: modulePriority + 0,  relpath: `/${basePath}`,            roles: [Study.commands.CREATE_STUDY.roles, ...FindStudy.roles] },
    FindStudy,
    FindStudyEvents     : { priority: modulePriority + 15, relpath: `/${basePath}/events`,     onlyAcurableUsers: true },
    ManageFulfilment    : { priority: modulePriority + 14, relpath: `/${basePath}/fulfil`,     roles: [Roles.superAdmin.id], options: { skipContext: true } },
    CreateStudy         : { priority: modulePriority + 2,  relpath: `/${basePath}/create`,     roles: Study.commands.CREATE_STUDY.roles },
    CreateStudyBatch    : { priority: modulePriority + 3,  relpath: `/${basePath}/createBatch`, onlyAcurableUsers: true, roles: Study.commands.CREATE_STUDY.roles },
    EditStudy           : { priority: modulePriority + 4,  relpath: `/${basePath}/edit`,       roles: [Study.commands.UPDATE_PATIENT_DETAILS.roles, Study.commands.UPDATE_STUDY.roles] },
    //UsageReport         : { priority: modulePriority + 4,  relpath: `/${basePath}/usage`,      roles: [Study.roles.viewer.id] },
    ViewStudy           : { priority: modulePriority + 5,  relpath: `/${basePath}/view`,       roles: Study.queries.VIEW_STUDY_SUMMARY.roles },
    ViewTest            : { priority: modulePriority + 6,  relpath: `/${basePath}/test`,       roles: Study.entities.Test.queries.VIEW_DIAGNOSIS_SUMMARY.roles },
    //ViewEvents          : { priority: modulePriority + 6,  relpath: `/${basePath}/events`,     roles: [Study.roles.viewer.id] },
    //EditComments        : { priority: modulePriority + 9,  relpath: `/${basePath}/comments`,   roles: [Study.roles.manager.id] },
    UploadTestFile      : { priority: modulePriority + 13,  relpath: `/${basePath}/upload`,    roles: Study.commands.CREATE_STUDY.roles },
  }
};
