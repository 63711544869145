import { useEffect, useRef, useState } from "react";
import { Avatar, Typography, List, ListItem, ListItemAvatar, ListItemText, TextField, Button } from '@mui/material';

import i18n from "services/server/functions/i18n";
import useCurrentUser from "ui/hooks/useCurrentUser";

const DateTime = ({ date }) => {
    return (
        <ListItemText
            primary={date?.toLocaleDateString()}
            secondary={
                <Typography variant="body2" component="span">
                    {date?.toLocaleTimeString()}
                </Typography>
            }
            style={{ textAlign: 'right' }}
        />
    );
};

export const CommentSection = ({ comments = [], onAddComment }) => {
    const listRef = useRef(null);
    const [userSnap] = useCurrentUser();

    const [text, setText] = useState(null);

    useEffect(() => {
        if (comments.length) {
            const list = listRef.current;
            list.scrollTo({ top: list.scrollHeight, behavior: "smooth" });
        }
    }, [comments]);

    const submitComment = async () => {
        const result = await onAddComment({
            userId: userSnap?.aggregate.id,
            text,
        });
        if (result) setText(null);
    };

    return (
        <div>
            {comments.length === 0 ? <div style={{ marginBottom: '16px' }}>{i18n.resolve('fulfilment.comments.none')}</div> :
                <List ref={listRef} style={{ maxHeight: 250, overflow: "auto" }} >
                    {comments.sort((a, b) => a.date - b.date).map((comment) => {
                        return (
                            <ListItem key={comment.id} alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="User Avatar" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={comment.user?.name || i18n.resolve('global.anonymous')}
                                    secondary={
                                        <Typography variant="body2" component="span">
                                            {comment.text}
                                        </Typography>
                                    }
                                />
                                <div style={{ width: 100 }}>
                                    <DateTime date={comment.date} />
                                </div>
                            </ListItem>
                        )
                    })}
                </List>
            }

            <TextField
                label={i18n.resolve("fulfilment.comments.add.text")}
                variant="outlined"
                fullWidth
                style={{ marginBottom: "10px" }}
                onChange={(event) => setText(event.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => submitComment()}
                disabled={!text?.length}
            >
                {i18n.resolve("fulfilment.comments.add.button")}
            </Button>
        </div>
    );
};