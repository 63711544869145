import i18n from "services/server/functions/i18n";

import { getBaseColumnsLayer } from "features/fulfilment/components/molecules/Table/uiLayers";
import { InfoBoxLabel } from "features/fulfilment/components/atoms/InfoBoxLabel";
import { AppColors } from "features/fulfilment/components/themes";
import { FlexBox, flexRowJustifyItemsCenter } from "features/fulfilment/components/atoms/Layout";


export const getCreateKitColumns = () => getBaseColumnsLayer([
    {
        field: 'name',
        headerName: i18n.resolve('fulfilment.kit.create.steps.kit_model.model'),
        renderCell: (cellProps) => <InfoBoxLabel label={cellProps?.value} bgColor={cellProps?.row?.hexColorCode} />,
    },
    {
        field: 'description',
        headerName: i18n.resolve('fulfilment.kit.create.steps.kit_model.short_desc'),
        width: 300,
    },
    {
        field: 'boxesQuantity', headerName: i18n.resolve('fulfilment.kit.create.steps.kit_model.quantity'), width: 500, editable: true,
        renderCell: (cellProps) =>
            <FlexBox sx={flexRowJustifyItemsCenter}>
                <InfoBoxLabel label={cellProps?.value} bgColor={AppColors.extraLightGrey} sx={{ textDecoration: 'underline' }} />
            </FlexBox>,
    },
]);