import { useEffect, memo, useReducer, useMemo } from "react";

import { SchemaForm } from "features/components/SchemaForm";
import { getSchemaFormConfig } from "features/components/SchemaForm/utils/formUtils";
import useLocationParams from "ui/hooks/useLocationParams";
import { useSettings } from 'features/hooks/useSettings';

import { StudySettingsReducerTypes, getStudySettingsReducerInitialState, studySettingsReducer } from "features/diagnosis/reducers/studySettingsReducer";
import i18n from "services/server/functions/i18n";

import MODELS from 'modules/model';

const renderCreateStudySchema = ({
    formRef, formId, schema, dataCausedByUser, onChange, onModifyStateToPersist
}) => {
    const formProps = { onChange, onModifyStateToPersist };
    const schemaData = { ...schema?.data, ...dataCausedByUser };
    const schemaFormConfig = useMemo(() => getSchemaFormConfig({ schema: schema?.form, id: formId }), [schema?.form, formId]);
    return (<>{schema?.form &&
        <div className="pageSection" id="templateSettings">
            <div className="sectionHeader">
                <div className="title">
                    {i18n.resolve("StudySettings.form_title")}
                </div>
            </div>
            <SchemaForm
                {...schemaFormConfig}
                formRef={formRef}
                cleanable={false}
                id={formId}
                schema={schema.form}
                data={schemaData}
                formOpts={{ showClearButton: false }}
                actions={schema?.actions}
                {...formProps}
                {...(schema?.formProps || {})} />
        </div>
    }</>);
};

const getStateToPersist = ({
    schema,
    dataCausedByUser,
    studySettingsOwner,
}) => {
    return ({
        schema,
        dataCausedByUser,
        studySettingsOwner
    });
};
export const StudySettings = memo((props) => {
    const { formRef, ownerId, skipReadOnly, onModifyStateToPersist } = props;
    const [persistedState, setPersistedState] = useLocationParams();
    const initialState = getStudySettingsReducerInitialState(getStateToPersist(persistedState));
    const [studySettingsState, dispatch] = useReducer(studySettingsReducer, initialState);
    const {
        schema, formId, dataCausedByUser, needsPersist, needsRetrieveSchemas
    } = studySettingsState;

    const { schemas, retrieveSchemas } = useSettings({
        ownerId,
        schemasToRetrieve: [
            {
                formId,
                action: MODELS.diagnosis.Study.actions.GET_CREATE_STUDY_FORM.exec,
                dataOverrides: { skipReadOnly },
            },
        ],
    });

    useEffect(() => {
        if (schemas?.[formId]) {
            dispatch({
                type: StudySettingsReducerTypes.SchemaUpdated,
                payload: schemas[formId]
            });
        }
    }, [schemas]);

    useEffect(() => {
        if (needsPersist) {
            setPersistedState({ ...persistedState, ...getStateToPersist(studySettingsState) });
        }
        if (needsRetrieveSchemas) {
            retrieveSchemas();
        }
    }, [studySettingsState]);

    useEffect(() => {
        const isDiffOwner =
            ownerId &&
            persistedState?.studySettingsOwner &&
            ownerId !== persistedState?.studySettingsOwner;
        const needsInitialOwner = ownerId && !persistedState?.studySettingsOwner;
        if (isDiffOwner) {
            dispatch({
                type: StudySettingsReducerTypes.currentOwnerHasBeenUpdated,
                payload: ownerId
            });
        } else if (needsInitialOwner) {
            dispatch({
                type: StudySettingsReducerTypes.OwnerIdHasBeenDefined,
                payload: ownerId
            });
        }
    }, [ownerId]);

    const onChange = (form) => dispatch({
        type: StudySettingsReducerTypes.PerformUserDataInteraction,
        payload: form?.formData
    });

    return (
        <div className="create-study-schema">
            {renderCreateStudySchema({
                formRef, ownerId, formId, schema, dataCausedByUser, onChange, onModifyStateToPersist
            })}
        </div>
    );
});
 