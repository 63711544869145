import { Joi, mail as mailSchema } from '../../validation/rules';
import { NOT_EXIST_POLICY, when } from '../policies';

import Config from './config';
import { Roles } from '../../iam/roles';
import { withDefaults } from '..';

const STATE = { delivering: "DELIVERING", delivered: "DELIVERED", error: "ERROR" };

const fillTemplate = (event, template) => {  
  event.data.template = { ...event.data.template, id: template };
  return event;
}

const UserWithEmailExistsPolicy = (request, _, {execute}) => execute(require('../authentication/model').User.queries.MAIL_EXISTS.newRequest({mail: request.to})).then(userSnap => when(!userSnap).rejectWith(NOT_EXIST_POLICY('Check the email, if the problem persists, please contact your system administrator.')));

let Email = {
  context: Config.context,
  name   : 'Email',
  STATE,
  schema: Joi.object().keys({
    from: Joi.alternatives().try(mailSchema, Joi.string().namedMail(), Joi.object().keys({name: Joi.string(), address: mailSchema})),
    to: mailSchema,
    message: Joi.object().keys({
      subject: Joi.string(),
      body: Joi.string()
    }),
    attachments: Joi.array().items(Joi.object().unknown(true)), // TODO: model properly using nodemailer
    template: Joi.object().keys({
      id        : Joi.string(),
      language  : Joi.alternatives().try(Joi.string(), Joi.array().items(Joi.string()).min(1)),
      parameters: Joi.object().unknown(true)                                           // We could define an schema for each template???
    })
  }),
  events: {
  },
  queries: {
    CONFIRM_ONBOARDING_EMAIL: {
      roles : [Roles.superAdmin.id],
      schedule: { // TODO: define command behaviour in a query does not seem right?? find a better way.
        event: { label: "Onboarding email sent" },
        permissions: [Roles.anonymous.id],
        checkPolicies: () => {}, 
      },
      get schema() { return Email.schema.concat(Joi.object().keys({
        template: Joi.object().keys({
          parameters: Joi.object().keys({
            study: Joi.string().required()
          })
        })
      })); },
      newRequest: (args, metadata) => ({
        action: Email.queries.CONFIRM_ONBOARDING_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots, it is a waste of time
      })
    },
    CANCEL_STUDY_EMAIL: {
      roles : [Roles.superAdmin.id],
      get schema() { return Email.schema.concat(Joi.object().keys({
        template: Joi.object().keys({
          parameters: Joi.object().keys({
            study: Joi.string().required(),
          })
        })
      })); },
      newRequest: (args, metadata) => ({
        action: Email.queries.CANCEL_STUDY_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots, it is a waste of time
      })
    },
    INVALID_STUDY_EMAIL: {
      roles : [Roles.superAdmin.id],
      get schema() { return Email.schema.concat(Joi.object().keys({
        template: Joi.object().keys({
          parameters: Joi.object().keys({
            testId: Joi.string().required()
          })
        })
      })); },
      newRequest: (args, metadata) => ({
        action: Email.queries.INVALID_STUDY_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots, it is a waste of time
      })
    },
    STUDY_ANALYSED_EMAIL: {
      roles : [Roles.superAdmin.id],
      get schema() { return Email.schema.concat(Joi.object().keys({
        template: Joi.object().keys({
          parameters: Joi.object().keys({
            testId: Joi.string().required()
          })
        })
      })); },
      newRequest: (args, metadata) => ({
        action: Email.queries.STUDY_ANALYSED_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots, it is a waste of time
      })
    },
    WELCOME_EMAIL: {
      roles : [Roles.superAdmin.id],
      get schema() { return Email.schema.fork('to', schema => schema.required()); },
      newRequest: (args, metadata) => ({
        action: Email.queries.WELCOME_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots -> we coud use GET_USER_BY_MAIL, but as the current changePassword template does not require it, it is a waste of time
      })
    },
    DISABLED_USER_EMAIL: {
      roles : [Roles.superAdmin.id],
      get schema() { return Email.schema.fork('to', schema => schema.required()); },
      newRequest: (args, metadata) => ({
        action: Email.queries.DISABLED_USER_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots -> we coud use GET_USER_BY_MAIL, but as the current changePassword template does not require it, it is a waste of time
      })
    },
    ENABLED_USER_EMAIL: {
      roles : [Roles.superAdmin.id],
      get schema() { return Email.schema.fork('to', schema => schema.required()); },
      newRequest: (args, metadata) => ({
        action: Email.queries.ENABLED_USER_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots -> we coud use GET_USER_BY_MAIL, but as the current changePassword template does not require it, it is a waste of time
      })
    },
    PASSWORD_EMAIL: {
      roles : [Roles.superAdmin.id],
      schedule: {
        permissions: [Roles.anonymous.id]
      },
      get schema() { return Email.schema.concat(Joi.object().keys({
        template: Joi.object().keys({
          parameters: Joi.object().keys({
            firstChange: Joi.boolean(),
          })
        })
      })).fork('to', schema => schema.required()); },
      newRequest: (args, metadata) => ({
        action: Email.queries.PASSWORD_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots -> we coud use GET_USER_BY_MAIL, but as the current changePassword template does not require it, it is a waste of time
      })
    },
    PASSWORD_CHANGED_EMAIL: {
      roles : [Roles.superAdmin.id],
      schedule: {
        permissions: [Roles.anonymous.id]
      },
      get schema() { return Email.schema.concat(Joi.object().keys({
        template: Joi.object().keys({
          parameters: Joi.object().keys({
            localISOTime: Joi.string().required(),
          }).required()
        }).required()
      })).fork('to', schema => schema.required()); },
      newRequest: (args, metadata) => ({
        action: Email.queries.PASSWORD_CHANGED_EMAIL.new(args, metadata),
        depends: [], // Need to use depends to avoid loading all Email events/snapshots -> we coud use GET_USER_BY_MAIL, but as the current changePassword template does not require it, it is a waste of time
      })
    }
  }
};

Email.commands = Object.entries(Email.queries).reduce((commands, [type, mail]) => type.endsWith('_EMAIL') ? {...commands, [`SCHEDULE_${type}`]: {
  roles: mail.schedule?.permissions || mail.roles || [Roles.superAdmin.id],
  get schema () { return mail.schema.fork('id', schema => schema.default(Email.newURN())); },
  checkPolicies: mail.schedule?.checkPolicies || UserWithEmailExistsPolicy,
  event: (action) => fillTemplate(Email.events[`${type}_SCHEDULED`].new(action), type),
  template: {id: type}
}} : commands, {...Email.commands});
Email.events   = Object.keys(Email.queries).reduce((events, type) => type.endsWith('_EMAIL') ? {...events, [`${type}_SCHEDULED`]: {...Email.queries[type].schedule?.event}} : events, {...Email.events});
Email = withDefaults()(Email);

export { Email }