import CreateStudy from '../ui/CreateStudy';
import CreateStudyBatch from './CreateStudyBatch';
import EditStudy from './EditStudy';
import FindStudy from './FindStudy';
import FindStudyEvents from './FindStudyEvents';
import ManageFulfilment from './FindStudy/FulfilmentView';
import StudyLanding from './Landing';
import UsageReport from './UsageReport';
import ViewEvents from './test/ViewEvents';
import ViewStudy from './ViewStudy';
import ViewTest from './test/ViewTest';
import {config} from 'services/server/functions/firebase/config';

const UploadTestFile = !config.projectId.startsWith("production") && !config.projectId.startsWith("staging") ? require('./test/UploadTestFile').default : undefined;

export {
  StudyLanding,
  FindStudy,
  FindStudyEvents,
  ManageFulfilment,
  CreateStudy,
  CreateStudyBatch,
  EditStudy,
  ViewStudy,
  ViewTest,
  ViewEvents,
  UsageReport,
  UploadTestFile
};
