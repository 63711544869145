import { useState, useEffect } from "react";
import { Button } from '@mui/material';

import { getImgUrl, useAssetsURL } from "ui/hooks/useAssetsUrl";

import i18n from "services/server/functions/i18n";
import ExportReport from 'ui/components/ExportReport';
import { Text } from "ui/components/Text";

import { Table } from "features/fulfilment/components/molecules/Table";
import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import { FlexBox, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";
import { SectionInfo } from "features/fulfilment/components/atoms/SectionInfo";
import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";

import { getInputShippingDetailsColumns } from "./tableConfig";
import { getProcessOrderActions } from "../orderHelper";
import { findKitTemplateForStudy } from "services/server/functions/model/fulfilment/connector/study.connector";

export const ShippingDetailsStep = ({
    orders = [], orderShippingUpdates = {}, setOrderShippingUpdates, studiesSnaps = {}, kitTemplates = [], goToPreviousStep, goToStickCodesStep, setCourierLabels, setCourierLabelsBatch, notify
}) => {
    const [allShippingDetailsIncluded, setAllShippingDetailsIncluded] = useState(false);
    const [inputShippingDetailsRows, setInputShippingDetailsRows] = useState([]);

    const saveCourierLabelsForRow = (data) => {
        return setCourierLabels(data)
            .catch(e => {
                notify("error", Text.resolve(e.reason), Text.resolve(e.resolution));
                return Promise.reject(e);
            }).then(_ => {
                const inputShippingDetailsRow = inputShippingDetailsRows.find(r => r.id === data.id);
                const originalOrder = orders.find(r => r.id === data.id);
                // Workaround for not having subscriptions (NOTE: both are always being set)
                originalOrder.shipping = { inbound: data.shipping.inbound, outbound: data.shipping.outbound };
                inputShippingDetailsRow.edited = false;
                inputShippingDetailsRow['shipping.inbound'] = data.shipping.inbound;
                inputShippingDetailsRow['shipping.outbound'] = data.shipping.outbound;
                setInputShippingDetailsRows(inputShippingDetailsRows.map(r => r));
                return true;
            });
    };

    const findKitTemplateForStudyId = (studyId) => {
        const studySnap = studiesSnaps[studyId];
        if (!studySnap) return undefined;
        const kitTemplate = findKitTemplateForStudy(studySnap, kitTemplates);
        return kitTemplate;
    };

    useEffect(() => {
        const includesAllShippingDetails = orders.every(o => o.shipping?.inbound?.length && o.shipping?.outbound?.length);
        if (includesAllShippingDetails !== allShippingDetailsIncluded) {
            setAllShippingDetailsIncluded(includesAllShippingDetails);
        }
    }, [orders, inputShippingDetailsRows]); // Temporary hack while orders are not updated automatically

    useEffect(() => {
        setInputShippingDetailsRows(orders.map(order => {
            const inputShippingDetailsRow = inputShippingDetailsRows.find(r => r.id === order.id);
            const kitTemplate = order.kit?.template || findKitTemplateForStudyId(order.owner);
            const shippingUpdates = orderShippingUpdates[order.id];

            let edited = inputShippingDetailsRow?.edited;
            if (shippingUpdates && (shippingUpdates.inbound !== order.shipping.inbound || shippingUpdates.outbound !== order.shipping.outbound)) {
                edited = true;
            }

            const shipping = shippingUpdates || inputShippingDetailsRow?.shipping || order.shipping || {};
            return {
                id: order.id,
                template: {
                    name: kitTemplate?.name,
                    hexColorCode: kitTemplate?.hexColorCode,
                },
                kit: order.kit,
                reference: order.reference,
                shipping: JSON.parse(JSON.stringify(shipping)),
                'shipping.inbound': shipping.inbound,
                'shipping.outbound': shipping.outbound,
                edited,
            };
        }));
        // Careful => right now is guaranteed orders will arrive at the same time that orderShippingUpdates, that's why it works
        if (Object.keys(orderShippingUpdates).length) setOrderShippingUpdates({});
    }, [orders, studiesSnaps, kitTemplates, orderShippingUpdates]);

    const updateShippingField = (field, row, value) => {
        const inputShippingDetailsRow = inputShippingDetailsRows.find(r => r.id === row.id);
        if (!inputShippingDetailsRow.shipping) inputShippingDetailsRow.shipping = {};
        inputShippingDetailsRow.shipping[field] = value;
        return inputShippingDetailsRow;
    };

    const onCellEditStop = (params, event) => {
        let inputShippingDetailsRow;
        if (params.reason !== "cellFocusOut" && (params.field === "shipping.inbound" || params.field === "shipping.outbound")) {
            const field = params.field.split(".").pop();
            const value = event.target.value === "" ? undefined : event.target.value;
            inputShippingDetailsRow = updateShippingField(field, params.row, value);

            // Update edited row property
            const originalOrder = orders.find(r => r.id === params.row.id);
            if (originalOrder.shipping?.inbound !== params.row.shipping?.inbound || originalOrder.shipping?.outbound !== params.row.shipping?.outbound) {
                inputShippingDetailsRow.edited = true;
            } else {
                inputShippingDetailsRow.edited = false;
            }
            setInputShippingDetailsRows(inputShippingDetailsRows.map(r => r));
        }
    }

    const exportLabels = async (orderLabelsInfo) => {
        const getLetters = _ => require('services/server/functions/executor/executor.client').getReport(orderLabelsInfo);
        return `data:data:application/pdf;base64,${await getLetters()}`; // TODO: export PDF assumes data URI. remove everywhere the use of data URIs and use URL.createObjectURL
    };

    const exportLabelsPDF = async () => {
        return await exportLabels(orders.map(order => ({
            reportId: 'fulfilment.Order.labels',
            id: order.id,
        })));
    };

    const disableExportLabels = !allShippingDetailsIncluded;
    const disbleNextButton = !allShippingDetailsIncluded;
    const { assetsURl } = useAssetsURL();
    return (
        <>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => goToPreviousStep()}
            />
            <div className="fulfilment page order-landing">
                <h1 className="title"><Text>fulfilment.order.process.title</Text></h1>
                <section className="section">
                    <FlexBox sx={{ width: '80%' }}>
                        <SectionInfo label={i18n.resolve('fulfilment.order.process.description')} />
                    </FlexBox>
                </section>
                <div style={{ marginTop: "60px" }}>
                    <Table
                        disableColumnSelector={true}
                        disableColumnFilter={true}
                        showExport={false}
                        checkboxSelection={false}
                        columns={getInputShippingDetailsColumns({ saveCourierLabelsForRow })}
                        Actions={getProcessOrderActions({ originalOrders: orders, setCourierLabelsBatch, setInputShippingDetailsRows })}
                        getRowId={(r) => r?.id}
                        rows={inputShippingDetailsRows}
                        onCellEditStop={onCellEditStop}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", marginTop: "40px" }}>
                    <div style={{ padding: "15px", border: "2px solid gray", borderRadius: "10px", boxShadow: "2px 2px 5px 0px lightgray", }}>
                        <img
                            className="order-label-image"
                            alt="order label"
                            src={getImgUrl(
                                assetsURl,
                                'process_order_label.png'
                            )}
                        />
                    </div>
                    <ExportReport
                        id="exportLabelsPDF"
                        disabled={disableExportLabels}
                        label={i18n.resolveValue("global.print")?.toUpperCase()}
                        secondary
                        downloadFile={`${orders.length}_order_labels.pdf`}
                        urlCallback={exportLabelsPDF}
                        style={{ width: "116px", padding: "10px", textAlign: "center" }}
                    />
                </div>
                <FlexBox sx={flexRowJustifyItemsEnd}>
                    <Button
                        disabled={disbleNextButton}
                        variant={Variant.Contained} color={Color.Secondary}
                        onClick={goToStickCodesStep}
                        sx={{ marginLeft: '8px' }}
                    >
                        <Text>next</Text>
                    </Button>
                </FlexBox>
            </div>
        </>
    );
};