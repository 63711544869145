import '@atlaskit/css-reset';

import { Route, Router, Switch } from 'react-router-dom';
import { createContext, useContext, useEffect } from 'react';

import {getLocationParams} from 'ui/hooks/useLocationParams';
import history from './history.js';
import useCustomPageContainer from 'ui/hooks/useCustomPageContainer.js';
import useEffectOnMount from 'ui/hooks/useEffectOnMount.js';

const register = (m) => Object.entries(m).filter(([_, val]) => Boolean(val)).reduce((components, [name, c]) => components.concat({ 
  name,
  path: c.ROUTE_PATH, 
  public: Boolean(c.ROUTE_PUBLIC), 
  hideOnAuth: Boolean(c.ROUTE_HIDEONAUTH), 
  hideNav: Boolean(c.ROUTE_HIDENAV),
  priority: c.ROUTE_PRIORITY,
  component: c 
}), []);
const routes = [
  require('./modules/administration'), // TODO: These modules should only be loaded if required (i.e. allow modules dynamic import)
  require('./modules/authentication'),
  require('./modules/devices'),
  require('./modules/diagnosis'),
  require('./modules/support'),
  require('./modules/main'),
  require('./modules/onboarding'),
  require('./features/fulfilment'),
].map(register).reduce((all, components) => all.concat(components), [])
 .concat([
  {path: '/403', public: true, component: require('ui/components/ErrorBoundary/Error403').default},
  {path: '/error', public: true, component: require('ui/components/ErrorBoundary/Error500').default},
  {component: require('ui/components/ErrorBoundary/Error404').default},
]).map((route, idx) => {
  route.path     = route.path !== undefined ? route.path : "*";
  route.priority = route.priority !== undefined ? route.priority : (9000 + idx);

  return route;
});

const pageListeners = [];
const registerPageListener = (onPageChange) => {
  onPageChange.prevLocation = {...window.location, state: {...window.location.state}};
  pageListeners.push(onPageChange);
  return () => pageListeners.splice(pageListeners.findIndex(l => l === onPageChange),1)
};
export const PageSelectorContext = createContext();

const PageWrapper = ({ authenticated, route, children }) => {
  const pageNav = useContext(require('ui/acukit/Page').PageNavContext);
  const embedded = Boolean(getLocationParams().embedded); // TODO: HACK ? maybe there is a better way ?
  const minimal = Boolean(getLocationParams().minimal); // TODO: HACK ? maybe there is a better way ?
  
  useEffectOnMount(_ => {
    if (embedded || route.hideNav) pageNav.hide();
    else {
      pageNav.show();
      if (minimal) pageNav.close();
      else         pageNav.open();
    }
    authenticated && require('services/redux/actions/Authentication').keepSessionAlive();
    pageListeners.forEach(onPageChange => onPageChange(onPageChange.prevLocation));
  });
  useEffect(() => { window.scrollTo(0, 0); document.querySelector('.pageContainer').scrollTop = 0; document.querySelector('.pageContainer').scrollLeft = 0; }, []); // Scroll to top
  useCustomPageContainer();
  return <PageSelectorContext.Provider value={{registerPageListener, page: route.name}}>{children}</PageSelectorContext.Provider>;
};

const routeTo = ({authenticated, ...props}) => (route, index) => <Route exact history={history} key={index} path={route.path} render={ _ => <PageWrapper authenticated={authenticated} route={route}><route.component {...props} /></PageWrapper> } />;
export default (props) => <Router history={history}><Switch>{ routes.sort((r1, r2) => r1.priority - r2.priority).filter(r => (r.public && !props.authenticated) || (props.authenticated && !r.hideOnAuth)).map(routeTo(props)) }</Switch></Router>;
