import { useContext, useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import history from 'history.js';
import { Button, Paper } from '@mui/material';

import useCurrentUser from 'ui/hooks/useCurrentUser';

import { fulfilmentTheme } from "../../components/themes";
import { Text } from "ui/components/Text";

import '../../components/themes/baseStyles.css';
import { ButtonGroup } from 'features/fulfilment/components/molecules/ButtonGroup';

import { BoxInfo } from 'features/fulfilment/components/atoms/BoxInfo';
import { Color } from 'features/fulfilment/components/constants/color';
import { Variant } from 'features/fulfilment/components/constants/variant';
import i18n from 'services/server/functions/i18n';
import { PageNavContext } from 'ui/acukit/Page';
import { getDaysFilters, getStatusFilters } from 'features/fulfilment/helpers/filters';
import { getLandingNavigationItems } from './landingHelper';
import * as Pages from '../../index';

import './styles.css';


export const OrderLanding = () => {
    const pageNav = useContext(PageNavContext);
    const [currentUser] = useCurrentUser();
    const daysFiltersItems = getDaysFilters();
    const statusFiltersItems = getStatusFilters();
    const [lastUpdateSelected, setLastUpdateSelected] = useState(daysFiltersItems[daysFiltersItems.length - 1]);
    const [statusSelected, setStatusSelected] = useState(statusFiltersItems[statusFiltersItems.length - 1]);

    useEffect(() => pageNav.enterFullScreen(), []);

    const handleNavigation = ({ path, state }) => {
        history.push(path, state);
    };

    const handleViewOrders = () => {
        const filters = statusSelected.label === statusFiltersItems.at(-1).label ? [] : [{
            id: 1,
            field: 'status',
            operator: 'equals',
            value: statusSelected.label,
        }];

        handleNavigation({
            path: Pages.Order.PATH,
            state: {
                filters,
                customFilters: {
                    lastUpdated: lastUpdateSelected?.value,
                },
            },
        })
    };

    return (
        <ThemeProvider theme={fulfilmentTheme}>
            <div className="fulfilment page order-landing ">
                <h1 className="title"><Text>fulfilment.order.title</Text></h1>
                <section className="section">
                    {getLandingNavigationItems(currentUser).map(({
                        label, navigations
                    }) => (
                        <div key={label} className="mb">
                            <BoxInfo
                                label={label}
                            >
                                <div style={{ display: "flex", "gap": "20px" }}>
                                    {navigations.map((nav, index) => {
                                        return <Button
                                            key={index}
                                            sx={{ width: '104px' }}
                                            variant={Variant.Contained}
                                            color={Color.Secondary}
                                            onClick={
                                                () => handleNavigation({ ...nav, path: nav.page.PATH })
                                            }
                                        >
                                            {nav?.label}
                                        </Button>;
                                    })}
                                </div>
                            </BoxInfo>
                        </div>
                    ))}
                    {
                    currentUser.hasAccess(Pages.Order) && <Paper
                        elevation={3}
                        color={Color.Alternative}
                        sx={{ height: '324px' }}
                    >
                        <div className="landing-page-all-orders">
                            <span className="landing-page-all-orders-title">
                                <Text>fulfilment.order.view_all_orders</Text>
                            </span>
                            <div className="landing-grid-container">
                                <ButtonGroup
                                    label={i18n.resolve('update')}
                                    items={daysFiltersItems}
                                    selected={lastUpdateSelected}
                                    handleChange={setLastUpdateSelected}
                                />
                                <ButtonGroup
                                    label={i18n.resolve('status')}
                                    items={statusFiltersItems}
                                    selected={statusSelected}
                                    handleChange={setStatusSelected}
                                />
                            </div>
                            <div className="landing-page-footer">
                                <Button
                                    variant={Variant.Contained}
                                    color={Color.Secondary}
                                    onClick={handleViewOrders}
                                >
                                    <Text>search</Text>
                                </Button>
                            </div>
                        </div>
                    </Paper>
                    }
                </section>
            </div>
        </ThemeProvider>
    );
};
