// const ACURABLE_SHIPPING_DETAILS = {
//     addressLine1: "Acurable Ltd, Finsgate, 5-7 Cranwood Street",
//     // addressLine2: "",
//     postCode: "EC1V 9EE",
//     city: "London",
//     country: "United Kingdom",
//     phone: "(+44) 0208 1917590",
//     name: "Orsina Dessi",
// };

// const CSV_FIELD_TO_RECIPIENT_DATA = {
//     "Patient Name": "name",
//     "Shipping Address - Address Line 1": "addressLine1",
//     "Shipping Address - Address Line 2": "addressLine2",
//     "Post code": "postCode",
//     "City": "city",
//     "Country": "country",
//     "Patient contact email": "email",
//     "Patient contact number": "phone",
// };

// const mapCSVToModelShippingFields = (csvStudyDetails) => {
//     const fields = Object.keys(CSV_FIELD_TO_RECIPIENT_DATA);
//     const mappedData = fields.reduce((acc, curr) => {
//         const key = CSV_FIELD_TO_RECIPIENT_DATA[curr];
//         const value = csvStudyDetails[curr];
//         if (value) acc[key] = value;
//         return acc;
//     }, {});
//     return mappedData;
// };

export const getCreateOrderPayload = (createdStudySnap/*, csvStudyDetails*/) => {
    const payload = {
        owner: createdStudySnap.aggregate.id,
        // sender: ACURABLE_SHIPPING_DETAILS, // TODO: not ideal as it could be manipulated
        // recipient: mapCSVToModelShippingFields(csvStudyDetails),
    };
    return payload;
};