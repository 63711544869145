import { useContext, useEffect, useState } from 'react';

import { Button, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import history from 'history.js';
import * as Pages from '../../index';

import useSnapshot from 'ui/hooks/useSnapshot';
import { Text } from "ui/components/Text";
import { FlexBox, flexRowBeEvenly, flexRowCenter, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";

import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { flexCol } from '../../components/atoms/Layout/index';
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import i18n from "services/server/functions/i18n";

import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";
import { SectionInfo } from 'features/fulfilment/components/atoms/SectionInfo';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

import { PageNavContext } from 'ui/acukit/Page';

import './styles.css';

import { fulfilmentTheme } from 'features/fulfilment/components/themes';
import { CheckList } from 'features/fulfilment/components/molecules/CheckList';
import { Order, Kit, Item } from 'services/server/functions/model/fulfilment/model';

const ITEMS_VALUES = [{
    label: i18n.resolve("fulfilment.kit.validation.status.lost"),
    value: Kit.VALIDATION_STATUS.LOST,
}, {
    label: i18n.resolve("fulfilment.kit.validation.status.disposed"),
    value: Kit.VALIDATION_STATUS.DISPOSED,
}];

export const ReturnedKit = ({ markKitAsValidated, markOrderAsValidated, notify }) => {
    const pageNav = useContext(PageNavContext);
    const { assetsURl } = useAssetsURL();

    const [reference, setReference] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedKit, setSelectedKit] = useState();
    const [selectedOrder, setSelectedOrder] = useState();
    const [validations, setValidations] = useState({});
    const [kitValidationItems, setKitValidationItems] = useState([]);

    const [orderSnaps, loadingOrders] = useSnapshot(Order);
    const [kitSnaps, loadingKits] = useSnapshot(Kit);
    const [itemsSnaps] = useSnapshot(Item);

    const runKitStatusValidations = (kitSnap) => {
        if (kitSnap.data.status === Kit.STATUS.VALIDATED) {
            notify('error', Text.resolve('The Kit is already validated'), Text.resolve('Is not possible to re-validate a Kit. Contact us for further assistance.'));
            return false;
        }
        if (kitSnap.data.status !== Kit.STATUS.ASSIGNED) {
            notify('error', Text.resolve('The Kit is not in the proper status'), Text.resolve('Is only possible to validate a Kit from the ASSIGNED status'));
            return false;
        }
        return true;
    };

    const setSelectedElements = () => {
        let orderSnap = Object.values(orderSnaps).find(s => s.data.reference === reference);
        if (orderSnap) {
            if (!orderSnap.data.kit) {
                return notify('error', Text.resolve('The Order does not have a Kit assigned'), Text.resolve('Orders can only be validated once they have been returned'));
            }
            const kitSnap = kitSnaps[orderSnap.data.kit];
            if (!kitSnap) {
                return notify('error', Text.resolve('The Kit could not be found'), Text.resolve('Try reloading the page'));
            }
            const complies = runKitStatusValidations(kitSnap);
            if (!complies) return;
            setSelectedOrder(orderSnap);
            return setSelectedKit(kitSnap);
        }
        const kitSnap = Object.values(kitSnaps).find(s => s.data.reference === reference);
        if (!kitSnap) {
            return notify('error', Text.resolve('Order or Kit cannot be found'), Text.resolve('Enter a reference of an existing Order or Kit'));
        }
        const complies = runKitStatusValidations();
        if (!complies) return;
        orderSnap = Object.values(orderSnaps).find(s => s.data.kit === kitSnap.aggregate.id);
        if (!orderSnap) {
            return notify('error', Text.resolve('The Kit is not assigned to an Order'), Text.resolve('A Kit can only be validated if its assigned to an Order'));
        }
        setSelectedKit(kitSnap);
        return setSelectedOrder(orderSnap);
    };

    const loadReferenceContent = () => {
        console.log(reference);
        setSelectedElements();
    };

    const validateKit = async () => {
        const formattedValidations = selectedKit.data.items.map((item, index) => {
            const validation = {
                item: selectedKit.data.template.items[index],
                status: validations[index] || Kit.VALIDATION_STATUS.RETURNED,
            };
            if (typeof item === "string") {
                validation.device = item;
            }
            return validation;
        })
        await markKitAsValidated({
            id: selectedKit.aggregate.id,
            validations: formattedValidations,
        });
        await markOrderAsValidated({
            id: selectedOrder.aggregate.id
        });
        history.push(Pages.OrderLanding.PATH);
    };

    const configureKitValidationItems = () => {
        if (!selectedKit) {
            return setKitValidationItems([]);
        }

        const items = selectedKit.data.items.map((item, index) => {
            const itemURN = selectedKit.data.template.items[index];
            const itemSnap = itemsSnaps[itemURN];
            return {
                id: index,
                label: itemSnap?.data.name || itemURN.split("/").pop(),
                values: ITEMS_VALUES,
            };
        });

        setKitValidationItems(items);
    };

    useEffect(() => pageNav.enterFullScreen(), []);

    useEffect(() => {
        setIsLoading(loadingOrders || loadingKits);
    }, [loadingOrders, loadingKits]);

    useEffect(() => {
        configureKitValidationItems();
    }, [selectedKit, itemsSnaps]);

    return (
        <ThemeProvider theme={fulfilmentTheme}>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => history.goBack()}
            />
            <div className="fulfilment page">
                <h1 className="title">
                    <FlexBox>
                        <Text>{i18n.resolve('fulfilment.kit.returned.subtitle')}</Text>
                    </FlexBox>
                </h1>
                <div className="create-kit-summary-content">
                    <div className="create-kit-sumary-section">
                        <SectionInfo label={i18n.resolve('fulfilment.kit.returned.legend')} />
                        <FlexBox sx={{
                            ...flexRowCenter,
                            width: '100%',
                            marginLeft: '30px',
                            marginTop: '20px'
                        }}>
                            <TextField id="scan-code"
                                value={reference}
                                onChange={(e) => setReference(e.target.value.trim())}
                                label={i18n.resolve('global.bar_code')}
                                variant={Variant.Outlined}
                                sx={{ width: '300px' }}
                            />
                            <Button
                                variant={Variant.Contained}
                                color={Color.Secondary}
                                onClick={() => loadReferenceContent()}
                                disabled={!reference.length || isLoading}
                                sx={{ marginLeft: '20px' }}
                            >
                                {isLoading ? '...' : <Text>next</Text>}
                            </Button>
                        </FlexBox>
                        <FlexBox sx={{ ...flexRowBeEvenly, marginTop: '40px' }}>
                            <div className="create-kit-box-border create-kit-list-items">
                                <CheckList
                                    items={kitValidationItems}
                                    handleSelectionChange={(selections) => { setValidations(selections) }}
                                />
                            </div>
                            <FlexBox sx={{ ...flexCol }}>
                                <SectionInfo label={i18n.resolve('global.template')} />
                                <img
                                    className="create-kit-box-img"
                                    alt="acuppeble box"
                                    src={getImgUrl(
                                        assetsURl,
                                        'fulfilment_placeholder.png'
                                    )}
                                />
                            </FlexBox>
                        </FlexBox>
                    </div>
                    <FlexBox sx={flexRowJustifyItemsEnd}>
                        <Button
                            variant={Variant.Contained}
                            color={Color.Secondary}
                            onClick={() => validateKit()}
                            disabled={!selectedKit || !selectedOrder}
                        >
                            <Text>global.done</Text>
                        </Button>
                    </FlexBox>
                </div>
            </div>
        </ThemeProvider>
    )
};
