import { useContext, useEffect, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import Loading from "ui/components/Loading";

import { Text } from "ui/components/Text";
import { Table } from "../components/molecules/Table";
import history from 'history.js';

import { PageNavContext } from "ui/acukit/Page";
import * as Pages from '../index';

import { KitTemplate, Kit, Order } from 'services/server/functions/model/fulfilment/model';
import { Study } from 'services/server/functions/model/diagnosis/model';

import i18n from "services/server/functions/i18n";
// import { getOrderSelectedActions } from "./orderHelper";
import { fulfilmentTheme } from "../components/themes";
import useLocationParams from "ui/hooks/useLocationParams";

import './styles.css';
import '../components/themes/baseStyles.css';
import { getOrderColumns, prepareOrderTableState } from "./tableConfig";
import { FlexBox, flexRowJustifyItemsEnd } from "../components/atoms/Layout";
import { Button } from "@mui/material";
import { Variant } from "../components/constants/variant";
import { Color } from "../components/constants/color";
import { BackIcon } from "../components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "../components/atoms/LinkInfo";
import useSnapshot from "ui/hooks/useSnapshot";
import useProjection from "ui/hooks/useProjection";
import { findKitTemplateForStudy } from "services/server/functions/model/fulfilment/connector/study.connector";
import { KitsOverview } from "../Kit/Overview";


export const OrderPage = () => {
    const [locState, setLocState] = useLocationParams();

    const [orders, loading] = useProjection(Order.queries.FIND_ORDER.newRequest({}));
    const [kitSnaps] = useSnapshot(Kit);
    const [kitTemplateSnaps] = useSnapshot(KitTemplate);
    const [studiesSnaps] = useSnapshot(Study);

    const kits = Object.values(kitSnaps).map(s => s.data);
    const kitTemplates = Object.values(kitTemplateSnaps).map(s => s.data);

    const [selectedOrders, setSelectedOrders] = useState(locState?.selectedOrders || []);
    const [orderRows, setOrderRows] = useState([]);

    const findKitTemplateForStudyId = (studyId) => {
        const studySnap = studiesSnaps[studyId];
        if (!studySnap) return undefined;
        const kitTemplate = findKitTemplateForStudy(studySnap, kitTemplates);
        return kitTemplate;
    };

    useEffect(() => {
        setOrderRows((orders || []).map(order => {
            const kitTemplate = order.kit?.template || findKitTemplateForStudyId(order.owner);
            return {
                ...order,
                _status: order.status,
                status: i18n.resolve(`fulfilment.order.status.${order.status}`),
                template: {
                    name: kitTemplate?.name,
                    hexColorCode: kitTemplate?.hexColorCode,
                },
            };
        }));
    }, [orders, kitTemplateSnaps, studiesSnaps]);

    const disableOrderProcess = selectedOrders.length === 0;
    const pageNav = useContext(PageNavContext);
    const initialState = prepareOrderTableState({ locState });
    const needsOrdersToProcess = locState?.needsOrdersToProcess;

    useEffect(() => {
        pageNav.enterFullScreen();
        needsOrdersToProcess && setLocState({ ...locState, fromCSV: false });
    }, []);

    useEffect(() => setLocState({ ...locState, selectedOrders }), [selectedOrders]);

    const goToProcessOrder = (fromCSV) => {
        history.push(Pages.ProcessOrder.PATH, { ordersToProcess: orders.filter(o => selectedOrders.includes(o.id)), fromCSV: Boolean(fromCSV) })
    };

    const { lastUpdated } = locState?.customFilters || {};
    const byLastUpdated = (order) => lastUpdated?.length ? moment(order.date).isBetween(moment(lastUpdated[0]), moment(lastUpdated[1])) : true;
    const allFilters = { byLastUpdated };

    const getFilters = (skip = []) => Object.keys(allFilters).filter(k => !skip.includes(k)).map(k => allFilters[k]);
    const byCurrentState = (skip) => (order) => getFilters(skip).reduce((result, filter) => result && filter(order), true);

    const filteredRows = orderRows.filter(byCurrentState());

    return <>
        {loading && <Loading loading={loading} />}
        <ThemeProvider theme={fulfilmentTheme}>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => history.goBack()}
            />
            <div className="fulfilment page">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1 className="title"><Text>fulfilment.order.title</Text></h1>
                    {needsOrdersToProcess ? <div>
                        <Button
                            variant={Variant.Outlined} color={Color.Secondary}
                            onClick={() => goToProcessOrder(true)}
                        >
                            <Text>Import CSV</Text>
                        </Button>
                    </div> : ''}
                </div>
                {needsOrdersToProcess ? <KitsOverview kits={kits} kitTemplates={kitTemplates} style={{ marginTop: '30px' }} /> : ''}
                <section className="section">
                    <Table
                        loading={loading}
                        columns={getOrderColumns()}
                        rows={filteredRows}
                        getRowId={(m) => m.id}
                        initialState={initialState}
                        rowSelectionModel={selectedOrders || []}
                        // SelectedActions={getOrderSelectedActions}
                        disableColumnSelector={true}
                        onRowSelectionModelChange={setSelectedOrders}
                        isRowSelectable={(params) => !needsOrdersToProcess || (params.row._status === Order.STATUS_GROUP.PENDING /*&& Object.keys(params.row.recipient || {}).length > 6*/ && !params.row.kit)}
                    />
                </section>
                {needsOrdersToProcess && (
                    <FlexBox sx={flexRowJustifyItemsEnd}>
                        <Button
                            disabled={disableOrderProcess}
                            variant={Variant.Contained}
                            color={Color.Secondary}
                            onClick={() => goToProcessOrder()}
                        >
                            <Text>next</Text>
                        </Button>
                    </FlexBox>
                )
                }
            </div>
        </ThemeProvider>
    </>;
};
