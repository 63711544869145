import { Paper } from "@mui/material";

import './styles.css';

export const BoxInfo = ({ children, label, sx }) => {
    const sxDefinition = { height: '86px', ...sx };
    return (
        <Paper
            elevation={3}
            color="primary"
            sx={sxDefinition}
        >
            <div className="box-component">
                <div className="box-component-header">
                    <span className="box-component-title">{label}</span>
                </div>
                {children}
            </div>
        </Paper>
    );
};
