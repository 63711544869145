import history from "history.js";
import { Button } from '@mui/material';

import { Table } from "features/fulfilment/components/molecules/Table";
import i18n from "services/server/functions/i18n";

import useCurrentUser from "ui/hooks/useCurrentUser";

import { getCreateKitColumns } from "./tableConfig";
import { BackIcon } from 'features/fulfilment/components/atoms/icons/fulfilmentIcons';
import { Text } from "ui/components/Text";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import { FlexBox, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";
import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";
import { SectionInfo } from "features/fulfilment/components/atoms/SectionInfo";
import { withKitTemplates } from "modules/diagnosis/ui/components";
import { useState, useEffect } from "react";
import { SetOwnersDialog } from "./SetOwnersDialog";

export const CreateKitSelectStep = withKitTemplates()(({
    selectedBoxes, goToSummaryStep, setSelectedBoxes, kitTemplatesSnaps, selectedOwners, setSelectedOwners,
}) => {
    const [userSnap] = useCurrentUser();

    const [createKitRows, setCreateKitRows] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCreateKitRows(kitTemplatesSnaps.map(kitTemplateSnap => ({
            id: kitTemplateSnap.aggregate.id,
            name: kitTemplateSnap.data.name,
            description: kitTemplateSnap.data.description,
            hexColorCode: kitTemplateSnap.data.hexColorCode,
            boxesQuantity: createKitRows?.find(r => r.id === kitTemplateSnap.aggregate.id)?.boxesQuantity || 0,
            items: kitTemplateSnap.data.items,
        })));
    }, [kitTemplatesSnaps]);

    const updateSelectedBoxes = (row, newBoxesQuantity) => {
        if (row.boxesQuantity === newBoxesQuantity) return;
        const createKitRow = createKitRows.find(b => b.id === row.id);
        createKitRow.boxesQuantity = newBoxesQuantity;
        setCreateKitRows(createKitRows.map(e => e));
        const selectedBoxesIds = new Set(selectedBoxes.map(r => r.id));
        setSelectedBoxes(createKitRows.filter(r => selectedBoxesIds.has(r.id)));
    };

    const onCellEditStop = (params, event) => {
        if (params.field === "boxesQuantity") {
            updateSelectedBoxes(params.row, parseInt(event.target.value));
        }
    };

    const cancelAction = () => {
        setOpen(false);
    };

    const doneAction = () => {
        setOpen(false);
        goToSummaryStep();
    };

    const onNext = () => {
        setOpen(true);
    };

    const disbleNextButton = !(selectedBoxes.length === 1 && selectedBoxes[0].boxesQuantity > 0);
    return (
        <>
            <SetOwnersDialog
                open={open}
                userSnap={userSnap}
                selectedOwners={selectedOwners}
                setSelectedOwners={setSelectedOwners}
                done={doneAction}
                cancel={cancelAction}
            />
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => history.goBack()}
            />
            <div className="fulfilment page">
                <h1 className="title">
                    <Text>create</Text>
                    { }
                </h1>
                <div className="section">
                    <SectionInfo label={i18n.resolve('fulfilment.kit.create.steps.kit_model.label')} />
                    <div className="create-kit-table-section">
                        <Table
                            disableColumnSelector={true}
                            disableColumnFilter={true}
                            showExport={false}
                            columns={getCreateKitColumns()}
                            getRowId={(r) => r?.id}
                            rows={createKitRows}
                            onRowSelectionModelChange={ids => setSelectedBoxes(createKitRows?.filter(r => ids?.includes(r?.id)))}
                            onCellEditStop={onCellEditStop}
                        />
                    </div>
                    <FlexBox sx={flexRowJustifyItemsEnd}>
                        <Button
                            disabled={disbleNextButton}
                            variant={Variant.Contained} color={Color.Secondary}
                            onClick={onNext}
                            sx={{ marginLeft: '8px' }}
                        >
                            <Text>next</Text>
                        </Button>
                    </FlexBox>
                </div>
            </div>
        </>
    );
});