import i18n from "services/server/functions/i18n";

import { getBaseColumnsLayer } from "features/fulfilment/components/molecules/Table/uiLayers";
import { InfoBoxLabel } from "features/fulfilment/components/atoms/InfoBoxLabel";

export const getKitColumns = () => getBaseColumnsLayer([
    {
        field: 'reference',
        headerName: i18n.resolve('global.ref'),
    },
    {
        field: 'name',
        headerName: i18n.resolve('global.name'),
    },
    {
        field: 'description',
        headerName: i18n.resolve('fulfilment.kit.description'),
        renderCell: (cellProps) => cellProps.row.description || cellProps.row.template.items.map(i => i.split("/").pop()).join(' + '),
    },
    {
        field: 'status',
        headerName: i18n.resolve('fulfilment.kit.status'),
    },
    {
        field: 'template',
        headerName: i18n.resolve('fulfilment.kitTemplate.box'),
        renderCell: (cellProps) => <InfoBoxLabel label={cellProps.row.template.name} bgColor={cellProps.row.template.hexColorCode} />,
    },
]);