import { groupBy } from "services/server/functions/helpers/utils";

import i18n from "services/server/functions/i18n";

import { InfoBoxLabel } from "features/fulfilment/components/atoms/InfoBoxLabel";

import { Kit } from 'services/server/functions/model/fulfilment/model';

// Only displays the kit templates that have kits with status PREPARED
export const KitsOverview = ({ kits = [], kitTemplates = [], style }) => {
    const templateIdToKits = groupBy(kits.filter(k => k.status === Kit.STATUS.PREPARED), 'template.id');

    const rows = Object.keys(templateIdToKits).map(templateId => {
        const kitTemplate = kitTemplates.find(kt => kt.id === templateId);
        return {
            id: templateId,
            name: kitTemplate?.name,
            hexColorCode: kitTemplate?.hexColorCode,
            amount: templateIdToKits[templateId].length,
        };
    });

    return (<div style={{ border: '3px solid black', borderRadius: '20px', padding: '10px', ...style }}>
        <div style={{ marginBottom: '15px' }}>
            <h3>{i18n.resolve('fulfilment.kit.overview.title')}</h3>
        </div>
        {
            rows.length === 0 ? <div>
                {i18n.resolve('fulfilment.kit.overview.noRows')}
            </div> : <div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    {rows.map(row => {
                        return <div key={row.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                            <InfoBoxLabel label={row.name} bgColor={row.hexColorCode} />
                            <div>{row.amount}</div>
                        </div>;
                    })}
                </div>
            </div>
        }
    </div>);
};