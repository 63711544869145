import { useContext, useEffect, useState } from "react";

import { ThemeProvider } from '@mui/material/styles';

import { PageNavContext } from "ui/acukit/Page";

import { CreateKitSelectStep } from "./SelectStep";
import { CreateKitSummaryStep } from "./SummaryStep";
import useLocationParams from "ui/hooks/useLocationParams";
import { fulfilmentTheme } from "features/fulfilment/components/themes";

import './styles.css';

const Step = Object.freeze({
    Select: 'select',
    Summary: 'summary'
});

const navigationComponents = {
    [Step.Select]: CreateKitSelectStep,
    [Step.Summary]: CreateKitSummaryStep
};
const orderedSteps = [Step.Select, Step.Summary];

export const CreateKit = (props) => {
    const pageNav = useContext(PageNavContext);
    const [locState, setLocState] = useLocationParams();
    
    const [selectedBoxes, setSelectedBoxes] = useState(locState?.selectedBoxes ?? []);
    const [selectedOwners, setSelectedOwners] = useState(locState?.selectedOwners ?? []);

    // Step Indexes 
    const [stepIndex, setStepIndex] = useState(locState?.stepIndex ?? 0);
    const currentStep = orderedSteps[stepIndex] ?? Step.Select;
    const StepComponent = navigationComponents[currentStep];

    useEffect(() => {
        pageNav.enterFullScreen();
    }, []);

    useEffect(() => setLocState({
        ...locState,
        stepIndex,
        selectedBoxes,
        selectedOwners,
    }), [stepIndex, selectedBoxes, selectedOwners]);

    const handleStepNavigation = (index) => {
        const component = navigationComponents[orderedSteps[index]];
        if (typeof component === 'function') {
            setStepIndex(index);
        }
    };

    const goToPreviousStep = () => handleStepNavigation(stepIndex - 1);
    const goToSummaryStep = () => handleStepNavigation(1);

    return (
        <ThemeProvider theme={fulfilmentTheme}>
            <StepComponent
                selectedBoxes={selectedBoxes}
                setSelectedBoxes={setSelectedBoxes}
                selectedOwners={selectedOwners}
                setSelectedOwners={setSelectedOwners}
                goToSummaryStep={goToSummaryStep}
                goToPreviousStep={goToPreviousStep}
                {...props}
            />
        </ThemeProvider>
    );
};
