import Config from "../config";

export const findKitTemplateForStudy = (studySnap, kitTemplates) => {
    const kitContent = getItemsRequiredByStudy(studySnap);
    const validKitTemplates = kitTemplates.filter(kt => isKitTemplateValidForItems(kt, kitContent));
    if (validKitTemplates.length > 1) {
        console.error(`Could find more than 1 compatible kit templates for study`, { studySnap, kitContent, validKitTemplates });
        throw new Error(`Could find more than 1 compatible kit templates for study ${studySnap.data.patient.id}`);
    }
    return validKitTemplates[0];
};

export const isKitTemplateValidForItems = (kitTemplate, kitContent) => {
    const kitTemplateItemsSet = new Set(kitTemplate.items);
    const includesAll = !kitContent.include.some(urn => !kitTemplateItemsSet.has(urn));
    const excludesAll = !kitContent.exclude.some(urn => kitTemplateItemsSet.has(urn));
    const isValid = includesAll && excludesAll;
    return isValid;
};

export const getItemsRequiredByStudy = (studySnap) => {
    const { data: { patient: { instructions: { providedPhone, requiresOximetry } } } } = studySnap;

    const content = {
        include: [Config.items.acuPebbleV1.id],
        exclude: [],
    };

    if (providedPhone) content.include.push(Config.items.phoneSIM.id);
    else content.exclude.push(Config.items.phoneSIM.id);
    
    if (requiresOximetry) content.include.push(Config.items.ringSensor.id);
    else content.exclude.push(Config.items.ringSensor.id);

    return content;
};