import { AppColors } from "../../themes";
import { SectionIcon, ExclamationSectionIcon } from "../icons/fulfilmentIcons";

import './styles.css';

export const SectionInfo = ({ label }) => (
    <div className="section-info-component">
        <div style={{ width: "28px", height: "28px", }}>
            <SectionIcon fill={AppColors.acuYellow} width="28px" height="28px" />
        </div>
        <span className="section-info-component-title">{label}</span>
    </div>
);

export const SectionExclamation = ({ label }) => (
    <div className="section-info-component">
        <div style={{ width: "28px", height: "28px", }}>
            <ExclamationSectionIcon fill={AppColors.acuYellow} width="28px" height="28px" />
        </div>
        <span className="section-info-component-title">{label}</span>
    </div>
);