import { browserStorageTypes } from 'features/constants/browserTypes'; // eslint-disable-line import/no-unresolved
import { getBrowserStorageItem, setBrowserStorageItem } from 'features/helpers/browserStorage'; // eslint-disable-line import/no-unresolved

const {
    authDomain,
    server: _unused,
    ...defaults
} = require('./config.default').default; // import from form does not work well with macros, don't know why ??
export const defaultRegion = defaults.region;

const wrapConfig = ({ firebase, ...appConfig }) => ({
    ...defaults,
    ...firebase,
    ...appConfig,
    //name: `${config.projectId.split('-').shift()}-${config.projectId.split('-').pop()}`, // TODO: review whether this is really required in our app (e.g. develop-eu, staging-uk, ...)
});

const configListeners = [
  region      => setBrowserStorageItem(browserStorageTypes.Region, region),
  (_, config) => window.dispatchEvent(new CustomEvent('onConfigurationChanged', { detail: config }))
];

export let currentRegion;
let suggestedRegion;
export let configurations = {};

export const isHostingChannel = window.location.hostname.includes("--");
export const getHostingChannelRegionConfig = (hostingChannelOrigin = window.location.origin) => Object.values(configurations).find(regionConfig => hostingChannelOrigin.includes(regionConfig.projectId + "--"));

const prepareConfig = (configs, region, product) => {
    const regionConfig = configs[region];
    const localisation = configs.localisation[region];
    localisation.product = product;

    configurations[region].localisation = localisation;

    return {
        ...regionConfig,
        localisation,
    };
};

export const setCurrentRegion = (region, product) => {
    if (region === currentRegion) return;

    console.debug('Using region', region);
    currentRegion = region;
    
    const config = prepareConfig(configurations, currentRegion, product);
    config.assetsDomain = config.assetsDomain.replace(/^(\w*)(?=\.)/g, region);

    notifyConfig(region, config);
};

export const onRegionChanged = ( onRegionChange, idx = configListeners.length ) => {
    if (!onRegionChange) return () => {};
    configListeners.push(onRegionChange);
    if (currentRegion && configurations[currentRegion]) onRegionChange(currentRegion, configurations[currentRegion]);
    return () => configListeners.splice(idx, 1)[0];
};
const notifyConfig = (region, config) => configListeners.forEach((cb) => cb(region, config));
const getLocationRegion = () => new URLSearchParams(window.location.search).get("region");
const getHostnameRegion = () => {
    if (!isHostingChannel) return Object.keys(configurations).find(key => configurations[key].authDomain === window.location.hostname);
    const currentRegionConfig = getHostingChannelRegionConfig();
    return currentRegionConfig.region;
};

export const fetchConfigurations = () => {
    console.debug('Fetching app configurations from:', `https://${authDomain}/_/config/web.json`);
    const cachedConfigs = getBrowserStorageItem('configurations');
    const remoteConfigs = fetch(`//${authDomain}/_/config/web.json`)
        .then(async (response) => response.ok ? response.json() : Promise.reject(response) )
        .then((configs) => {
          console.debug('Configurations fetched from server');
          suggestedRegion = configs.clientRegion;
          if (!getBrowserStorageItem(browserStorageTypes.Region)) setBrowserStorageItem(browserStorageTypes.Region, configs.clientRegion);
          configs = Object.entries(configs)
              .filter( ([_, value]) => (typeof value === 'object' && 'region' in value) || _ === 'localisation' )
              .reduce((all, [region, config]) => ({...all, [region]: config}), {});

          console.debug('Suggested region:', suggestedRegion, '- Available regions:', Object.keys(configs) );
          return configs;
        })
        .then((configs) => {
            setBrowserStorageItem('configurations', configs);
            return configs;
        }); // Store fetched configurations for next time

    // TODO: when configs are cached and hence returned, we should detect somehow if the fetched configurations are different from cached ones and suggest user to refresh the app (note: setBrowserStorageItem has already been called and now cached configurations would be updated)
    if (cachedConfigs)
        console.debug('Using cached configurations while fetching server ones. Refresh the app to use server ones, once server ones have been fetched');

    return cachedConfigs
        ? Promise.resolve(cachedConfigs)
        : remoteConfigs.catch((response) => console.error('Regions config fetch error: ', response) ); // TODO: maybe at this point we should show some error notification saying we were not able to retrieve the updated list of region configurations (internet connection, or other reason) and default or last cached/used app configuration will be used
};

// TODO: Reduce initial load time (specially for slow connections) by:
//  1: by default use last configurations stored in local storage, if undefined then fetchConfigurations
//  2: create component similar to service worker that fetches configuration in background anc checks if something changed, then notifies users when region configuration has changed to ask them to refresh the webapp and force fetch
export default fetchConfigurations()
    .then((configs) => {
        if (Object.keys(configs || {}).length === 0) {
            console.warn('No configurations found, using local ones');
            suggestedRegion = defaultRegion;
            configs = {
                [suggestedRegion]: require('./firebase.default').default,
                ...getBrowserStorageItem('configurations'),
            };
        }
        return configs || {};
    })
    .then((configs) => {
        configurations = Object.entries(configs)
            .filter(([_, value]) => typeof value === 'object')
            .reduce((wrappedConfigs, [region, config]) => {
                wrappedConfigs[region] = wrapConfig(config);
                return wrappedConfigs;
            }, {});
        const regionsPriority = [
            getLocationRegion(), // When using links (e.g. dynamic links) with a given region, then use that region by default
            getHostnameRegion(),
            getBrowserStorageItem('region'), // Remember last selected region and use it by default
            suggestedRegion, // Suggested region from configurations end-point (based on end-user current location)
            defaultRegion,
        ];
        console.debug('Regions priority queue', regionsPriority, '- Available regions', Object.keys(configurations) );
        // product will be undefined on setCurrentRegion and prepareConfig the first time
        // the default product is being set on the userContextProvider
        setCurrentRegion(
            regionsPriority
                .filter(Boolean)
                .find((region) => region in configurations)
        );
        const config = prepareConfig(configurations, currentRegion);
        
        return config;
    });
