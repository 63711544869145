import { useMemo, useState } from 'react';

import { IfHasAccess } from 'ui/hooks/useCurrentUser';
import { WithValidation } from 'ui/components/WithValidation';
import { CustomModal } from 'ui/acukit/ModalDialog';
import { Text } from 'ui/components/Text';

import { Joi, equalsTo } from 'services/server/functions/validation/rules';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { ValidatedSelect } from 'features/helpers/validators';

const ValidatedInput = ({ onValidate, showError, field, ...props }) => <WithValidation field={field || props.name} onValidate={onValidate} showError={showError} {...props}><input {...props} value={props.value || ''} /></WithValidation>;

export const CancelStudyModal = ({ disabled = false, currentUser, ...props }) => {
    const [showError, setShowError] = useState(false);
    const [value, setValue] = useState(undefined);
    const [cancelReason, setCancelReason] = useState(undefined);
    const [cancelCustomText, setCancelCustomText] = useState(undefined);
    const [errors, setErrors] = useState({
        type: 'does not match',
    });

    const confirmed = Object.values(errors).every(e => e === undefined);

    const reasonOptions = useMemo(() => {
        if (props.type !== 'cancel') return [];
        return Object.values(Study.CANCEL_REASON).filter((reason) => {
            if (reason === Study.CANCEL_REASON.REPEATED_STUDY) return false; // deprecated
            if (reason === Study.CANCEL_REASON.REPEATED_STUDY_ACURABLE || reason === Study.CANCEL_REASON.REPEATED_STUDY_PATIENT) return currentUser.isFromAcurable(); // internal only
            return true;
        }).map(r => ({ value: r, label: Text.resolve('module.diagnosis.cancel.reason.' + r) }));
    });

    const submit = () => {
        const data = {
            cancel: {},
        };
        if (cancelReason?.length) {
            data.cancel.reason = cancelReason;
        }
        if (cancelCustomText?.length) {
            data.cancel.customText = cancelCustomText;
        }

        return props.onConfirm(data);
    };

    const onValidate = (field, error) => {
        setErrors(prevErrors => {
            if (error) return prevErrors[field] === error ? prevErrors : { ...prevErrors, [field]: error };
            delete prevErrors[field];
            return { ...prevErrors };
        });
    };

    return disabled ? <></> : (
        <IfHasAccess resource={Study.commands.CANCEL_STUDY} >
            <CustomModal id={props.type}
                btn
                tooltip={props.tooltip}
                secondaryAction={() => { setShowError(true); return confirmed && submit(); }}
                message={''}
                heading={`ViewStudy.${props.type}.header`}
                label={props.type}
            >
                <p><Text variables={{ deleteWord: (<span className='bold red'>'{Text.resolve(props.type).toLowerCase()}'</span>) }}>{props.type === 'cancel' ? "sure-to-cancel-study" : "sure-to-delete-type"}</Text></p>
                <ValidatedInput
                    value={value}
                    schema={equalsTo(Text.resolve(props.type).toLowerCase())}
                    autoFocus={true}
                    onKeyDown={(e) => e.keyCode === 13 ? confirmed && submit() : null}
                    showError={showError}
                    onChange={e => { setValue(e.target.value); e.target.value && e.target.value.toLowerCase() === Text.resolve(props.type).toLowerCase() ? onValidate('type', undefined) : onValidate('type', 'does not match'); return true; }}
                />
                {props.type === 'cancel' ? <div style={{ marginTop: '10px' }}>
                    <p>Reason</p>
                    <ValidatedSelect
                        options={reasonOptions}
                        name="cancel-reason"
                        placeholder={Text.resolve("Select an option")}
                        schema={Joi.string()}
                        mandatory={true}
                        isClearable={true}
                        showError={showError}
                        onChange={(e) => {
                            setCancelReason(e.target.value);
                            if (e.target.type !== Study.CANCEL_REASON.OTHER && errors['cancel-custom-text']) {
                                onValidate('cancel-custom-text', null);
                            }
                        }}
                        onValidate={onValidate}
                        value={cancelReason}
                    />
                    {cancelReason === Study.CANCEL_REASON.OTHER ? <div style={{ marginTop: '5px' }}>
                        <ValidatedInput
                            name="cancel-custom-text"
                            placeholder="Type the reason why you wish to cancel this study"
                            value={cancelCustomText}
                            schema={Joi.string()}
                            autoFocus={true}
                            mandatory={true}
                            showError={showError}
                            onChange={e => { setCancelCustomText(e.target.value); }}
                            onValidate={onValidate}
                        />
                    </div> : undefined}
                </div> : undefined}
            </CustomModal>
        </IfHasAccess>
    );
};