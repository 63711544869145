import { FlexBox } from "../../atoms/Layout";
import { BoxField, InfoField, ListField, TextAreaField } from "./Fields";

const rootStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    '@media (max-width: 960px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (max-width: 600px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
    },
    height: '180px',
};

export const FieldsViewerType = Object.freeze({
    Info: 'info',
    TextArea: 'textarea',
    Box: 'box',
    List: 'list'
});

const components = {
    [FieldsViewerType.Info]: InfoField,
    [FieldsViewerType.Box]: BoxField,
    [FieldsViewerType.TextArea]: TextAreaField,
    [FieldsViewerType.List]: ListField,
};

export const FieldsViewer = ({ items, ...props }) => (
    <FlexBox sx={{...rootStyles, ...props.sx}}>
        {items?.map(c => {
            const Component = components[c?.type] ?? components[FieldsViewerType.Info];
            return <Component key={`${c?.type}-${c?.label}`} {...c} />
        })}
    </FlexBox>
);