import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, useGridRootProps, GridToolbarQuickFilter } from '@mui/x-data-grid';

export const TableHeader = (props) => {
    const {
        csvOptions,
        printOptions,
        excelOptions,
        showDensity = false,
        showColumnsButton = false,
        showQuickFilter = false,
        quickFilterProps = {},
        SelectedActions,
        Actions,
        showExport,
        selectedRows,
        rows,
    } = props;
    const rootProps = useGridRootProps();
    const hasSelectedRows = selectedRows?.length > 0;
    const isNotAvailable = rootProps.disableColumnFilter &&
        rootProps.disableColumnSelector && rootProps.disableDensitySelector &&
        !showQuickFilter;
    if (isNotAvailable) {
        return null;
    }

    return (
        <div className="table-header-root">
            <div className="table-header-bar">
                <div className="quick-filter-root">
                    {showQuickFilter && <GridToolbarQuickFilter {...quickFilterProps} sx={{ width: '100%' }} />}
                </div>
                {showColumnsButton && <GridToolbarColumnsButton />}
                <div className="actions">
                    <GridToolbarFilterButton />
                    {showDensity && <GridToolbarDensitySelector />}
                    { showExport && <GridToolbarExport
                        csvOptions={csvOptions}
                        printOptions={printOptions}
                        excelOptions={excelOptions}
                    />}
                    {Actions && <Actions rows={rows} />}
                </div> 
            </div>
            {hasSelectedRows && SelectedActions && (
                <div className="table-selected-actions">
                    <SelectedActions selectedRows={selectedRows} />
                </div>
            )}
        </div>
    );
};