import { Box } from "@mui/material";

export const flexCol = {
    display: 'flex',
    flexDirection: 'column',
};

export const flexRow = {
    display: 'flex',
    flexDirection: 'row',
};

export const flexColCenter = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

export const flexRowEnd = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
};

export const flexRowCenter = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

export const flexRowBetween = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
};

export const flexRowBeEvenly = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
};


export const flexRowJustifyItemsCenter = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
};


export const flexRowJustifyItemsEnd = {
    width: '100%',
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
};


export const FlexBox = ({ children, ...props }) => (
    <Box sx={{
        display: 'flex',
        ...props?.sx
    }} {...props}>
        {children}
    </Box>
);