import { checkDuplicatedID } from '../policies';
import { HealthcareSite, Organisation } from '../administration/model';

import Config from './config';
import { Joi } from '../../validation/rules';
import { Roles } from '../../iam/roles';
import { Study } from '../diagnosis/model';
import { dataFrom } from '../../executor/urn';
import moment from 'moment';
import { withDefaults } from '..';

const STATUS = {
  created: "CREATED",
  inStock: "IN-STOCK",
  ready: "READY",
  active: "ACTIVE",
  disabled: "DISABLED",
  retired: "RETIRED"
};

const STATUS_LABELS = {
  "CREATED": STATUS.created,
  "IN-STOCK": STATUS.inStock,
  "READY": STATUS.ready,
  "ACTIVE": STATUS.active,
  "DISABLED": STATUS.disabled,
  "RETIRED": STATUS.retired
}

const STATUS_DESCRIPTIONS = {
  "CREATED": "global.device.not_yet_ready_message",
  "IN-STOCK": "Device manufactured and in stock, ready to be shipped to client",
  "READY": "Device assigned and received by client, ready for use",
  "ACTIVE": "global.device.connected_to_app_message",
  "DISABLED": "global.device.disabled_conducted_message",
  "RETIRED": "Device retired so it cannot be used"
};
const STATUS_TRANSITIONS = {
  // target state -> current state: STATUS_TRANSITIONS[target][current]
  [STATUS.created]: { [STATUS.created]: false, [STATUS.inStock]: true, [STATUS.ready]: true, [STATUS.active]: true, [STATUS.disabled]: true, [STATUS.retired]: true },
  [STATUS.inStock]: { [STATUS.created]: true, [STATUS.inStock]: false, [STATUS.ready]: true, [STATUS.active]: true, [STATUS.disabled]: true, [STATUS.retired]: true },
  [STATUS.ready]: { [STATUS.created]: true, [STATUS.inStock]: true, [STATUS.ready]: false, [STATUS.active]: true, [STATUS.disabled]: true, [STATUS.retired]: false },
  [STATUS.active]: { [STATUS.created]: false, [STATUS.inStock]: false, [STATUS.ready]: true, [STATUS.active]: false, [STATUS.disabled]: true, [STATUS.retired]: false },
  [STATUS.disabled]: { [STATUS.created]: true, [STATUS.inStock]: true, [STATUS.ready]: true, [STATUS.active]: true, [STATUS.disabled]: true, [STATUS.retired]: true },
  [STATUS.retired]: { [STATUS.created]: true, [STATUS.inStock]: true, [STATUS.ready]: true, [STATUS.active]: true, [STATUS.disabled]: true, [STATUS.retired]: false },
};

const ISSUE_TYPES = [
  "device-not-working",
  "device-lost",
  "other"
];

// Duplicated in fulfilment
const MODEL_TYPE = Object.freeze({
  ACU_PEBBLE_V1: 'ACU_PEBBLE_V1',
  PHONE: 'PHONE',
  RING_SENSOR: 'RING_SENSOR',
});
const MODEL_TYPES = Object.values(MODEL_TYPE);

const IS_AN_ACUPEBBLE_MODEL_REGEX = /^C.{15}$/;
const isAnAcupebbleModel = (id) => {
  const isMatch = IS_AN_ACUPEBBLE_MODEL_REGEX.test(id);
  return isMatch;
};

let Device = withDefaults()({
  context: Config.context,
  name: 'Device',
  STATUS,
  STATUS_LABELS,
  STATUS_DESCRIPTIONS,
  STATUS_TRANSITIONS,
  ISSUE_TYPES,
  MODEL_TYPE,
  schema: Joi.object().keys({
    type: Joi.string().valid("sensor", "receiver"),
    model: Joi.string().valid(...MODEL_TYPES),
    batchId: Joi.string(),
    serialNumber: Joi.string(),
    flashedDate: Joi.string().isoDateWithOffset(),
    factoryTests: Joi.object().keys({
      result: Joi.boolean(),
      detail: Joi.object().unknown(true),
      file: Joi.string(),
    }),
    activeTime: Joi.number().positive().allow(0), // cumulative sum of all the durations of the recordings done with this sensor.
    recordings: Joi.object().pattern(Joi.string().uri(), Joi.object().keys({ //TODO: maybe extract a common Recording schema so it can be used by both Devices and Diagnosis context?
      test: Joi.string().uri(),
      study: Joi.string().uri(),
      healthcaresite: Joi.string().uri(),
      organisation: Joi.string().uri(),
      startTime: Joi.string().isoDateWithOffset(),
      endTime: Joi.string().isoDateWithOffset(),
      length: Joi.number().positive().allow(0),
      receiver: Joi.string(),
    })),
    status: Joi.string().valid(...Object.values(STATUS)),
    issues: Joi.object().pattern(Joi.string(), Joi.object().keys({
      id: Joi.string(),
      type: Joi.string().valid(...ISSUE_TYPES),
      description: Joi.string(),
    })),
  }),
  reports: {
    inventory: {
      type: 'csv',
      query: () => Device.queries.GET_INVENTORY_REPORT.newRequest()
    }
  },
  events: {
    DEVICE_REGISTERED: { snapshot: (_, prevSnap) => ({ data: { status: !prevSnap?.data.status ? Device.STATUS.created : prevSnap.data.status, activeTime: 0 } }) },
    DEVICE_FIRMWARE_FLASHED: { snapshot: (e) => ({ data: { flashedDate: new Date(e.timestamp).toISOString() } }) },
    DEVICE_FACTORY_TESTS_PASSED: { snapshot: (e, prevSnap) => ({ data: { status: (prevSnap?.data.status && (Object.values(Device.STATUS).indexOf(prevSnap.data.status) > Object.values(Device.STATUS).indexOf(Device.STATUS.inStock))) ? prevSnap.data.status : Device.STATUS.inStock, factoryTests: e.data.factoryTests } }) },
    DEVICE_FACTORY_TESTS_FAILED: { snapshot: (e) => ({ data: { status: Device.STATUS.disabled, factoryTests: e.data.factoryTests } }) },
    DEVICE_RECORDING_COMPLETED: { snapshot: (e, prevSnap) => ({ data: { status: Device.STATUS.active, activeTime: (prevSnap?.data.activeTime || 0) + Object.values(e.data.recordings).reduce((cumsum, r) => ('length' in r) ? (cumsum + r.length) : cumsum, 0), recordings: { ...prevSnap?.data?.recordings, ...e.data.recordings } } }) },
    DEVICE_ISSUE_REPORTED: {
      snapshot: (event, prevSnap) => {
        const updates = { data: { issues: { ...prevSnap?.data?.issues, ...event.data.issues } } };
        if (Object.values(event.data.issues).some(i => i.type === ISSUE_TYPES[1])) updates.data.status = STATUS.disabled;
        return updates;
      }
    },
    DEVICE_ASSIGNED: {},

    DEVICE_UPDATED: {},
  },
});

Device = withDefaults()({
  ...Device,
  snapshot: (event, prevDevice) => {
    const prevDeviceSnap = prevDevice || { data: {}, metadata: {} };
    const changes = { data: { type: event.data.type || prevDeviceSnap.data.type || 'sensor' } };

    if ((!event.data.model || !prevDeviceSnap.data.model) && isAnAcupebbleModel(event.aggregate.id.split("/").pop())) {
      changes.data.model = MODEL_TYPE.ACU_PEBBLE_V1;
    }

    return changes;
  },
  commands: {
    REPORT_DEVICE_ISSUE: {
      schema: Joi.object().keys({ id: Device.schema.extract('id').required(), issues: Device.schema.extract('issues').required() }),
      event: Device.events.DEVICE_ISSUE_REPORTED
    },
    REGISTER_DEVICE: {
      checkPolicies: (dev, _, executor) => checkDuplicatedID(dev, Device, executor),
      label: "Create device",
      schema: Device.schema.fork(['id', 'serialNumber', 'type'], schema => schema.required()),
      event: (action) => {
        const event = Device.events.DEVICE_REGISTERED.new({ ...action.data });
        if (!action.data.model && isAnAcupebbleModel(event.data.id.split("/").pop())) event.data.model = MODEL_TYPE.ACU_PEBBLE_V1;
        return event;
      },
    },
    UPDATE_DEVICE: {
      label: "Edit device",
      schema: Device.schema.fork('id', schema => schema.required()).fork(['batchId', 'type'], schema => schema.forbidden()),
      event: Device.events.DEVICE_UPDATED,
    },
  },
  queries: {
    GET_LABEL_INFO: {
      roles: [Roles.anonymous.id],
      schema: Joi.object().keys({ id: Joi.string(), ids: Joi.array().min(1).items(Joi.string()) }).xor('id', 'ids'),
      newRequest: (args, metadata) => ({
        action: Device.queries.GET_LABEL_INFO.new(args, metadata),
        depends: [_ => [args.id, ...(args.ids || [])].filter(Boolean).map(id => Device.queries.GET.newRequest({ id: Device.newURN(Device.id(id)) }, metadata))],
        transform: ([...devices]) => {
          const toLabelInfo = d => ({
            id: d.data.id,
            type: d.data.type,
            // serialNumber: d.data.serialNumber,
            // activeTime  : d.data.activeTime,
            LOT: d.data.batchId.split('/').pop(),
            fccId: d.data.fccId || (d.data.type === 'sensor' ? '2A258-AP100C04' : (d.data.type === 'ring' ? '2ADXK-8326' : undefined))  // TODO: add FCC IDs when registering devices and register ring sensors
          });

          return devices.length > 1 ? devices.reduce((all, d) => ({ ...all, [Device.id(d.data.id)]: toLabelInfo(d) }), {}) : (devices.length ? toLabelInfo(devices[0]) : {});
        }
      })
    },
    GET_INVENTORY_REPORT: {
      roles: [Roles.superAdmin.id],
      get schema() { return Joi.object().keys({}); },
      newRequest: (_, metadata) => ({
        action: Device.queries.GET_INVENTORY_REPORT.new(undefined, metadata),
        depends: [() => [Organisation.queries.LIST.newRequest({}), HealthcareSite.queries.LIST.newRequest({}), Study.queries.LIST.newRequest({}), Device.queries.LIST.newRequest({})]], // TODO: make LIST accept filtering parameters to make query more efficient and scalable
        transform: ([orgs, sites, studies, devices]) => devices.filter(dev => dev.data.type === "sensor").map(dev => {
          const site = sites.find(s => HealthcareSite.ownersFrom(dev.data).includes(s.aggregate.id));
          const org = site && orgs.find(o => Organisation.ownersFrom(site.data).includes(o.aggregate.id));
          const sensorStudies = studies.filter(s => Object.values(s.data.tests).some(t => t.recording?.sensor === dev.aggregate.id));
          const sensorTests = sensorStudies.reduce((tests, s) => tests.concat(Object.values(s.data.tests).filter(t => t.recording?.sensor === dev.aggregate.id)), []);
          const [firstUse, lastUse] = sensorTests.reduce(([first, last], t) => {
            const startTime = t.recording?.startTime || t.recording?.endTime;
            const endTime = t.recording?.endTime || t.recording?.startTime
            first = startTime === undefined ? first : (first === undefined ? startTime : (moment(first) < moment(startTime) ? first : startTime));
            last = endTime === undefined ? last : (last === undefined ? endTime : (moment(last) > moment(endTime) ? last : endTime));

            return [first, last];
          }, [undefined, undefined]);
          const registeredDate = dev.metadata.events.find(e => dataFrom(e).type === Device.events.DEVICE_REGISTERED)?.timestamp;

          return {
            batch: dev.data.batchId,
            id: Device.id(dev),
            date: registeredDate && moment(registeredDate).format('DD/MM/YYYY'),
            firstUse,
            lastUse,
            studies: sensorStudies.length,
            tests: sensorTests.length,
            status: dev.data.status,
            organisation: org?.data.name,
            site: site?.data.name,
          };
        })
      })
    }
  }
});

Device = require('./Device.et').patch(Device); // a bit of a hack to keep things in the same file.

let Batch = withDefaults()({
  context: Config.context,
  name: 'Batch',
  ISSUE_TYPES,
  schema: Joi.object().keys({
    design: Joi.string(),
    revision: Joi.string(),
    tag: Joi.string(),
    start: Joi.number().integer(),
    end: Joi.number().integer().positive(),
    size: Joi.number().integer().positive(),
    serialNumber: Joi.string(),
    createdDate: Joi.number().integer().positive(),                      // TODO: timestamo, should be a proper date ?
    manifestFile: Joi.string(),
    devices: Joi.object().pattern(Joi.string(), Device.schema), // TODO: at least 1 device      
    issues: Joi.object().pattern(Joi.string(), Joi.object().keys({
      id: Joi.string(),
      type: Joi.string().valid(...ISSUE_TYPES),
      description: Joi.string(),
    })),
    errors: Joi.object(),
  }),
  events: {
    BATCH_IMPORTED: {},
    BATCH_ISSUE_REPORTED: {},
    BATCH_REGISTERED: {
      label: 'Batch created',
      snapshot: (event) => ({ data: { createdDate: event.data.createdDate || event.timestamp } }),
    }
  },
})

Batch = withDefaults(Device)({
  ...Batch,
  snapshot: (event, prevSnap) => ({ data: { issues: { ...prevSnap?.data?.issues, ...event.data.issues } } }),
  commands: {
    REPORT_BATCH_ISSUE: {
      schema: Joi.object().keys({ id: Batch.schema.extract('id').required(), issues: Batch.schema.extract('issues').required() }),
      event: Batch.events.BATCH_ISSUE_REPORTED
    },
    REGISTER_BATCH: {
      checkPolicies: (batch, _, executor) => checkDuplicatedID(batch, Batch, executor),
      label: "Create batch",
      schema: Batch.schema.xor('devices', 'manifestFile').fork(['id', 'size', 'serialNumber'], schema => schema.required()).fork(['createdDate', 'issues', 'errors'], schema => schema.forbidden()),
      event: Batch.events.BATCH_REGISTERED,
    }
  }
});

export {
  Device,
  Batch
};