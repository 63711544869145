import { useState, useEffect } from 'react';
import history from 'history.js';
import { List, ListItem, Button } from '@mui/material';

import * as Pages from '../../index';

import { Text } from "ui/components/Text";
import { FlexBox, flexRowBeEvenly, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";

import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import i18n from "services/server/functions/i18n";

import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";

import './styles.css';
import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';
import { InfoBoxLabel } from 'features/fulfilment/components/atoms/InfoBoxLabel';

export const SummaryOverviewStep = ({
    box, goToLabelsStep, upcomingBoxIds, prepareNewKitBatch, owners,
}) => {
    const [generatedKits, setGeneratedKits] = useState(new Array(box.boxesQuantity).fill(false));

    useEffect(() => {
        // Finished generating all kits
        if (generatedKits.every(k => k)) history.push(Pages.OrderLanding.PATH);
    }, [generatedKits]);

    const generateKits = () => {
        const onProgress = (result, row) => {
            const isInvalid = result.status !== 'fulfilled';
            if (isInvalid) {
                return console.error({ row, type: result.reason?.data?.code, message: result.reason?.data?.details });
            }
            generatedKits[row] = result.value.data;
            setGeneratedKits(generatedKits.map(e => e));
        };

        const kits = upcomingBoxIds.map((id) => {
            return {
                id,
                name: box.name,
                template: box.id,
                owners,
            };
        });

        return prepareNewKitBatch(kits, onProgress);
    };

    const { assetsURl } = useAssetsURL();
    return (
        <>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={goToLabelsStep}
            />
            <div className="fulfilment page">
                <h1 className="title">
                    <FlexBox>
                        <Text>create</Text>
                        <FlexBox sx={{ marginLeft: '8px' }}>
                            {box?.name}
                        </FlexBox>
                    </FlexBox>
                </h1>
                <div className="create-kit-summary-content">
                    <div className="create-kit-overview-section">
                        <FlexBox>
                            <span className="create-kit-template-primary-title">
                                <Text>fulfilment.kit.create.kit_template.title</Text>
                            </span>
                            <InfoBoxLabel label={box?.name}
                                sx={{ marginLeft: '30px', bgColor: box?.hexColorCode }} />
                            <span className="create-kit-template-summary-title">
                                {box?.description}
                            </span>
                        </FlexBox>
                        <FlexBox sx={{ ...flexRowBeEvenly, marginTop: '40px', alignItems: "center", }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "fit-content", }}>
                                <span>{i18n.resolve('fulfilment.kit.create.prepareBoxes', { numberOfBoxes: box.boxesQuantity })}</span>
                                <div className="create-kit-box-border create-kit-list-items">
                                    <List>
                                        {box?.items?.map(item => (
                                            <ListItem key={item.id}>
                                                {item?.name || item.id.split("/").pop()}
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                            <div style={{ padding: "15px", border: "2px solid orange", backgroundColor: "papayawhip", height: "fit-content", }}>
                                <span>{Text.resolve("fulfilment.kit.create.stickbarCode")}</span>
                            </div>
                        </FlexBox>
                        <FlexBox sx={{ ...flexRowBeEvenly, marginTop: '100px' }}>
                            <img
                                className="create-kit-box-img"
                                alt="acuppeble box"
                                src={getImgUrl(
                                    assetsURl,
                                    'fulfilment_placeholder.png'
                                )}
                            />
                            <img
                                className="box-image"
                                alt="acuppeble box"
                                src={getImgUrl(
                                    assetsURl,
                                    'full_box_img.png'
                                )}
                            />
                        </FlexBox>
                    </div>
                    <FlexBox sx={flexRowJustifyItemsEnd}>
                        <Button
                            variant={Variant.Contained}
                            color={Color.Secondary}
                            onClick={() => generateKits()}
                        >
                            <Text>done</Text>
                        </Button>
                    </FlexBox>
                </div>
            </div>
        </>
    )
};