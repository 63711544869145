
export const SendPackageIcon = (props) => <svg id="send-package-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#004366" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M16 12.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
    <path d="M11 9.5v5.5l5 3" />
    <path d="M16 12.545l5 -3.03" />
    <path d="M7 9h-5" />
    <path d="M7 12h-3" />
    <path d="M7 15h-1" />
</svg>;

export const ViewDetailsIcon = (props) => <svg id="view-details-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#004366" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
    <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
</svg>;

export const CreateKitIcon = (props) => <svg id="create-kit-icon" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 3l8 4.5v9l-8 4.5l-8 -4.5v-9l8 -4.5" />
    <path d="M12 12l8 -4.5" />
    <path d="M8.2 9.8l7.6 -4.6" />
    <path d="M12 12v9" />
    <path d="M12 12l-8 -4.5" />
</svg>;

export const ReturnedKitIcon = (props) => <svg id="returned-kit-icon" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 21l-8 -4.5v-9l8 -4.5l8 4.5v4.5" />
    <path d="M12 12l8 -4.5" />
    <path d="M12 12v9" />
    <path d="M12 12l-8 -4.5" />
    <path d="M22 18h-7" />
    <path d="M18 15l-3 3l3 3" />
</svg>


export const PendingOrdersIcon = (props) => <svg id="pending-order-icon" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
    <path d="M2 13.5v5.5l5 3" />
    <path d="M7 16.545l5 -3.03" />
    <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z" />
    <path d="M12 19l5 3" />
    <path d="M17 16.5l5 -3" />
    <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5" />
    <path d="M7 5.03v5.455" />
    <path d="M12 8l5 -3" />
</svg>

export const BrowseListIcon = (props) => <svg id="browse-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M15 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
    <path d="M18.5 18.5l2.5 2.5" />
    <path d="M4 6h16" />
    <path d="M4 12h4" />
    <path d="M4 18h4" />
</svg>;

export const PackageIcon = (props) => <svg id="package-icon" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
    <path d="M12 12l8 -4.5" />
    <path d="M12 12l0 9" />
    <path d="M12 12l-8 -4.5" />
</svg>;


export const SectionIcon = (props) => <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round" transform="rotate(90)" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M11.99 1.968c1.023 0 1.97 .521 2.512 1.359l.103 .172l7.1 12.25l.062 .126a3 3 0 0 1 -2.568 4.117l-.199 .008h-14l-.049 -.003l-.112 .002a3 3 0 0 1 -2.268 -1.226l-.109 -.16a3 3 0 0 1 -.32 -2.545l.072 -.194l.06 -.125l7.092 -12.233a3 3 0 0 1 2.625 -1.548z" strokeWidth="0" fill={`${props?.fill}`} />
</svg>;

export const ExclamationSectionIcon = (props) => <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" {...props}>
    <path fill={`${props?.fill}`} d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
</svg>


export const BackIcon = props => <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9CBCFF" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M11 7l-5 5l5 5" />
    <path d="M17 7l-5 5l5 5" />
</svg>;


export const ViewOrderIcon = props => <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#f3ba00" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
    <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
</svg>