
import { Joi } from '../../../validation/rules';

import Config from '../config';
import { withDefaults } from '../..';
import { runAlreadyExistsPolicy } from '../../policies';
import { hash } from '../../../helpers/crypto';

// hash of sorted Items Ids => in order to query Kits that match a set of Items
const generateContentHash = (items) => {
    const text = items.map(urn => urn.split("/").pop()).join("");
    return hash(text);
};

let KitTemplate = {
    context: Config.context,
    name: 'KitTemplate',
    generateContentHash,
    schema: Joi.object().keys({
        // id => might be human descriptive name
        name: Joi.string(),
        description: Joi.string(),
        asset: Joi.string().uri(), // Web URL
        hexColorCode: Joi.string().pattern(/^#[0-9A-Fa-f]{6}$/), // Example: #9FE2BF
        items: Joi.array().items(Joi.string().uri()), // reference of Item
    }),
    events: {
        KIT_TEMPLATE_CREATED: {},
    },
    commands: {
        CREATE_KIT_TEMPLATE: {
            checkPolicies: async (requestData, existingKitTemplate) => await Promise.all([
                runAlreadyExistsPolicy(requestData, existingKitTemplate),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: KitTemplate.schema.extract('id'),
                    name: KitTemplate.schema.extract('name').required(),
                    description: KitTemplate.schema.extract('description'),
                    asset: KitTemplate.schema.extract('asset'),
                    hexColorCode: KitTemplate.schema.extract('hexColorCode'),
                    items: KitTemplate.schema.extract('items').required().min(1),
                });
            },
            event: (action) => {
                return KitTemplate.events.KIT_TEMPLATE_CREATED.new({ ...action.data }, { contentHash: generateContentHash(action.data.items) });
            },
        },
    },
    queries: {},
};
KitTemplate = withDefaults()(KitTemplate);

export default KitTemplate;