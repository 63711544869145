import { DataGrid } from '@mui/x-data-grid';

import { TableHeader } from './TableHeader';

import './styles.css';
import { useState } from 'react';

const DEFAULT_PAGE_SIZE = [5, 10];
/**
 *  
 * api: https://mui.com/x/api/data-grid/data-grid/
 * columns: GridColDef https://mui.com/x/api/data-grid/grid-col-def/
 * rows: https://mui.com/x/api/data-grid/grid-row-params/
 */

export const Table = ({
    columns, rows, pageSizeOptions, initialState,
    SelectedActions, Actions,
    withHeader = true,
    showExport = true,
    checkboxSelection = true,
    ...overrides
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelection = (rows) => {
        setSelectedRows(rows);
        overrides?.onRowSelectionModelChange?.(rows);
    };

    const slots = withHeader ? {
        toolbar: TableHeader
    } : {};

    return (
        <div className="table-data-grid">
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    ...initialState,
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={pageSizeOptions ?? DEFAULT_PAGE_SIZE}
                checkboxSelection={checkboxSelection}
                slots={slots}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        SelectedActions,
                        Actions,
                        selectedRows,
                        rows,
                        showExport
                    },
                }}
                {...overrides}
                onRowSelectionModelChange={handleSelection}
            />
        </div>
    );
};