import { Order, Kit } from 'services/server/functions/model/fulfilment/model';
import ServerConfig  from 'services/server/functions/model/administration/config';

const modulePriority = 2000;

const ProcessOrderRoles = Order.commands.PROCESS_ORDER.roles;
const ReturnOrderRoles  = Kit.commands.MARK_AS_VALIDATED.roles;

export default {
    context: ServerConfig.context,
    priority: modulePriority,
    routes: {
        // Fulfilment
        Landing: { priority: modulePriority + 1, relpath: `/order`, roles: [ProcessOrderRoles, ReturnOrderRoles, Kit.commands.PREPARE_NEW_KIT.roles, Order.queries.FIND_ORDER.roles] },
        Orders: { priority: modulePriority + 2, relpath: `/order/find`, roles: Order.queries.FIND_ORDER.roles },
        Kits: { priority: modulePriority + 3, relpath: `/kit/find`, roles: Kit.queries.LIST.roles },
        CreateKit: { priority: modulePriority + 4, relpath: `/kit/create`, roles: Kit.commands.PREPARE_NEW_KIT.roles },
        ReturnedKit: { priority: modulePriority + 5, relpath: `/kit/returned`, roles: ReturnOrderRoles },
        ProcessOrder: { priority: modulePriority + 6, relpath: `/order/process`, roles: ProcessOrderRoles },
        ViewOrder: { priority: modulePriority + 7, relpath: `/order/view`, roles: Order.queries.VIEW_ORDER.roles },
    }
};
