import { List, ListItem, Button } from '@mui/material';

import * as Pages from '../../index';

import { Text } from "ui/components/Text";
import ExportReport from 'ui/components/ExportReport';
import { FlexBox, flexRow, flexColCenter, flexRowBeEvenly, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";

import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import i18n from "services/server/functions/i18n";

import { getImgUrl, useAssetsURL } from "ui/hooks/useAssetsUrl";

import './styles.css';
import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";
import { SectionInfo } from "features/fulfilment/components/atoms/SectionInfo";
import { InfoBoxLabel } from 'features/fulfilment/components/atoms/InfoBoxLabel';

import { Kit } from 'services/server/functions/model/fulfilment/model';

export const SummaryLabelsStep = ({ box, goToPreviousStep, goToOverviewStep, upcomingBoxIds, setUpcomingBoxIds }) => {
    const numberOfBoxes = box?.boxesQuantity ?? 0;
    if (numberOfBoxes === 0) {
        history.push(Pages.CreateKit.PATH);
        return <></>;
    }

    if (upcomingBoxIds.length !== box.boxesQuantity) {
        setUpcomingBoxIds(new Array(box.boxesQuantity).fill(0).map(() => Kit.newURN()));
    }

    const exportLabels = async (kitLabelsInfo) => {
        const getLetters = _ => require('services/server/functions/executor/executor.client').getReport(kitLabelsInfo);
        return `data:data:application/pdf;base64,${await getLetters()}`; // TODO: export PDF assumes data URI. remove everywhere the use of data URIs and use URL.createObjectURL
    };

    const exportLabelsPDF = async () => {
        return await exportLabels(upcomingBoxIds.map(id => ({
            reportId: 'fulfilment.Kit.labels',
            reference: Kit.generateKitReference(id),
            template: {
                name: box.name,
                hexColorCode: box.hexColorCode,
            },
        })));
    };

    const { assetsURl } = useAssetsURL();
    return (
        <>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={goToPreviousStep}
            />
            <div className="fulfilment page">
                <h1 className="title">
                    <FlexBox>
                        <Text>create</Text>
                        <FlexBox sx={{ marginLeft: '8px' }}>
                            {box?.name}
                        </FlexBox>
                    </FlexBox>
                </h1>
                <div className="create-kit-summary-content">
                    <FlexBox sx={{ marginTop: '60px', marginBottom: '44px' }}>
                        <SectionInfo label={i18n.resolve('fulfilment.kit.create.steps.kit_template.label', { quantity: numberOfBoxes })} />
                    </FlexBox>
                    <FlexBox sx={{ ...flexRow, marginLeft: '28px', marginBottom: '44px' }}>
                        <span className="create-kit-template-primary-title">
                            <Text>fulfilment.kit.create.kit_template.title</Text>
                        </span>
                        <InfoBoxLabel label={box?.name}
                            sx={{ marginLeft: '30px', bgColor: box?.hexColorCode }} />
                        <span className="create-kit-template-summary-title">
                            {box?.description}
                        </span>
                    </FlexBox>
                    <FlexBox sx={flexRowBeEvenly}>
                        <FlexBox sx={flexColCenter}>
                            <img
                                className="barcode-image"
                                alt="acuppeble bar code"
                                src={getImgUrl(
                                    assetsURl,
                                    'barcode_placeholder.svg'
                                )}
                            />
                            <ExportReport
                                id="exportLabelsPDF"
                                // disabled={loading || !canGetLetter}
                                label={i18n.resolveValue("global.print")?.toUpperCase()}
                                secondary
                                downloadFile={`${numberOfBoxes}_labels_of_${box.name}.pdf`}
                                urlCallback={exportLabelsPDF}
                                style={{ width: "116px", padding: "10px", textAlign: "center" }}
                            />
                        </FlexBox>
                        <div className="create-kit-box-border create-kit-list-items">
                            <List>
                                {box?.items?.map(item => (
                                    <ListItem key={item.id}>
                                        {item?.name || item.id.split("/").pop()}
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </FlexBox>
                    <FlexBox sx={{
                        ...flexRowJustifyItemsEnd,
                        marginTop: '40px',
                        marginRight: '30px'
                    }}>
                        <Button
                            sx={{ width: '116px' }}
                            variant={Variant.Contained}
                            color={Color.Secondary}
                            onClick={goToOverviewStep}
                        >
                            <Text>next</Text>
                        </Button>
                    </FlexBox>
                </div>
            </div>
        </>
    );
};