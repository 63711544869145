import { TextareaAutosize } from "@mui/material";
import { InfoBoxLabel } from "../../atoms/InfoBoxLabel";
import { FlexBox } from "../../atoms/Layout";
import {flexCol} from '../../atoms/Layout/index';
import { AppColors } from "../../themes";
import { FieldsViewerType } from ".";

const titleStyles = {
    color: AppColors.mediumAcuGrey,
    fontWeight: 700,
    fontSize: '20px',
    marginBottom: '8px',
};
const valueStyles = {
    color: AppColors.lightAcuGrey,
    fontSize: '18px'
};


export const InfoField = ({ label, value, ...rest }) => (
    <FlexBox {...rest} sx={flexCol}>
        <FlexBox sx={titleStyles}>{label}</FlexBox>
        <FlexBox sx={valueStyles}>{value}</FlexBox>
    </FlexBox>
);

export const BoxField = ({ label, sx, value, bgColor, ...rest }) => (
    <FlexBox {...rest} sx={flexCol}>
        <FlexBox sx={titleStyles}>{label}</FlexBox>
        <InfoBoxLabel label={value} bgColor={bgColor} sx={{
            width: '150px !important',
            ...sx
        }} />
    </FlexBox>
);

export const TextAreaField = ({ label, value, ...rest }) => (
    <FlexBox {...rest} sx={flexCol}>
        <FlexBox sx={titleStyles}>{label}</FlexBox>
        <TextareaAutosize
            minRows={4} maxRows={6} defaultValue={value}
            disabled={true}
        />
    </FlexBox>
);

export const ListField = ({ label, itemType, values, ...rest }) => (
    <FlexBox {...rest} sx={flexCol}>
        <FlexBox sx={titleStyles}>{label}</FlexBox>
        {values?.map((item, index) => {
            const isBox = itemType === FieldsViewerType.Box;
            const key = `${item?.label}-${index}`;
            return isBox ? (
                <InfoBoxLabel
                    key={key}
                    label={item?.label} sx={{
                    width: '100px !important',
                    marginBottom: '8px'
                }} />
            ) :
                (<FlexBox key={key} sx={valueStyles}>{item?.label}</FlexBox>);
        })}
    </FlexBox>
);
