import { useContext, useEffect, useState } from "react";

import { ThemeProvider } from '@mui/material/styles';

import history from 'history.js';
import * as Pages from '../../index';

import { PageNavContext } from "ui/acukit/Page";
import useSnapshot from 'ui/hooks/useSnapshot';
import useLocationParams from "ui/hooks/useLocationParams";

import '../styles.css';
import { fulfilmentTheme } from "features/fulfilment/components/themes";

import { ImportCSVStep } from './ImportCSVStep';
import { ShippingDetailsStep } from './ShippingDetailsStep';
import { StickCodesStep } from './StickCodesStep';
import { PhoneStickerStep } from './PhoneStickerStep';
import { ScanStep } from './ScanStep';
import { KitTemplate, Kit } from "services/server/functions/model/fulfilment/model";
import { Study } from "services/server/functions/model/diagnosis/model";

const Step = Object.freeze({
    ImportCSV: 'import_csv',
    ShippingDetails: 'shipping_details',
    StickCodes: 'stick_codes',
    PhoneSticker: 'phone_sticker',
    Scan: 'scan',
});

const navigationComponents = {
    [Step.ImportCSV]: ImportCSVStep,
    [Step.ShippingDetails]: ShippingDetailsStep,
    [Step.StickCodes]: StickCodesStep,
    [Step.PhoneSticker]: PhoneStickerStep,
    [Step.Scan]: ScanStep,
};
const orderedSteps = [Step.ImportCSV, Step.ShippingDetails, Step.StickCodes, Step.PhoneSticker, Step.Scan];

export const ProcessOrder = (props) => {
    const pageNav = useContext(PageNavContext);
    const [locState, setLocState] = useLocationParams();
    
    const [kitTemplateSnaps] = useSnapshot(KitTemplate);
    const [kitSnaps] = useSnapshot(Kit);
    const [studiesSnaps, isLoadingStudiesSnaps] = useSnapshot(Study);
    
    const [orders, setOrders] = useState([]);
    const [orderShippingUpdates, setOrderShippingUpdates] = useState({});

    if (!locState.fromCSV && !locState.ordersToProcess?.length) {
        history.push(Pages.Order.PATH, { needsOrdersToProcess: true });
        return <></>;
    }

    // Step Indexes 
    const [stepIndex, setStepIndex] = useState(locState?.stepIndex ? locState.stepIndex : locState.fromCSV ? 0 : 1);
    const currentStep = orderedSteps[stepIndex] ?? Step.Select;
    const StepComponent = navigationComponents[currentStep];
    
    useEffect(() => {
        pageNav.enterFullScreen();
    }, []);

    useEffect(() => setLocState({
        ...locState, stepIndex,
    }), [stepIndex]);
    
    useEffect(() => {
        setOrders(locState.ordersToProcess);
        if (locState.orderShippingUpdates) setOrderShippingUpdates(locState.orderShippingUpdates);
    }, [locState]);

    const handleStepNavigation = (index) => {
        const component = navigationComponents[orderedSteps[index]];
        if (typeof component === 'function') {
            setStepIndex(index);
        }
    };

    const goToPreviousStep = () => stepIndex - 1 === 0 && !locState.fromCSV ? history.goBack() : handleStepNavigation(stepIndex - 1);
    const goToShippingDetailsStep = () => handleStepNavigation(1);
    const goToStickCodesStep = () => handleStepNavigation(2);
    const goToPhoneStickerStep = () => handleStepNavigation(3);
    const goToScanStep = () => handleStepNavigation(4);

    return (
        <ThemeProvider theme={fulfilmentTheme}>
            <StepComponent
                orders={orders}
                orderShippingUpdates={orderShippingUpdates}
                setOrderShippingUpdates={setOrderShippingUpdates}
                studiesSnaps={studiesSnaps || {}}
                isLoadingStudiesSnaps={isLoadingStudiesSnaps}
                kitTemplates={Object.values(kitTemplateSnaps || {}).map(s => s.data)}
                kits={Object.values(kitSnaps || {}).map(s => s.data)}
                goToShippingDetailsStep={goToShippingDetailsStep}
                goToStickCodesStep={goToStickCodesStep}
                goToPhoneStickerStep={goToPhoneStickerStep}
                goToPreviousStep={goToPreviousStep}
                goToScanStep={goToScanStep}
                setCourierLabels={props.setCourierLabels}
                setCourierLabelsBatch={props.setCourierLabelsBatch}
                {...props}
            />
        </ThemeProvider>
    );
};
