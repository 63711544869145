import * as Pages from '../../index';
import i18n from 'services/server/functions/i18n';

export const getLandingNavigationItems = (currentUser) => [{
    label: i18n.resolve('fulfilment.kit.title'),
    navigations: [
        {
            label: i18n.resolve('view'),
            page: Pages.Kit
        },
        {
            label: i18n.resolve('create'),
            page: Pages.CreateKit
        },
    ],
}, {
    label: i18n.resolve('fulfilment.order.process_pending_orders'),
    navigations: [
        {
            label: i18n.resolve('global.process'),
            page: Pages.Order,
            state: {
                needsOrdersToProcess: true,
                filters: [{
                    id: 1,
                    field: 'status',
                    operator: 'equals',
                    value: 'PENDING',
                }]
            }
        },
    ],
}, {
    label: i18n.resolve('fulfilment.kit.returned.check_text'),
    navigations: [
        {
            label: i18n.resolve('global.check'),
            page: Pages.ReturnedKit
        },
    ],
}].map(item => ({ ...item, navigations: item.navigations.filter(nav => currentUser.hasAccess(nav.page))})).filter(({ navigations }) => navigations.length);