import { useContext } from 'react';
import * as Pages from 'modules/diagnosis';
import moment from 'moment';

import { UserContext } from 'features/providers/userContextProvider';
import { Study } from 'services/server/functions/model/diagnosis/model';
import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import ExportPDF from 'ui/components/ExportReport';
import { Text } from 'ui/components/Text';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import i18n from 'services/server/functions/i18n';

const exportReport = (report, id, product) => () => require('services/server/functions/executor/executor.client')
                                        .getReport(`diagnosis.Study.${report}`, { id, product })
                                        .then(encFile => `data:application/pdf;base64,${encFile}`);

export default (props) => {
  const { selectedProduct } = useContext(UserContext);
  const [currentUser] = useCurrentUser();

  const patientID      = props.study?.data?.patient.id || "-";
  const studyDate      = props.study?.data?.date ? moment(props.study?.data?.date).format("L") : "-";
  const fileName = i18n.resolve('report.Instructions.onboarding.file_name');
  const reportFilename = `${fileName}_${patientID}_${studyDate}.pdf`;

  return <div id="PrepareOnboarding">
      <div className='pageSection'>
        <div className='sectionHeader'>
          <div className='title'><Text>CreateStudy.onboarding.study-summary</Text></div>
          <div className="actions">
            <Pages.EditStudy.AsLinkButton text="edit" study={props.study?.data?.id}/>
          </div>
        </div>
        <DetailsBlock className="studyDetailsHeader studySummaryHeader">
          <div className="grid-row">
            <div className="grid-col">
              <DetailsBlock className="profileBlock">
                <div className="grid-col">
                  <Details id="studyPatientID" label="patient-id" value={props.study?.data?.patient.id} />
                  <Details id="studyPatientBirth" label="global.date_of_birth" value={props.study?.data?.patient.birthDate ? moment(props.study?.data?.patient.birthDate, 'DD/MM/YYYY').format("L") : "-"} />
                </div>
              </DetailsBlock>
              <DetailsBlock className="profileBlock">
                <div className="grid-col">
                  <Details id="studyReference" label="study-reference" value={Study.getReference(props.study?.data) || "-"} />
                  <Details id="numTests" label="ViewStudy.num-tests" helpPosition="top">
                    <span>{props.study?.data?.requestedTests || 0}</span>
                  </Details>
                </div>
              </DetailsBlock>
            </div>
            <DetailsBlock className="profileBlock">
              <div className="grid-col">
                <Details asModal id="onboardingRequired" label="CreateStudy.options.onboarding">
                  {['chargePatient'/*, 'chargeAmount'*/, 'fulfillCourier', 'requireShipDetails', 'requireDeviceModel', 'providedPhone'].reduce((options, opt) => props.study?.data?.patient.instructions[opt] ? (options || []).concat(<li key={opt}><Text variables={{value: Text.resolve(props.study?.data?.patient.instructions[opt])}}>{`CreateStudy.patient.instructions.${opt}.value`}</Text></li>) : options, null)}
                </Details>
                <Details asModal id="onboardingQuestionnaire" label="CreateStudy.options.questionnaire">
                  {['fillQuestionnaire', 'questionnaireThreshold'].reduce((options, opt) => (props.study?.data?.patient.instructions[opt] && props.study?.data?.patient.instructions[opt] !== 'None') ? (options || []).concat(<li key={opt}><Text variables={{value: Text.resolve(props.study?.data?.patient.instructions[opt])}}>{`CreateStudy.patient.instructions.${opt}.value`}</Text></li>) : options, null)}
                </Details>
                <div />
                <div />
              </div>
            </DetailsBlock>
          </div>
        </DetailsBlock>
    </div>
    <div className="pageSection">
      <DetailsBlock inline>
        <div className="grid-row">
          <h3><Text>CreateStudy.onboarding.print-instructions</Text></h3>
          <span><Text>CreateStudy.onboarding.instructions-1</Text></span>
          <ul>
            <li><Text>CreateStudy.onboarding.instructions-1.step-1</Text></li>
            <li><Text>CreateStudy.onboarding.instructions-1.step-2</Text></li>
          </ul>
          <span><Text>CreateStudy.onboarding.instructions-2</Text></span>
          <ExportPDF disabled={!currentUser.hasAccess(Study.queries.GET_ONBOARDING_LETTER)} label="global.download_pdf_message" secondary downloadFile={reportFilename} id="exportOnboarding" urlCallback={exportReport('onboarding', props.study?.aggregate?.id, selectedProduct?.key)} />
        </div>
        <div className="vcentered instructionImg">
          <img style={{maxWidth: "70%"}} alt="" src={require('./images/printer-code.png').default}/>
        </div>
      </DetailsBlock>
    </div>
  </div>;
}