
import { Box } from '@mui/material';
import './styles.css';
import { AppColors } from '../../themes';

export const InfoBoxLabel = ({ label, sx, bgColor = AppColors.lightAcuBlue, ...rest }) => (
    <Box className="info-box-label-component"
        sx={{ ...sx, backgroundColor: bgColor }} {...rest}>{label}
    </Box>
);
