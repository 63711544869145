import { useState, useEffect, useCallback } from "react";
import { Button } from '@mui/material';

import history from 'history.js';

import i18n from "services/server/functions/i18n";
import { Text } from "ui/components/Text";
import Loading from "ui/components/Loading";

import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import { FlexBox, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";
import { SectionInfo } from "features/fulfilment/components/atoms/SectionInfo";
import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";

import useLocationParams from "ui/hooks/useLocationParams";
import useProjection from "ui/hooks/useProjection";

import FileDrop from 'ui/components/FileDrop';
import { Order } from "services/server/functions/model/fulfilment/model";
import { Study } from "services/server/functions/model/diagnosis/model";
import { groupBy } from "services/server/functions/helpers/utils";

export const ImportCSVStep = ({
    goToShippingDetailsStep, studiesSnaps = {}, isLoadingStudiesSnaps,
}) => {
    const [locState, setLocState] = useLocationParams();
    const [orders = [], isLoadingOrders] = useProjection(Order.queries.FIND_ORDER.newRequest({}));
    const isLoading = isLoadingStudiesSnaps || isLoadingOrders;

    const [isValid, setIsValid] = useState(false);
    const [csvRows, setCSVRows] = useState([]);
    const [errors, setErrors] = useState([]);

    const referenceToStudySnaps = groupBy(Object.values(studiesSnaps).map(s => {
        s.data.reference = Study.getReference(s.data);
        return s;
    }), 'data.reference');
    const ownerToOrders = groupBy(orders, 'owner');

    const model = Order.commands.SET_COURIER_LABELS;
    const schema = model.schema.tailor('csv').tailor('import').prefs({ abortEarly: false, noDefaults: true });

    // CSV template
    const { fields } = model.describe(schema);
    const template = fields.flatten().withMeta(meta => meta?.csv?.header).map(f => f.field).join(',');
    const url = URL.createObjectURL(new Blob([Buffer.from(template)], { type: 'text/csv' }));
    useEffect(() => () => { URL.revokeObjectURL(url) }, [url]);

    const getErrors = useCallback(() => {
        const validationErrors = [];

        csvRows.forEach((row, index) => {
            validationErrors.push(...row.errors);
            const studySnap = referenceToStudySnaps[row.data.reference]?.[0];
            if (!studySnap) {
                return validationErrors.push({
                    row: index,
                    type: "custom.invalid",
                    field: "reference",
                    message: "There is no study with the provided reference",
                });
            }

            const order = ownerToOrders[studySnap.aggregate.id]?.[0];
            if (!order) {
                return validationErrors.push({
                    row: index,
                    type: "custom.invalid",
                    field: "reference",
                    message: "There is no order associated to the study of the provided reference",
                });
            }
            if (!(order.status === Order.STATUS_GROUP.PENDING /*&& Object.keys(params.row.recipient || {}).length > 6*/ && !order.kit)) {
                return validationErrors.push({
                    row: index,
                    type: "custom.invalid",
                    field: "reference",
                    message: `The order must be in status ${Order.STATUS_GROUP.PENDING} and without a kit assigned`,
                });
            }
        });

        return validationErrors;
    }, [csvRows, studiesSnaps, orders]);

    useEffect(() => {
        if (!isLoading) {
            const validationErrors = getErrors();
            setErrors(validationErrors);
            setIsValid(csvRows.length && !validationErrors.length);
        }
    }, [isLoading, getErrors]);

    const onCSVUpload = (rows) => {
        setCSVRows(rows);
    };

    const validate = ({ row, data, settings = {}, errors = [], ...other }) => {
        const { value, error } = schema.validate(data);

        errors = [
            ...errors.filter(e => !e.field), // keep other type of errors not related to the schema validation, removes old errors asociated with this row 
            ...(error?.details || []).map(({ type, path, message }) => ({ row, type, field: path.join('.'), message })) // new errors if any
        ];

        const missing = errors.filter(e => e.type.endsWith(".required")).map(f => f.field).filter((f, i, a) => a.indexOf(f) === i);
        const unknown = errors.filter(e => e.type.endsWith(".unknown")).map(f => f.field).filter((f, i, a) => a.indexOf(f) === i);
        const knownErrors = errors.filter(e => !e.type.endsWith(".unknown"));
        const invalid = knownErrors.map(f => f.field).filter((f, i, a) => f && a.indexOf(f) === i);

        errors = knownErrors; // actual errors we care about    
        return ({ ...other, row, instance: value, data, settings, invalid: Boolean(errors.length), errors, missingFields: missing, unknownFields: unknown, invalidFields: invalid })
    };

    const next = () => {
        const ordersToProcess = [];
        const orderShippingUpdates = {};
        csvRows.forEach(r => {
            const studySnap = referenceToStudySnaps[r.data.reference][0];
            const order = ownerToOrders[studySnap.aggregate.id][0];
            ordersToProcess.push(order);
            orderShippingUpdates[order.id] = {
                inbound: r.data["Inbound Tracking Number"],
                outbound: r.data["Outbound Tracking Number"],
            };
        });
        setLocState({
            ...locState,
            ordersToProcess,
            orderShippingUpdates,
        });
        goToShippingDetailsStep();
    };

    return (
        <>
            {isLoading && <Loading loading={isLoading} />}
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => history.goBack()}
            />
            <div className="fulfilment page order-landing">
                <h1 className="title"><Text>fulfilment.order.process.title</Text></h1>
                <section className="section">
                    <FlexBox sx={{ width: '80%', marginBottom: '30px' }}>
                        <SectionInfo label={i18n.resolve('fulfilment.order.process.importCSV.description')} />
                    </FlexBox>
                    <FileDrop.CSV id="csv" transformRow={validate} onComplete={onCSVUpload} annotation={<Text variables={{ "template-link": <a id='processOrdersTemplateLink' href={url} >{i18n.resolve("global.template-link")}</a> }} >fulfilment.order.process.importCSV.instructions</Text>} />
                    {!isValid && errors.length ? <div style={{ marginTop: "30px" }}>
                        <h3 style={{ marginBottom: '15px' }}>{i18n.resolve('Invalid input data')}</h3>
                        <span>{errors.map(e => JSON.stringify(e, null, 4))}</span>
                    </div> : ''}
                    {isValid ? <div style={{ marginTop: "30px" }}>
                        <span>{i18n.resolve('global.validCSV')}</span>
                    </div> : ''}
                </section>
                <FlexBox sx={{ ...flexRowJustifyItemsEnd, display: isValid ? 'inherit' : 'none' }}>
                    <Button
                        variant={Variant.Contained} color={Color.Secondary}
                        onClick={next}
                        sx={{ marginLeft: '8px' }}
                    >
                        <Text>next</Text>
                    </Button>
                </FlexBox>
            </div>
        </>
    );
};