import { useState, useEffect } from 'react';

import { TextField, Button, Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Loading from 'ui/components/Loading';

import useSnapshot from 'ui/hooks/useSnapshot';

import i18n from "services/server/functions/i18n";
import { Text } from "ui/components/Text";

import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";

import AdministrationConfig from "services/server/functions/model/administration/config";
import { Organisation, HealthcareSite } from "services/server/functions/model/administration/model";

const LoadingComponent = Loading.Default;
const ROOT_ORGA_ID = AdministrationConfig.organisation.root.id;

export const SetOwnersDialog = ({ userSnap, selectedOwners = [], setSelectedOwners, open = true, cancel = () => { }, done = () => { } }) => {
    const isRootOrgaOwner = userSnap.data.owners.some(urn => urn === ROOT_ORGA_ID);
    if (userSnap.data.owners.length === 1 && !isRootOrgaOwner) {
        setSelectedOwners(userSnap.data.owners);
        done();
        return <></>;
    }

    const [options, setOptions] = useState([]);
    
    const [orgaSnaps, loadingOrgas] = useSnapshot(Organisation);
    const [hcsSnaps, loadingHCSs] = useSnapshot(HealthcareSite);

    const isLoading = loadingOrgas || loadingHCSs || !userSnap;
    const disbleNextButton = selectedOwners.length === 0;

    useEffect(() => {
        let allOrgaSnaps = Object.values(orgaSnaps);
        if (!isRootOrgaOwner) allOrgaSnaps = allOrgaSnaps.filter(o => o.aggregate.id !== ROOT_ORGA_ID);
        let allHcsSnaps = Object.values(hcsSnaps);
        if (!isRootOrgaOwner) allHcsSnaps = allHcsSnaps.filter(h => !h.data.owners.includes(ROOT_ORGA_ID));

        setOptions([...allOrgaSnaps, ...allHcsSnaps].map((snap) => {
            return {
                id: snap.aggregate.id,
                aggregateName: snap.aggregate.name,
                name: snap.data.name,
            };
        }));
    }, [orgaSnaps, hcsSnaps]);

    useEffect(() => {
        // Set default option
        if (selectedOwners.length === 0 && isRootOrgaOwner) {
            setSelectedOwners([{
                id: ROOT_ORGA_ID,
                aggregateName: AdministrationConfig.organisation.name,
                name: AdministrationConfig.organisation.root.name,
            }]);
        }
    }, []);

    return <>
        {isLoading && <LoadingComponent loading={isLoading} />}

        <Dialog open={open}>
            <DialogTitle>{i18n.resolve("fulfilment.kit.create.setOwnersDialog.title")}</DialogTitle>
            <DialogContent>
                <div style={{ margin: "20px 10px" }}>
                    {isRootOrgaOwner ? <div style={{ marginBottom: "20px" }}>{i18n.resolve("fulfilment.kit.create.setOwnersDialog.isRootOrgaOwner")}</div> : ''}
                    <Autocomplete
                        id="kit-owners"
                        multiple
                        disableCloseOnSelect
                        options={options}
                        groupBy={(option) => option.aggregateName}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={selectedOwners}
                        renderInput={(params) => <TextField {...params} label="fulfilment.kit.owners" />}
                        getOptionLabel={(option) => option.name || option.label}
                        onChange={(event, newValue) => setSelectedOwners(newValue)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={Variant.Contained} color={Color.Secondary}
                    onClick={cancel}
                    sx={{ marginLeft: '8px' }}
                >
                    <Text>cancel</Text>
                </Button>
                <Button
                    disabled={disbleNextButton}
                    variant={Variant.Contained} color={Color.Secondary}
                    onClick={done}
                    sx={{ marginLeft: '8px' }}
                >
                    <Text>done</Text>
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};