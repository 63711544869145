import { useContext, useEffect, useState } from "react";
import { ThemeProvider } from '@mui/material/styles';
import Loading from "ui/components/Loading";

import { Text } from "ui/components/Text";
import { Table } from "../components/molecules/Table";
import history from 'history.js';

import { PageNavContext } from "ui/acukit/Page";

import { Kit } from 'services/server/functions/model/fulfilment/model';

import i18n from "services/server/functions/i18n";
import { fulfilmentTheme } from "../components/themes";

import '../components/themes/baseStyles.css';
import { getKitColumns } from "./tableConfig";
import { BackIcon } from "../components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "../components/atoms/LinkInfo";
import useSnapshot from "ui/hooks/useSnapshot";

export const KitPage = () => {
    const [selectedKits, setSelectedKits] = useState([]);

    const [kitsSnaps, loading] = useSnapshot(Kit);

    const data = Object.values(kitsSnaps || {}).map(s => {
        return {
            ...s.data,
            _status: s.data.status,
            status: i18n.resolve(`fulfilment.kit.status.${s.data.status}`),
        };
    });

    const pageNav = useContext(PageNavContext);

    useEffect(() => {
        pageNav.enterFullScreen();
    }, []);

    return <>
        {loading && <Loading loading={loading} />}
        <ThemeProvider theme={fulfilmentTheme}>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => history.goBack()}
            />
            <div className="fulfilment page">
                <h1 className="title"><Text>fulfilment.kit.title</Text></h1>
                <section className="section">
                    <Table
                        loading={loading}
                        columns={getKitColumns()}
                        rows={data}
                        getRowId={(m) => m.id}
                        rowSelectionModel={selectedKits}
                        disableColumnSelector={true}
                        onRowSelectionModelChange={setSelectedKits}
                    />
                </section>
            </div>
        </ThemeProvider>
    </>;
};
