import { useCallback } from 'react';

import { FlexBox } from 'features/fulfilment/components/atoms/Layout';
import { Tab, TabPanel } from './Tab';
import { flexCol } from 'features/fulfilment/components/atoms/Layout';
import { AppColors } from 'features/fulfilment/components/themes';

const containerStyles = {
    ...flexCol,
};

const labelStyles = {
    ...flexCol,
    marginBottom: '20px',
    '&::after': {
        content: '" "',
        background: AppColors.acuYellow,
        height: '2px',
        width: '100px',
        marginTop: '6px',
    }
};

export const Tabs = ({
    label,
    value = 0,
    handleChange,
    items,
    noTabsText,
    sx
}) => {
    const handleClick = useCallback((t) => {
        handleChange?.(t?.index)
    }, [handleChange]);

    const sections = items?.map(t =>
        <Tab
            index={t.index} key={t.label}
            label={t.label} value={value}
            onClick={() => handleClick(t)}
        />
    );

    return (
        <FlexBox sx={{ ...containerStyles, ...sx }}>
            <FlexBox sx={labelStyles}>{label}</FlexBox>
            {noTabsText ? noTabsText : <>
                <FlexBox>{sections}</FlexBox>
                {
                    items?.map(t => {
                        const Content = t.content;
                        return (
                            <TabPanel
                                key={t.index}
                                value={value} index={t.index}>
                                <Content />
                            </TabPanel>
                        )
                    })
                }
            </>}
        </FlexBox>
    );
};
