import { useState } from 'react';
import asyncComponent from 'ui/components/AsyncComponent';
import {Text} from 'ui/components/Text';

const Activation = asyncComponent(() => import(/* webpackChunkName: "OnboardingActivation" */  './Activation'));
const Controller = asyncComponent(() => import(/* webpackChunkName: "Onboarding" */  './Controller'));

const Onboarding = (props) => {
  require('./style.css');
  
  const [navItems, setNavItems] = useState(false);
  return (
    <div id="Onboarding">
      <div id="OnboardingNav" className={navItems.length > 0 ? 'asMenu' : 'asLanding'}>
        <div className={`logo ${navItems.length > 0 ? 'light' : 'dark'}`} />
        <div className="navItems grid-row">{(navItems || []).map((item, idx) => <div className={`item grid-col vcentered ${item.active ? 'active' : ''}`} key={`step${idx+1}`}>
            <div className="stepNum centered vcentered">{idx+1}<div className="total">/{navItems.length}</div></div><span className="stepLabel fitContent">{item.label}</span>
          </div>)}
        </div>
      </div>
      <div id="OnboardingContent">
        <Activation {...props} >
          <Controller setNavItems={setNavItems} {...props} />
        </Activation>
      </div>
    </div>
  );
}

const ThankYou = _ => {
  require('./style.css');
  
  return (
    <div id="Onboarding">
      <div id="OnboardingNav" className={'asLanding'}>
        <div className={`logo dark`} />
        <div className="navItems grid-row">
        </div>
      </div>
      <div id="OnboardingContent">
        <div className="page">
          <div className="pageHeader"><h1 className="title"><Text>module.diagnosis.fe.view.onboarding.done.thanks_message</Text></h1></div>
          <div className="pageContent grid-row">
            <p><Text>module.diagnosis.fe.view.onboarding.done.closure_message</Text></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  Onboarding,
  ThankYou
};