import 'ui/theme/form.css';

import { EditStudy, ViewStudy } from 'modules/diagnosis';
import { useEffect, useState, useContext } from 'react';

import DetailsBlock from 'ui/components/DetailsBlock';
import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Text } from 'ui/components/Text';
import history from 'history.js';
import moment from 'moment';
import { monotonicFactory } from 'ulid';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useEffectOnMount from 'ui/hooks/useEffectOnMount';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import { Form } from 'ui/components/CustomField';
import { useHcs } from 'features/hooks/useHcs';
import { StudySettings } from 'features/diagnosis/components/StudySettings';
import { PatientBaseForm } from 'features/diagnosis/Study/Patient/components/forms/PatientBaseForm';
import { PatientHcsForm } from 'features/diagnosis/Study/Patient/components/forms/PatientHcsForm';
import { PatientTrialForm } from 'features/diagnosis/Study/Patient/components/forms/PatientTrialForm';
import { prepareCreateStudyFormData } from 'features/diagnosis/helpers/studyTransformHelper';
import { getStudyvalidationSchema } from 'features/diagnosis/helpers/validationSchemasHelper';
import { ConfirmDuplicityStudyModal, ConfirmLeaveStudyModal, UnmatchStudyDetailsModal } from 'features/diagnosis/components/StudyModals';
import { UserContext } from 'features/providers/userContextProvider';
import ProductStudy from 'services/server/functions/model/administration/model/ProductStudy';
import useProjection from 'ui/hooks/useProjection';
import { Patient } from 'services/server/functions/model/diagnosis/model/Patient';

const ulid = monotonicFactory();
const BIRTH_DATE_FORMAT = "DD/MM/YYYY";

const FormUI = ({
  formRef, onModifyStateToPersist,
  loadingStudy,
  study,
  patientIDConfirmed,
  patientBirthConfirmed,
  clinicianOptions = [], loadingClinicians,
  siteOptions = [], loadingSites,
  trialOptions, loadingTrials,
  isConfirm, patientMissmatch, closeMissmatchError, formSaved, onChange, onValidate, isEditPage, confirmDuplicity, confirmLeave, onConfirm, onRegret, hasAccess, validationSchema, locState }) => {
  const { patientID, patientBirth, clinician, site, trial, profile, id } = study;
  const settingsOwner = isEditPage ? id : site;
  const studySettingsOwner = locState?.clone ? locState.clone : settingsOwner;
  const isRepeat = Boolean(locState?.clone && locState.clone === studySettingsOwner);

  return (
    <>
      <PatientBaseForm
        isConfirm={isConfirm}
        formSaved={formSaved}
        onValidate={onValidate}
        onChange={onChange}
        patientID={patientID}
        patientIDConfirmed={patientIDConfirmed}
        patientBirth={patientBirth}
        patientBirthConfirmed={patientBirthConfirmed}
        validationSchema={validationSchema}
      />
      {
        !isConfirm && <>
          {profile?.length !== 0 && <div className='pageSection'>
            <div className='title'><Text>patient-profile</Text></div>
            <DetailsBlock>
              <Form
                name="profile"
                form={profile}
                onChange={onChange}
                onValidate={onValidate}
                showErrors={formSaved}
              />
            </DetailsBlock>
          </div>}
          <PatientHcsForm
            siteOptions={siteOptions}
            loadingSites={loadingSites}
            isEditPage={isEditPage}
            site={site}
            onChange={onChange}
            onValidate={onValidate}
            formSaved={formSaved}
            validationSchema={validationSchema}
            clinicianOptions={clinicianOptions}
            loadingClinicians={loadingClinicians}
            hasAccess={hasAccess}
            clinician={clinician}
          />
          <PatientTrialForm
            trialOptions={trialOptions}
            loadingTrials={loadingTrials}
            hasAccess={hasAccess}
            isEditPage={isEditPage}
            trial={trial}
            formSaved={formSaved}
            onChange={onChange}
            onValidate={onValidate}
          />
          {!loadingStudy && !loadingSites && Study.STATUS.compare(study.status, Study.STATUS.finished) < 0 && studySettingsOwner && <StudySettings formRef={formRef} ownerId={studySettingsOwner} skipReadOnly={isRepeat} onModifyStateToPersist={onModifyStateToPersist} />}
        </>
      }
      <UnmatchStudyDetailsModal show={patientMissmatch}
        onClose={closeMissmatchError} />
      <ConfirmLeaveStudyModal show={confirmLeave} onConfirm={onConfirm} onRegret={onRegret} />
      <ConfirmDuplicityStudyModal show={confirmDuplicity}
        onClose={onRegret} onConfirm={onConfirm} />
    </>
  );
};

const onModifyStateToPersist = ProductStudy.schemas.StudyConfigSettings.onModifyStateToPersist(ProductStudy.schemas.StudyConfigSettings.getSpecialFields(true));

const PatientDetailsPresenter = (props) => {
  const isEditPage = EditStudy.PATH === window.location.pathname; // dodgy :-(
  const [locState, setLocationState] = useLocationParams({step: undefined, edited: undefined, patientIDConfirmed: undefined, patientBirthConfirmed: undefined});
  const [currentUser] = useCurrentUser();
  const [patientMissmatch, setPatientMissmatch] = useState(false);
  const [confirmDuplicity, setConfirmDuplicity] = useState(false);
  const [confirmLeave, setConfirmLeave]         = useState(false);
  const [formSaved, setFormSaved]               = useState(false);
  const [errors, setErrors]                     = useState({});
  
  useEffect(() => {
    // point the user to the next error to fix
    formSaved && document.querySelector('.failed')?.closest('.detailsBlock, .pageSection')?.scrollIntoView();
  }, [formSaved, Object.keys(errors).length])

  let {
    edited,
    patientIDConfirmed, 
    patientBirthConfirmed,
    step
  } = locState
  
  let {
    setOnBack,
    setOnNext, 
    goBack=() => {},
    goNext=() => {},
    onSubmit,
    formRef
  } = props;
  
  const studyRef   = locState.clone ? props.cloneData : (props.study?.data || {status: undefined, id: locState.study, clinician: undefined, site: undefined, requestedTests: undefined, freezeTestCancelledStatus: undefined, showAllSleepPositions: undefined, patient: {instructions: {}}});
  const loadingRef = locState.clone ? props.cloneData === undefined : props.loading;
  const origChargePatient = studyRef?.patient?.instructions?.chargePatient;

  let patientBirth = locState.patientBirth;
  if (!patientBirth && studyRef?.patient.birthDate) {
    patientBirth = moment(studyRef?.patient.birthDate, BIRTH_DATE_FORMAT).toDate();
  }

  const [patientForm, setPatientForm] = useState();
  const loadStudy = _ => ({
    id                 : locState.id || (isEditPage ? locState.study : Study.newURN(ulid())),
    date               : isEditPage ? studyRef?.date : locState.date || moment().toISOString(true),
    patientID          : 'patientID' in locState ? locState.patientID : studyRef?.patient.id,
    patientBirth, // TODO: need to store dates without time in model using simple format
    profile            : locState.profile || studyRef?.patient.profile || (isEditPage ? [] : patientForm),
    site               : edited ? locState.site : (studyRef?.site || (currentUser?.hasPermission(Study.roles.CREATE_STUDY.id) && HealthcareSite.ownersFrom(currentUser?.data)[0])),
    organisation       : edited ? locState.organisation : (studyRef?.organisation || (currentUser?.hasPermission(Study.roles.CREATE_STUDY.id) && Organisation.ownersFrom(currentUser?.data)[0])),
    trial              : edited ? locState.trial : Organisation.entities.Trial.ownersFrom(studyRef)[0],
    clinician          : edited ? locState.clinician : (studyRef?.clinician || (currentUser?.hasPermission(Study.roles.CREATE_STUDY.id) && currentUser?.data.id)),
    status             : locState.clone ? Study.STATUS.created : studyRef?.status,
    tests              : studyRef?.tests || {}
  });
  const study = loadStudy();
  
  // Potential duplicate studies
  const [studies = {}, _loadingStudies] = useSnapshot(Study, { listenToChanges: false, autoStart: Boolean(study.patientID), filters: [ ['patient.id', '==', study.patientID] ] }); // , ['patient.birthDate', '==', moment(study.patientBirth).format(BIRTH_DATE_FORMAT) 
  // somehow we need to wait for the fetch ???

  const {
    loadingSites, siteOptions, loadingClinicians, clinicianOptions, clinician
  } = useHcs({ currentUser, study, edited, isEditPage });
  const contextState = useContext(UserContext);
  const product = contextState.selectedProduct;

  useEffect(() => {
    if (!locState.site) {
      setLocationState({ ...locState, site: study.site });
    }
  }, [study.site]);

  const [patientProfileForm, loadingProfilePrefs] = useProjection(Patient.queries.GET_PATIENT_PROFILE_FORM.newRequest({ site: study.site }), { autoStart: (study.site && !isEditPage) || false, update: study.site });

  useEffect(_ => {
    if (!isEditPage && !loadingProfilePrefs && study.site) {
      setPatientForm(patientProfileForm);
    } else if (!loadingProfilePrefs || !study.site) {
      setPatientForm([]);
    }
  }, [patientProfileForm, loadingProfilePrefs]);
  
  const [trialSnaps, loadingTrials] = useSnapshot(Organisation.entities.Trial);

  useEffect(_ => {
    if (!locState.id && !loadingRef) {
      // for some reason, at this point, study !== loadStudy() (SAW-1796 clinician field in repeat UC is set at this point to current user if it is a valid clinician instead of the cloned clinician value)
      setLocationState({...loadStudy()}); // Set initial creation state in location state
    }
  }, [locState.id, JSON.stringify(study), loadingRef]);

  useEffectOnMount(_ => {
    // Handle browser's back button on patient details steps
    if (!isEditPage && props.study?.data) history.replace(ViewStudy.PATH, {study: locState.id});
  });

  const onChange = (event) => {
    const value = event.target.type === 'select-multiple' ? event.target.selectedOptions?.map(o => o.value) || [] : event.target.value;

    const field = event.target.name;
    let changes = { [field]: value };
    if (field === 'site' && value !== locState[field]) changes.clinician = undefined;
    
    if (locState[field] !== value) {
      setLocationState({ edited: !props.loading, ...changes });
    }
  };
  
  const onValidate = (field, error) => setErrors(prevErrors => {
    if (error) return prevErrors[field] === error ? prevErrors : { ...prevErrors, [field]: error };
    delete prevErrors[field];
    return prevErrors;
  });

  const closeModals = () => { 
    setPatientMissmatch(false); 
    setConfirmLeave(false); 
    setConfirmDuplicity(false); 
    return true;
  };

  const isConfirm = (step === "validate") || false;
  const validationSchema = getStudyvalidationSchema({ currentUser, isConfirm, isEdit: isEditPage });

  // TODO: ask confirmation to leave when user tries to leave this page in general (not only by pressing back buttons of this component): onComponenWillUnmount or history.block?
  setOnBack(isConfirm ? () => { setErrors({}); return true; }
                      : () => !edited || (confirmLeave && closeModals()) || (setConfirmLeave(true) && false));
  setOnNext(isConfirm ? () => {
    // TODO: validate with validation component and show modal if any field is wrong as usual
    // One is null and one is undefined so that if they don't have a value the strict equality will return false
    const studyPatientBirthFormat = study.patientBirth ? moment(study.patientBirth).format(BIRTH_DATE_FORMAT) : null;
    const patientBirthConfirmedFormat = patientBirthConfirmed ? moment(patientBirthConfirmed).format(BIRTH_DATE_FORMAT) : undefined;
    const patientNoMatch = studyPatientBirthFormat !== patientBirthConfirmedFormat || study.patientID.toUpperCase().trim() !== patientIDConfirmed.toUpperCase().trim();
    setPatientMissmatch(patientNoMatch);
    if (patientNoMatch) return false;
    const askConfirmation = !confirmDuplicity && Object.values(studies)
      .find(s => s.data.id !== study.id
              && s.data.patient.id === study.patientID 
              && s.data.patient.birthDate === studyPatientBirthFormat 
              && Study.STATUS.compare(s.data.status, Study.STATUS.in_progress) < 0
      ) !== undefined;
    setConfirmDuplicity(askConfirmation);
    
    return !askConfirmation && onSubmit(prepareCreateStudyFormData({
      persistedState: locState, study, product, isEditPage
    })).catch(e => {
      props.notify("error", Text.resolve(e.reason || e?.data.reason), Text.resolve(e.resolution || e?.data.resolution));
      return Promise.reject(e);
    }).then(_ => true);
  }
  : () => {
    setFormSaved(true);
    if (loadingProfilePrefs) {
      props.notify("warning", Text.resolve("CreateStudy.notify.profile-not-loaded.title"), Text.resolve("CreateStudy.notify.profile-not-loaded.content"))
      return false;
    }
    
    return !errors || Object.values(errors).every(error => !error);
  });

  const onConfirm = () => confirmLeave ? goBack() : confirmDuplicity ? goNext() : undefined;
  const onRegret  = closeModals
  return FormUI({...locState, loadingStudy: props.loading, study, origChargePatient, patientMissmatch, confirmDuplicity, confirmLeave, closeMissmatchError: () => setPatientMissmatch(false), onConfirm, onRegret, clinicianOptions, loadingClinicians, siteOptions, loadingSites, trialOptions: trialSnaps ? Organisation.entities.Trial.ownersFrom(clinician).map(t => ({label: trialSnaps[t]?.data.name, value: t})) : [], loadingTrials, isConfirm, formSaved, onChange, onValidate, isEditPage, hasAccess: currentUser.hasAccess, validationSchema, formRef, onModifyStateToPersist, locState })
}

export default PatientDetailsPresenter;