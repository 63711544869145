import { Button } from "@mui/material";
import { Variant } from "../components/constants/variant";
import { Size } from "../components/constants/size";
import { Color } from "../components/constants/color";

export const getOrderSelectedActions = () => {
    return (
        <div className="selected-orders-icons">
            <Button
                variant={Variant.Contained}
                size={Size.Small}
                color={Color.Secondary}
            >
                label
            </Button>
        </div>
    );
};

const saveAllCourierLabels = ({ originalOrders, allOrders, orders, setCourierLabelsBatch, setInputShippingDetailsRows }) => {
    const onProgress = (result, row) => {
        const isInvalid = result.status !== 'fulfilled';
        if (isInvalid) {
            return console.error({ row, type: result.reason?.data?.code, message: result.reason?.data?.details });
        }
        const originalOrder = originalOrders.find(o => o.id === orders[row].id);
        const order = allOrders.find(o => o.id === orders[row].id);
        // Workaround for not having subscriptions (NOTE: both are always being set)
        originalOrder.shipping = { inbound: order.shipping.inbound, outbound: order.shipping.outbound };
        order.edited = false;
        setInputShippingDetailsRows(allOrders.map(o => o));
    };

    return setCourierLabelsBatch(orders, onProgress);
};

export const getProcessOrderActions = ({ originalOrders, setCourierLabelsBatch, setInputShippingDetailsRows }) => ({ rows }) => {
    return (
        <div className="selected-orders-icons">
            <Button
                variant={Variant.Contained}
                size={Size.Small}
                color={Color.Secondary}
                sx={{ width: "max-content" }}
                onClick={() => saveAllCourierLabels({ originalOrders, allOrders: rows, orders: rows.filter(o => o.edited), setCourierLabelsBatch, setInputShippingDetailsRows })}
                disabled={rows.every(r => !r.edited)}
            >
                Save All
            </Button>
        </div>
    );
};
