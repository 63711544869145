
import { GridLogicOperator } from "@mui/x-data-grid";
import { Button } from "@mui/material";

import i18n from "services/server/functions/i18n";
import history from 'history.js';

import * as Pages from '../index';

import { getBaseColumnsLayer } from "features/fulfilment/components/molecules/Table/uiLayers";
import { Text } from "ui/components/Text";

import { ViewOrderIcon } from "../components/atoms/icons/fulfilmentIcons";
import { FlexBox, flexCol } from "../components/atoms/Layout";
import { InfoBoxLabel } from "features/fulfilment/components/atoms/InfoBoxLabel";

export const getOrderActions = () => (params) => [
    <Button key={`${params?.id}_gridrow`}>
        <FlexBox
            sx={{
                ...flexCol, alignItems: 'center',
                fontSize: '12px', height: '48px'
            }}
            onClick={() => history.push(Pages.ViewOrder.PATH, { order: params?.row.id })} >
            <Text>view</Text>
            <ViewOrderIcon />
        </FlexBox>
    </Button>
];

export const getOrderColumns = () => getBaseColumnsLayer([
    {
        field: 'date',
        headerName: i18n.resolve('date'),
        renderCell: (cellProps) => cellProps.value ? new Date(cellProps.value).toLocaleDateString() : '',
    },
    {
        field: 'reference',
        headerName: i18n.resolve('fulfilment.order.ref'),
    },
    {
        field: 'client',
        headerName: i18n.resolve('global.client'),
        renderCell: (cellProps) => cellProps.row.hcs.name,
    },
    {
        field: 'type',
        headerName: i18n.resolve('global.type'),
        renderCell: (cellProps) => <div style={{ display: "flex", alignItems: "center", gap: "5px" }}><InfoBoxLabel label={cellProps.row.template?.name} bgColor={cellProps.row.template?.hexColorCode} /><span>{cellProps.row.kit ? '📦' : ''}</span></div>,
    },
    {
        field: 'status',
        headerName: i18n.resolve('fulfilment.order.status'),
    },
    {
        field: 'actions',
        headerName: i18n.resolve('global.actions'),
        sortable: false,
        type: 'actions',
        getActions: getOrderActions(),
    },
]);

export const prepareOrderTableState = ({ locState }) => {
    const hasCustomFilters = Array.isArray(locState?.filters);

    return ({
        sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
        },
        filter: {
            filterModel: {
                logicOperator: GridLogicOperator.Or,
                items: hasCustomFilters ? locState?.filters : []
            }
        },
        columns: {
            columnVisibilityModel: {
                id: false,
            },
        },
    });
};
