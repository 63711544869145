import * as R from "ramda";
import { SchemaExtractor } from '../../../validation/schemaExtractor';
import { permissionURN } from '../../../executor/urn';
import { Joi } from '../../../validation/rules';
import { getProperty, setProperty, flattenObject } from '../../../helpers/utils';
import { AttributeRights } from "../../../iam/roles";
import { isSuperAdmin } from "../../../iam";

export const SETTINGS_DEFAULT_VALUES = ["Empty", "Yes", "No"];

const SETTING_VALUE_TO_RIGHTS = {
    Empty: ["read", "write"],
    Yes: [], // ["read"],
    No: [],
};

export const mapSettingsToAttributeRights = (settings = {}, context, name) => {
    const accessRights = [];
    Object.keys(settings).forEach(key => {
        const value = settings[key];
        const rights = SETTING_VALUE_TO_RIGHTS[value];
        rights.forEach(right => {
            accessRights.push(permissionURN(`${key}/${right}`, context, name));
        });
    });
    return accessRights;
}

export const getField = (accessRight) => {
    const arr = accessRight.split("/");
    return AttributeRights.from(arr[arr.length - 2]);
}

// element = dataFrom(accessRight)
export const mapDataToSettings = (elements) => {
    const keyToElements = R.groupBy((data) => getField(data.id), elements);
    const settings = Object.keys(keyToElements).reduce((acc, key) => {
        acc[key] = keyToElements[key].length === 1 ? "Yes" : "Empty";
        return acc;
    }, {});
    return settings;
};

export const getSchemaFields = (schema, ...formIds) => {
    const filter = (meta) => formIds.includes(meta?.preferences?.id);
    const jsonSchema = SchemaExtractor.JoiSchemaDescriptor.describe(schema);
    const fields = jsonSchema.fields.flatten().withMeta(filter);
    return fields;
};

export const generateAttributeRightsSettingsSchemaKeys = (schema, group) => {
    const fields = getSchemaFields(schema, group).map(f => f.field);
    const keys = fields.reduce((acc, curr) => {
        acc[curr] = Joi.string().valid(...SETTINGS_DEFAULT_VALUES).meta({ preferences: { id: "AttributeRights", group } });
        return acc;
    }, {});
    return keys;
};

export const ReportSettings = {
    schema: Joi.object().keys({
        sleep_report_format: Joi.string().meta({ preferences: { id: "ReportSettings", group: "ReportSettings" } }),
    }),
};

export const TemplateSettings = {
    schema: Joi.object().keys({
        clinicianTemplate: Joi.string().meta({ preferences: { id: "TemplateSettings", group: "TemplateSettings" } }),
    }),
};

export const filterByValidFields = (data, validFields) => {
    const flattennedData = flattenObject(data);
    const validData = Object.keys(flattennedData).reduce((acc, curr) => {
        if (validFields.includes(curr)) setProperty(acc, curr, flattennedData[curr]);
        return acc;
    }, {});
    return validData;
}

export const filterWithoutFields = (attributeRights = [], fields) => {
    return attributeRights.filter(ar => !fields.includes(getField(ar)));
};

const filterByRegex = (strings, regexArr) => {
    const regex = new RegExp(regexArr.join("|"));
    return strings.filter(str => regex.test(AttributeRights.to(str)));
}

export const asRegex = (key, isObject = false) => {
    key = AttributeRights.to(key);
    if (isObject) return `^${key}\\.+`;
    return `^${key}$`;
}

export const getAttributeRightsByAndForFields = (attributeRights = [], fields) => {
    const fieldToAttributeRights = fields.reduce((acc, curr) => {
        const regex = new RegExp(`/${AttributeRights.to(curr)}/(read|write)$`);
        acc[curr] = attributeRights.filter(ar => regex.test(ar));
        return acc;
    }, {});
    return fieldToAttributeRights;
};

const isReadOnlyField = (attributeRights = [], field, { context, name }) => {
    field = AttributeRights.to(field);
    const readURN = AttributeRights.urn(field + "/read", { context, name });
    const writeURN = AttributeRights.urn(field + "/write", { context, name });
    const isReadOnly = attributeRights.some(ar => ar === readURN) && attributeRights.every(ar => ar !== writeURN);
    return isReadOnly;
};

const isWriteField = (attributeRights = [], field, { context, name }) => {
    field = AttributeRights.to(field);
    const writeURN = AttributeRights.urn(field + "/write", { context, name });
    const isWrite = attributeRights.some(ar => ar === writeURN);
    return isWrite;
};

// Assumes data is flatten, otherwise it only iterates over the first level of keys
const getFieldsInData = (attributeRights = [], data, { context, name }, comparator) => {
    const fields = Object.keys(data).reduce((acc, field) => {
        const satisfies = comparator(attributeRights, field, { context, name });
        if (satisfies) acc.push(field);
        return acc;
    }, []);
    return fields;
};

// Assumes data is flatten, otherwise it only iterates over the first level of keys
const getFieldValues = (attributeRights = [], data, { context, name }, comparator) => {
    const fields = getFieldsInData(attributeRights, data, { context, name }, comparator);
    return fields.reduce((acc, field) => {
        acc[field] = data[field];
        return acc;
    }, {});
};

// Assumes data is flatten, otherwise it only iterates over the first level of keys
export const getReadOnlyValues = (attributeRights = [], data, { context, name }) => {
    return getFieldValues(attributeRights, data, { context, name }, isReadOnlyField);
};

// Assumes data is flatten, otherwise it only iterates over the first level of keys
export const getWriteValues = (attributeRights = [], data, { context, name }) => {
    return getFieldValues(attributeRights, data, { context, name }, isWriteField);
};

export const isRead = (accessRight) => accessRight.endsWith("/read");
const isWrite = (accessRight) => accessRight.endsWith("/write");

// allowedData is optional, and consist of an object with the readOnly values that are allowed
export const validate = (data, fieldsWithPermissions, attributeRights = [], allowedData, forceAllowedData) => {
    const writeRights = attributeRights.filter(isWrite);
    const writeRegexes = writeRights.map(getField);
    const flattenedData = flattenObject(data);
    const fieldsRegexes = Object.values(fieldsWithPermissions).map(f => f.regex);
    const flattenedFieldsData = filterByRegex(Object.keys(flattenedData), fieldsRegexes).reduce((acc, k) => {
        acc[k] = flattenedData[k];
        return acc;
    }, {});
    Object.keys(flattenedFieldsData).forEach(fieldKey => {
        const hasRight = writeRegexes.some(ar => new RegExp(ar).test(AttributeRights.to(fieldKey)));
        const allowedDataValue = getProperty(allowedData, fieldKey);
        const allowedDataIsSet = allowedDataValue !== undefined && allowedDataValue !== null;
        if (((forceAllowedData && allowedDataIsSet) || !hasRight) && allowedDataValue !== flattenedFieldsData[fieldKey]) {
            throw new Error(`Missing write access to ${fieldKey}`);
        }
    })
}

// Cache of all attribute rights
let allAttributeRightsIds;
const allAttributeRights = () => {
    allAttributeRightsIds ??= AttributeRights.all().map(ar => ar.id);
    return allAttributeRightsIds;
}

// Returns an array of objects, not urns
export const getUserAttributeRights = (userSnap, productSnap, forceIntersection) => {
    if (!forceIntersection && isSuperAdmin(userSnap)) {
        return allAttributeRights();
    }
    // TODO: add intersection once users have attributeRights
    return productSnap.data.attributeRights;
};

export const getAllowedFieldsForAttributeRights = (attributeRights = [], fields) => {
    const fieldToAttributeRights = getAttributeRightsByAndForFields(attributeRights, fields);
    const allowedFields = Object.keys(fieldToAttributeRights).filter(field => fieldToAttributeRights[field].length);
    return allowedFields;
};