
import { Joi } from '../../../validation/rules';

import Config from '../config';
import { VALID_DATA_POLICY, runAlreadyExistsPolicy, runNotExistsPolicy, when } from '../../policies';
import { withDefaults } from '../..';
import Comment from '../../system/model/Comment';
import { orderAssignKitPolicies, isOrderUnique, markOrderAsValidatedPolicies } from './policies';
import { hash } from '../../../helpers/crypto';
import { findLast } from '../../../helpers/utils';

const STATUS = {
    CREATED: "CREATED",
    READY: "READY",
    HANDED: "HANDED",
    WAITING: "WAITING",
    RETURNED: "RETURNED",
    INCOMPLETE: "INCOMPLETE",
    CANCELLED: "CANCELLED",
    VALIDATED: "VALIDATED",
};
const STATUSES = Object.values(STATUS);

const STATUS_GROUP = {
    PENDING: "PENDING",
    IN_PROGRESS: "IN_PROGRESS",
    RETURNED: "RETURNED",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
    INCOMPLETE: "INCOMPLETE",
};
const STATUS_TO_STATUS_GROUP_MAP = {
    [STATUS.CREATED]: STATUS_GROUP.PENDING,
    [STATUS.READY]: STATUS_GROUP.PENDING,
    [STATUS.HANDED]: STATUS_GROUP.IN_PROGRESS,
    [STATUS.WAITING]: STATUS_GROUP.IN_PROGRESS,
    [STATUS.RETURNED]: STATUS_GROUP.RETURNED,
    [STATUS.INCOMPLETE]: STATUS_GROUP.INCOMPLETE,
    [STATUS.CANCELLED]: STATUS_GROUP.CANCELLED,
    [STATUS.VALIDATED]: STATUS_GROUP.COMPLETED,
};

const fillOrder = (orderSnap) => {
    orderSnap.data.generalStatus = STATUS_TO_STATUS_GROUP_MAP[orderSnap.data.status];
    return orderSnap;
};

const generateOrderReference = (orderId) => {
    const ref = hash(orderId, 8);
    const chunks = ref.match(/.{1,4}/g); // splits the hashed value into 2 chunks of 4 characters each
    return `O-${chunks[0]}-${chunks[1]}`.toUpperCase();
};

const isAOrderReference = (orderReference) => {
    const regex = /^O-\w{4}-\w{4}$/;
    return regex.test(orderReference);
};

const lastEventUpdate = (snapEvents, eventFilters) => findLast(snapEvents, e => !eventFilters || eventFilters.some(type => type.isInstance(e.id)))?.timestamp;

let Order = {
    context: Config.context,
    name: 'Order',
    STATUS,
    STATUS_GROUP,
    STATUS_TO_STATUS_GROUP_MAP,
    fillOrder,
    generateOrderReference,
    isAOrderReference,
    lastEventUpdate,
    // owners[0] is a reference of Study
    schema: Joi.object().keys({
        reference: Joi.string(),
        // Currently always Acurable UK/EU/US
        // sender: Joi.object().keys({
        //     addressLine1: Joi.string(),
        //     addressLine2: Joi.string(),
        //     postCode: Joi.string(),
        //     city: Joi.string(),
        //     country: Joi.string(),
        //     phone: Joi.string(),
        //     name: Joi.string(),
        // }),
        // recipient: Joi.object().keys({
        //     addressLine1: Joi.string(),
        //     addressLine2: Joi.string(),
        //     postCode: Joi.string(),
        //     city: Joi.string(),
        //     country: Joi.string(),
        //     phone: Joi.string(),
        //     email: mail,
        //     name: Joi.string(),
        // }),
        shipping: Joi.object().keys({
            outbound: Joi.string(),
            inbound: Joi.string(),
        }),
        kit: Joi.string().uri(), // reference of Kit
        status: Joi.string().valid(...STATUSES),
    }),
    entities: {
        get Comment() {
            delete this.Comment;
            this.Comment = Comment(Order);
            return this.Comment;
        },
    },
    snapshot: (event, prevOrder) => {
        const prevOrderSnap = prevOrder || { data: {}, metadata: {} };

        const changes = { data: {} };

        let status = prevOrderSnap.data.status;
        if (status === STATUS.CREATED && event.data.status !== STATUS.HANDED) {
            const hasRecipientData = true; // Object.values(prevOrder.data.recipient || event.data.recipient || {}).length > 6; // can be improved
            const hasKit = event.data.kit || prevOrder.data.kit;
            if (hasRecipientData && hasKit) {
                changes.data.status = STATUS.READY;
            }
        }

        return changes;
    },
    events: {
        ORDER_CREATED: {},
        SHIPPING_DETAILS_UPDATED: {},
        ORDER_PROCESSED: {},
        MARKED_AS_CANCELLED: {},
        COURIER_LABELS_SET: {},
        MARKED_AS_LOST: {},
        MARKED_AS_VALIDATED: {},
    },
    commands: {
        CREATE_ORDER: {
            // TODO: should it also validate the status of the Study?
            checkPolicies: async (requestData, existingOrder, executor) => await Promise.all([
                runAlreadyExistsPolicy(requestData, existingOrder),
                isOrderUnique(executor, requestData.owner),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id'),
                    owner: Joi.string().uri().required(), // reference of Study
                    // sender: Order.schema.extract('sender').required(), // TODO: make all keys required
                    // recipient: Order.schema.extract('recipient'),
                });
            },
            event: (action, _, data) => {
                const event = Order.events.ORDER_CREATED.new({
                    ...action.data,
                    ...data,
                    status: STATUS.CREATED
                });
                return event;
            },
        },
        // UPDATE_SHIPPING_DETAILS: {
        //     checkPolicies: async (requestData, existingOrder, executor) => await Promise.all([
        //         runNotExistsPolicy(requestData, existingOrder),
        //         when(existingOrder?.data?.status !== STATUS.CREATED).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.CREATED} status`)),
        //         updateShippingDetailsPolicies(executor, requestData, existingOrder),
        //     ]),
        //     get schema() {
        //         return Joi.object().keys({
        //             id: Order.schema.extract('id').required(),
        //             recipient: Order.schema.extract('recipient').required(), // TODO: make all keys required
        //         });
        //     },
        //     get event() { return Order.events.SHIPPING_DETAILS_UPDATED; },
        // },
        PROCESS_ORDER: {
            checkPolicies: async (requestData, existingOrder, executor) => await Promise.all([
                orderAssignKitPolicies(executor, requestData, existingOrder),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id'),
                    reference: Order.schema.extract('reference'), // order reference
                    kit: Order.schema.extract('kit'), // reference of Kit
                    kitReference: Order.schema.extract('reference'), // kit reference
                    devices: Joi.array().items(Joi.string()).default([]), // Ids of Devices
                }).or('id', 'reference').or('kit', 'kitReference');
            },
            event: (action, _, data) => {
                return Order.events.ORDER_PROCESSED.new(data);
            },
        },
        MARK_AS_CANCELLED: {
            checkPolicies: async (requestData, existingOrder) => await Promise.all([
                runNotExistsPolicy(requestData, existingOrder),
                when(![STATUS.CREATED, STATUS.READY].some(s => existingOrder?.data?.status === s)).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.CREATED} or ${STATUS.READY} status`)),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id').required(),
                });
            },
            event: (action) => {
                return Order.events.MARKED_AS_CANCELLED.new({ ...action.data, status: STATUS.CANCELLED, });
            },
        },
        SET_COURIER_LABELS: {
            checkPolicies: async (requestData, existingOrder) => await Promise.all([
                runNotExistsPolicy(requestData, existingOrder),
                when(![STATUS.CREATED, STATUS.READY].some(s => existingOrder?.data?.status === s)).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.CREATED} status`)),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id').required(),
                    shipping: Order.schema.extract('shipping').required(), // TODO: make all keys required
                }).alter({
                    csv: schema => {
                        // reference => study reference
                        const importFields = ['reference', 'Inbound Tracking Number', 'Outbound Tracking Number'];

                        return schema
                            .keys({
                                reference: Order.schema.extract('reference').required(),
                                'Inbound Tracking Number': Order.schema.extract('shipping.inbound').required(),
                                'Outbound Tracking Number': Order.schema.extract('shipping.outbound').required(),
                            })
                            .alter({
                                import: S => S.prefs({ abortEarly: false, noDefaults: true })
                                    .fork(['id', 'shipping'], s => s.optional())
                                    .fork(importFields, s => s.meta({ csv: { header: true } })),
                            })
                    },
                });
            },
            get event() { return Order.events.COURIER_LABELS_SET; },
        },
        MARK_AS_LOST: {
            checkPolicies: async (requestData, existingOrder) => await Promise.all([
                runNotExistsPolicy(requestData, existingOrder),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id').required(),
                });
            },
            event: (action) => {
                return Order.events.MARKED_AS_LOST.new({ ...action.data, status: STATUS.INCOMPLETE, });
            },
        },
        MARK_AS_VALIDATED: {
            checkPolicies: async (requestData, existingOrder, executor) => await Promise.all([
                runNotExistsPolicy(requestData, existingOrder),
                when(existingOrder?.data?.status !== STATUS.HANDED).rejectWith(VALID_DATA_POLICY(`This operation is only possible from the ${STATUS.HANDED} status`)),
                markOrderAsValidatedPolicies(executor, existingOrder),
            ]),
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id').required(),
                });
            },
            event: (action) => {
                return Order.events.MARKED_AS_VALIDATED.new({ ...action.data, status: STATUS.VALIDATED, });
            },
        },
    },
    queries: {
        VIEW_ORDER: {
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id').required(),
                });
            },
        },
        FIND_ORDER: {
            get schema() {
                return Joi.object().keys({
                    statuses: Joi.array().items(Joi.string().valid(...Object.values(STATUS_GROUP))),
                    reference: Joi.string().empty(''),
                });
            },
            newRequest: (args, metadata) => ({
                action: Order.queries.FIND_ORDER.new(args, metadata),
                depends: [], // NECESSARY! Otherwise it sends the handler all the snapshots!
            }),
        },
        GET_LABEL: {
            get schema() {
                return Joi.object().keys({
                    id: Order.schema.extract('id').required(),
                    format: Joi.string().valid('png', 'pdf').default('pdf'),
                });
            },
            newRequest: (args, metadata) => ({
                action: Order.queries.GET_LABEL.new(args, metadata),
                depends: [], // NECESSARY! Otherwise it sends the handler all the snapshots!
            }),
        },
    },
    reports: {
        labels: {
            type: 'isPDF',
            query: args => Order.queries.GET_LABEL.newRequest(args),
        },
    },
};
Order = withDefaults()(Order);

export default Order;