import { useState, useCallback, useEffect } from 'react';
import { deepEquals } from '@rjsf/utils';
import { objHasAllDefinedValues } from 'features/helpers/objectHelper';
import { combineSchemaFormDefaultsAndData } from '../utils/formUtils';

export const useSchemaFormHandler = ({ id, data, onChange, onModifyStateToPersist = (v) => v,
    onClickAction, properties, onError, cleanable }) => {
    const [status, setStatus] = useState({
        pristine: true,
        allDefinedValues: false,
    });
    const combinedData = combineSchemaFormDefaultsAndData(properties, data);
    const [formData, setFormData] = useState(() => combinedData);
    const handleChange = useCallback((value) => {
        const newFormData = combineSchemaFormDefaultsAndData(properties, value?.formData);
        const isEquals = deepEquals(formData, newFormData);
        if (!isEquals) {
            const modifiedFormData = onModifyStateToPersist(newFormData);
            setFormData(modifiedFormData);
            onChange(value, {formId: id});
        }
    }, [formData, onChange]);

    const handleError = useCallback((e) => onError(e), [onError]);
    const handleClear = useCallback(() => setFormData(combinedData), [data]);
    const handleAction = useCallback((e) => onClickAction(e), [onClickAction]);

    useEffect(() => {
        setStatus({
            pristine: deepEquals(combinedData, formData),
            allDefinedValues: objHasAllDefinedValues(formData)
        });
    }, [formData]);

    useEffect(() => {
        if (cleanable) {
            handleClear();
        }
    }, [data]);

    return {
        formData,
        handleClear,
        status,
        handleChange,
        handleError,
        handleAction
    };
};