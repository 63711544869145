
import { Joi } from '../../../validation/rules';

import Config from '../config';
import { withDefaults } from '../..';
import { runAlreadyExistsPolicy } from '../../policies';

let Item = {
    context: Config.context,
    name: 'Item',
    schema: Joi.object().keys({
        // id => might be human descriptive name
        name: Joi.string(),
        asset: Joi.string().uri(), // Web URL
        // we will need to map them to a Device (which could be a mix of type + id logic due to the version)
    }),
    events: {
        ITEM_CREATED: {},
    },
    commands: {
        CREATE_ITEM: {
            checkPolicies: async (requestData, existingItem) => await Promise.all([
                runAlreadyExistsPolicy(requestData, existingItem),
            ]),
            get schema() {
                return Item.schema.fork('name', schema => schema.required());
            },
            get event() { return Item.events.ITEM_CREATED; },
        },
    },
    queries: {},
};
Item = withDefaults()(Item);

export default Item;