import { Button } from '@mui/material';

import { Text } from "ui/components/Text";
import { FlexBox, flexRowBeEvenly, flexRowJustifyItemsEnd } from "features/fulfilment/components/atoms/Layout";

import '../styles.css';
import { BackIcon } from "features/fulfilment/components/atoms/icons/fulfilmentIcons";
import { LinkInfo } from "features/fulfilment/components/atoms/LinkInfo";
import { SectionExclamation } from "features/fulfilment/components/atoms/SectionInfo";
import i18n from "services/server/functions/i18n";

import { Variant } from "features/fulfilment/components/constants/variant";
import { Color } from "features/fulfilment/components/constants/color";

import { getImgUrl, useAssetsURL } from 'ui/hooks/useAssetsUrl';

export const PhoneStickerStep = ({
    goToPreviousStep, goToScanStep
}) => {
    const { assetsURl } = useAssetsURL();
    return (
        <>
            <LinkInfo
                label={i18n.resolve('back')} Icon={BackIcon}
                onClick={() => goToPreviousStep()}
            />
            <div className="fulfilment page order-landing">
                <h1 className="title"><Text>fulfilment.order.process.title</Text></h1>
                <section className="section">
                    <FlexBox sx={{ width: '80%' }}>
                        <SectionExclamation label={i18n.resolve('fulfilment.order.process.phoneSticker.description')} />
                    </FlexBox>
                </section>
                <FlexBox sx={{ ...flexRowBeEvenly }}>
                    <img
                        className="process-order-phone-sticker-img"
                        alt="process order phone sticker"
                        src={getImgUrl(
                            assetsURl,
                            'process_order_phone-sticker.png'
                        )}
                    />
                </FlexBox>
                <FlexBox sx={flexRowJustifyItemsEnd}>
                    <Button
                        variant={Variant.Contained} color={Color.Secondary}
                        onClick={goToScanStep}
                        sx={{ marginLeft: '8px' }}
                    >
                        <Text>next</Text>
                    </Button>
                </FlexBox>
            </div>
        </>
    );
};