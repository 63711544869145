import { Button } from "@mui/material";

import { Variant } from "../../constants/variant";
import { Color } from "../../constants/color";

import './styles.css';
import { AppColors } from "../../themes";

const getVariant = (item, selected) => item?.label === selected?.label ? Variant.Selected : Variant.Outlined;

const getItemStyles = (item) => item?.bg ? {
    backgroundColor: item.bg,
    color: item?.color || AppColors.mediumAcuGrey,
    '&:hover': {
        color: item?.color || AppColors.mediumAcuGrey,
        backgroundColor: item.bg
    },
} : {};

export const ButtonGroup = ({ label, items, selected, handleChange, buttonSx }) => {
    const handleSelection = item => handleChange(item);
    return (
        <div className="button-group-component">
            <div className="button-group-component-grid-container">
                <span className="button-group-component-label">{label}</span>
                {items?.map(item => (
                    <Button
                        key={item?.label}
                        sx={{
                            width: '160px', height: '34px', ...buttonSx,
                            ...getItemStyles(item)
                        }}
                        variant={getVariant(item, selected)}
                        color={Color.Secondary}
                        onClick={()=> handleSelection(item)}
                    >
                        {item?.label} 
                    </Button>
                ))}
            </div>
        </div>
    );
}; 