import { Autocomplete, TextField } from "@mui/material";

import i18n from "services/server/functions/i18n";

const FIELD_ID = 'scan-order';

export const OrderField = ({ value, options = [], ...params }) => {
    return (
        <Autocomplete
            id={FIELD_ID}
            options={options}
            value={value}
            renderInput={(params) => <TextField {...params} label={i18n.resolve("fulfilment.order.reference.scan")} />}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            {...params}
        />
    );
};