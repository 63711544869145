import { Children, Fragment, useContext, useEffect } from "react";
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Item, KitTemplate } from "services/server/functions/model/fulfilment/model";
import useSnapshot, { useSnapshots } from 'ui/hooks/useSnapshot';
import Loading from 'ui/components/Loading';
import { isEmpty } from "ramda";
import { Text } from "ui/components/Text";
import { getStudyTestIds, prepareTests } from "services/server/functions/model/diagnosis/connector/study-report.connector";
import { UserContext } from "features/providers/userContextProvider";

export const useTest = ({ study }) => {
  const testIds = getStudyTestIds(study);
  const [testSnaps, loadingTests] = useSnapshots(...testIds);
  const testEntities = testSnaps?.data ? { [testSnaps.aggregate.id]: testSnaps } : (testSnaps || {});
  const tests = prepareTests({ study, testIds, testEntities });
  return { tests, loadingTests };
};

export const useStudy = ({ id }, options) => {
  const [study, loadingStudy] = useSnapshots(id, options);
  const { tests, loadingTests } = useTest({ study });
  const studyWithTests = study ? { ...study, data: { ...study.data, tests } } : undefined;
  return [studyWithTests, loadingStudy || loadingTests];
};

// export const WithStudy = props => <WithSnapshotV2 model={Study} {...props} />
export const WithStudy = ({ children, id, showLoading = true, LoadingComponent = Loading.Default, onNotFound = () => { }, onError = () => { } }) => {
  const { selectedProduct } = useContext(UserContext);
  const Child = Children.only(children);
  const [snapshot, loading] = useStudy({ id }, { onError });
  if (snapshot) snapshot.model = Study;
  const hasStudy = snapshot?.data && !isEmpty(snapshot.data);
  useEffect(() => {
    // Checking here if the study is not from the product because on useSnapshot we bypass the context filters if we only provide an id
    if (!loading && ((id === undefined || snapshot === undefined) || (snapshot && !snapshot.data.owners.includes(selectedProduct.id)))) {
      onNotFound();
    }
  }, [id, loading, snapshot, selectedProduct?.id]);

  return (
    <>
      {(showLoading && loading && !hasStudy) && <LoadingComponent loading={loading} />}
      <Child.type {...{ ...Child.props, [Study.name.toLowerCase()]: snapshot }} loading={loading} /> {/* eslint-disable-line react/jsx-pascal-case */}
    </>
  );
};

export const withKitTemplates = (...args) => (WrappedComponent) =>
  Object.assign((origProps) => {
    const [ids, options = {}] = args;
    const { loading: parentIsLoading, ...props } = origProps;
    const { showLoading = true, fallbackComponent: Fallback, loadingComponent: LoadingComponent = Loading.Default, ...snapshotOptions } = options;
    const [snapshots, loading, start, cancel] = useSnapshot(KitTemplate, ids, {
      ...snapshotOptions,
      onError: reason => {
        props.notify && props.notify('error', Text.resolve('App.loading-data-failed'), Text.resolve('App.loading-data-failed-resolution'));
        options.onError && snapshotOptions.onError(reason);
      }
    });
    const [itemsSnapshots, loadingItemsSnapshots] = useSnapshot(Item);

    const allKitTemplates = Object.values(snapshots).map(snap => {
      const items = snap.data.items.map(itemURN => {
        const itemSnap = itemsSnapshots[itemURN];
        return {
          id: itemURN,
          name: itemSnap?.data.name,
        };
      });
      return {
        ...snap,
        data: {
          ...snap.data,
          items,
        },
      };
    });
    const missingID = args.length >= 2 && ids === undefined && !parentIsLoading && !loadingItemsSnapshots;;
    const useFallback = Fallback && (missingID || (!loading && (allKitTemplates === undefined || Object.keys(allKitTemplates).length === 0)));

    return useFallback ? <Fallback {...origProps} />
      : <Fragment>
        {(showLoading) && <LoadingComponent loading={loading} />}
        <WrappedComponent loading={loading || parentIsLoading || loadingItemsSnapshots} {...{ [(typeof ids === 'string') ? 'kitTemplateSnap' : 'kitTemplatesSnaps']: allKitTemplates }} start={start} cancel={cancel} {...props} />
      </Fragment>;
  }, { displayName: `WithSnapshot(${WrappedComponent?.displayName || WrappedComponent?.name})` });