import Config from '../config';
import { Joi } from '../../../validation/rules';
import { withDefaults } from '../..';

const buildPayment = (parent) => withDefaults()({
  context: Config.context,
  name   : 'Payment',
  schema: Joi.object().unknown(true), // TODO: define properly Payment schema
  events: {
    PAYMENT_SESSION_STARTED : { },
    PAYMENT_SESSION_CHANGED : { },
    PAYMENT_SESSION_ENDED   : { },
  },
  commands: {
    START_PAYMENT_SESSION: {
      get schema() { return Joi.object(); },
      get event() { return buildPayment(parent).events.PAYMENT_SESSION_STARTED; }
    }
  }
}, parent);

const Payment    = buildPayment();
Payment.asEntity = buildPayment;

export { Payment }