import { SchemaExtractor, SchemaTransformer } from '../../../validation/schemaExtractor';
import { Joi } from '../../../validation/rules';
import { groupBy, getProperty, setProperty, flattenObject, deepMerge, deepClone } from "../../../helpers/utils";
import { getAllowedFieldsForAttributeRights } from './attributeRights';
import { AttributeRights } from '../../../iam/roles';

const isGroupRepresentative = field => "feature" in field.meta;
const getGroupRepresentative = groupFields => groupFields.find(isGroupRepresentative);

// When transforming the data to the form schema we deduce if a group field is empty differently than when whe transform from the schema to the data
const isGroupEmpty = (groupValue, groupFields, flattennedData, toSchema) => {
  return groupValue === undefined && (!toSchema || groupFields.every(f => flattennedData[f.field] === undefined));
};

const isGroupSet = (groupRepresentative, groupValue, groupFields, flattennedData, toSchema) => {
  if (isGroupEmpty(groupValue, groupFields, flattennedData, toSchema)) return false;
  if (!groupRepresentative) return Boolean(groupValue);
  if (groupRepresentative.meta.feature?.enabledForAnyValue && groupValue !== undefined) {
    return true;
  }
  if ("enabled" in groupRepresentative.meta.feature) {
    return groupValue === groupRepresentative.meta.feature.enabled;
  }
  // disabled
  return groupValue !== undefined && groupValue !== groupRepresentative.meta.feature.disabled;
};

const isGroupDisabled = (groupRepresentative, groupValue) => {
  if (groupValue === undefined) return false;
  if ("enabled" in groupRepresentative.meta.feature) return groupValue !== groupRepresentative.meta.feature.enabled;
  return groupValue === groupRepresentative.meta.feature.disabled;
};

const getValue = (field, group, flattennedData) => {
  let key;
  if (isGroupRepresentative(field)) {
    key = group;
  } else {
    key = field.field;
  }
  return flattennedData[key];
};

const getSchema = (schemaDetails, allowedFields = []) => {
  const fieldsByGroup = groupBy(schemaDetails.fields.filter(f => allowedFields.includes(f.field)), "group");

  const buildGroupProperty = (groupFields, group) => {
    const groupRepresentative = getGroupRepresentative(groupFields);
    if (groupRepresentative) {
      let field = schemaDetails.schema.extract(groupRepresentative.field).meta({ extension: { isGroupOwner: true, } });
      if (groupRepresentative.meta.preferences.format) field = field.meta({ extension: { format: groupRepresentative.meta.preferences.format } });
      if (groupRepresentative.meta.preferences.required) field = field.required();
      return field;
    }
    return Joi.boolean().meta({ extension: { isGroupOwner: true }, preferences: { id: schemaDetails.formId, group, order: parseInt(groupFields[0].meta.preferences.order) } });
  };

  const buildFieldGroupWhenCondition = (groupRepresentative) => {
    if (!groupRepresentative) return { is: false, then: Joi.strip() };
    if (groupRepresentative.meta.feature?.enabledForAnyValue) {
      // TODO: not needed for now (complex because... how should I make the comparison?)
      // return { is: groupRepresentative.meta.feature.enabled, otherwise: Joi.strip() };
    }
    if ("enabled" in groupRepresentative.meta.feature) {
      return { is: groupRepresentative.meta.feature.enabled, otherwise: Joi.strip() };
    }
    // disabled
    return { is: groupRepresentative.meta.feature.disabled, then: Joi.strip() };
  };

  const generatedSchema = Joi.object().keys(Object.keys(fieldsByGroup).reduce((acc, group) => {
    const groupFields = fieldsByGroup[group];
    if (groupFields.length === 0) return acc;

    const groupProperty = buildGroupProperty(groupFields, group);
    const groupRepresentative = getGroupRepresentative(groupFields);
    const whenCondition = buildFieldGroupWhenCondition(groupRepresentative);
    acc[group] = groupProperty;

    groupFields.forEach((field) => {
      if (groupRepresentative?.field === field.field) return;
      let schemaField = schemaDetails.schema.extract(field.field);
      if (field.meta.preferences.format) schemaField = schemaField.meta({ extension: { format: field.meta.preferences.format } });
      schemaField = SchemaTransformer.resetWhens(schemaField);
      if (field.meta.preferences.when) {
        let [fieldName, condition] = field.meta.preferences.when;
        fieldName = fieldName === group ? group : groupFields.find(f => f.field.endsWith(fieldName)).field;
        if (fieldName.includes('.')) {
          fieldName = Joi.ref(fieldName);
          fieldName.path = [fieldName.key]; // hack to allow dot notation on Joi without indicating a path!
        }
        schemaField = schemaField.when(fieldName, condition);
      } else {
        schemaField = schemaField.when(group, whenCondition);
      }
      if (field.meta.preferences.default) {
        schemaField = schemaField.default(field.meta.preferences.default);
      }
      acc[field.field] = schemaField;
      // If there is a group representative the whole group fields will be set to read only, therefore they should have a value assigned
      // if (groupRepresentative) acc[field.field] = acc[field.field].required();
    });
    return acc;
  }, {}));

  return generatedSchema;
};

// maps settings data to schema
const mapToSchema = (data = {}, schemaDetails, fieldToAttributeRights) => {
  const flattennedData = flattenObject(data);
  const potentialFieldsSet = new Set([...Object.keys(flattennedData), ...Object.keys(fieldToAttributeRights)]);

  const fieldsByGroup = groupBy(schemaDetails.fields.filter(f => potentialFieldsSet.has(f.field)), "group");

  const getGroupValue = (groupFields) => {
    const groupRepresentative = getGroupRepresentative(groupFields);
    if (groupRepresentative) {
      return flattennedData[groupRepresentative.field];
    }
    // Currently, all group fields should have the same attribute rights
    const groupAttributeRightsLength = fieldToAttributeRights[groupFields[0].field]?.length;
    if (groupAttributeRightsLength === 0) return false; // (no rights)
    if (groupAttributeRightsLength === 1 || groupFields.some(f => flattennedData[f.field] !== undefined)) return true; // (read)
    return undefined; // groupAttributeRightsLength === 2 (read & write)
  };

  const getGroupFieldValue = (field) => {
    return flattennedData[field.field];
  };

  const hasRightsForGroup = (groupFields) => {
    // Currently, all group fields should have the same attribute rights
    const groupFieldRightsLength = fieldToAttributeRights[groupFields[0].field]?.length;
    return Boolean(groupFieldRightsLength);
  };

  const mappedData = Object.keys(fieldsByGroup).reduce((acc, group) => {
    const groupFields = fieldsByGroup[group];
    const hasRights = hasRightsForGroup(groupFields);
    if (groupFields.length === 0 || !hasRights) return acc;

    const groupValue = getGroupValue(groupFields);
    const groupRepresentative = getGroupRepresentative(groupFields);
    const isSet = isGroupSet(groupRepresentative, groupValue, groupFields, flattennedData, true);
    // Wee need to set the group values for the group representatives that have a disabled groupValue
    if (groupValue !== undefined) acc[group] = groupValue;
    if (!isSet) return acc;
    acc[group] = groupValue;

    groupFields.forEach((field) => {
      if (groupRepresentative?.field === field.field) return;
      const groupFieldValue = getGroupFieldValue(field);
      if (groupFieldValue !== undefined) {
        acc[field.field] = groupFieldValue;
      }
    });
    return acc;
  }, {});

  if (mappedData.questionnaire === false) mappedData.questionnaire = "None";
  return mappedData;
};

// The attribute rights are built with the parent data overritten by the data
const mapToRightsAndData = (fields, data = {}, allowedFields = []) => {
  const flattennedData = flattenObject(data);
  const fieldsByGroup = groupBy(fields.filter(f => allowedFields.includes(f.field)), "group");

  const getAccessRight = (groupRepresentative, group, groupFields) => {
    const groupValue = flattennedData[group];
    if (isGroupEmpty(groupValue, groupFields, flattennedData)) return "Empty";
    if (isGroupSet(groupRepresentative, groupValue, groupFields, flattennedData) || (groupRepresentative && groupValue !== undefined)) return "Yes";
    return "No";
  };

  const mappedData = Object.keys(fieldsByGroup).reduce((acc, group) => {
    const groupFields = fieldsByGroup[group];
    const groupRepresentative = getGroupRepresentative(groupFields);

    // Currently, all group fields should have the same attribute rights
    const accessRight = getAccessRight(groupRepresentative, group, groupFields);

    groupFields.forEach((field) => {
      acc.rights[field.field] = accessRight;
      const fieldValue = getValue(field, group, flattennedData);
      if (fieldValue === undefined) return;
      // We need to set the group values for the group representatives that have a disabled fieldValue
      if (acc.rights[field.field] === "Yes" || (acc.rights[field.field] === "No" && isGroupRepresentative(field))) {
        setProperty(acc.data, field.field, fieldValue);
      }
    });
    return acc;
  }, { rights: {}, data: {} });

  return mappedData;
};

const ProductStudy = {
  get Study() { return require("../../diagnosis/model").Study },
  schemas: {
    StudyConfigSettings: {
      get fields() {
        delete this.fields;
        this.fields = SchemaExtractor.getWrappedFields(ProductStudy.Study.commands.CREATE_STUDY.schema, meta => meta?.preferences?.id === "StudyConfigSettings");
        return this.fields;
      },
      get oppositeFields() {
        delete this.oppositeFields;
        // TODO: improve by generating them automatically, not hardcoding them
        this.oppositeFields = [{ original: 'patient.instructions.alarmDisabled', opposite: 'patient.instructions.alarmEnabled' }, { original: 'freezeTestCancelledStatus', opposite: 'analyseCancelledTests' }];
        return this.oppositeFields;
      },
      get attributeRights() {
        delete this.attributeRights;
        const RIGHTS = ['read', 'write'];
        this.attributeRights = ProductStudy.schemas.StudyConfigSettings.fields.reduce((acc, field) => {
          RIGHTS.forEach(right => acc.push(AttributeRights.urn(`${field.field}/${right}`, ProductStudy.Study)));
          return acc;
        }, []);
        return this.attributeRights;
      },
      extractFromStudy(study) {
        const settings = ProductStudy.schemas.StudyConfigSettings.fields.reduce((acc, field) => {
          const value = getProperty(study, field.field);
          if (value === undefined) return acc;
          return setProperty(acc, field.field, value);
        }, {});
        return settings;
      },
      schemas: {
        Defaults: {
          get schema() {
            delete this.schema;
            const schema = ProductStudy.Study.commands.CREATE_STUDY.schema.tailor('defaults');
            this.schema = Joi.object().keys(ProductStudy.schemas.StudyConfigSettings.fields.reduce((acc, field) => {
              const joiField = schema.extract(field.field);
              return setProperty(acc, field.path, joiField);
            }, {}));
            return this.schema;
          },
        },
        Configure: {
          getSchema: (allowedFields = []) => {
            const schemaDetails = {
              formId: 'StudyConfigSettings',
              fields: ProductStudy.schemas.StudyConfigSettings.fields,
              schema: ProductStudy.Study.commands.CREATE_STUDY.schema,
            };
            return getSchema(schemaDetails, allowedFields);
          },
          mapToSchema: (data = {}, fieldToAttributeRights) => {
            const schemaDetails = {
              fields: ProductStudy.schemas.StudyConfigSettings.fields,
            };
            return mapToSchema(data, schemaDetails, fieldToAttributeRights);
          },
          // maps schema settings to rights and data
          mapToRightsAndData: (data, allowedFields) => {
            return mapToRightsAndData(ProductStudy.schemas.StudyConfigSettings.fields, data, allowedFields);
          },
          // compares if 2 settings are compatible
          isCompatible: (source = {}, target = {}) => {
            const mergedSettings = deepMerge(deepClone(source), target);
            const flattennedMergedSettings = flattenObject(mergedSettings);
            // TODO: using group the implementation would be incorrect and fieldToAttributeRights would be needed
            const fieldsByGroup = groupBy(ProductStudy.schemas.StudyConfigSettings.fields, "group");
            for (const group of Object.keys(fieldsByGroup)) {
              const groupFields = fieldsByGroup[group];
              const groupRepresentative = getGroupRepresentative(groupFields);
              // With the new approach, all groups have a group representative, at they might be the owner themselves, otherwise I would need the fieldToAttributeRights
              const groupValue = flattennedMergedSettings[groupRepresentative.field];
              const isDisabled = isGroupDisabled(groupRepresentative, groupValue);
              for (const groupField of groupFields.filter(f => f.field !== groupRepresentative.field)) {
                // If any of the child fields are set and the parent field is not set or disabled, then its not compatible
                if ((groupValue === undefined || isDisabled) && flattennedMergedSettings[groupField.field] !== undefined) {
                  return false;
                }
              }
            }

            return true;
          },
        },
      },
      // TODO: by change the special cases here belong to the only groups that have more values, however, we should always return the data of these values if those fields need to be renders, otherwise they might not because they are inside the allOf
      applySpecialCases: (specialFields, form, allowedData, data) => {
        const questionnaireThresholdPropKey = specialFields.questionnaireThreshold;
        const questionnairePropKey = specialFields.questionnaire;
        const onboardingPropKey = specialFields.onboarding;

        const questionnaireThresholdProp = form.allOf.find(p => p.else?.properties[questionnaireThresholdPropKey]);
        if (questionnaireThresholdProp) {
          questionnaireThresholdProp.if = {
            properties: {
              [questionnairePropKey]: {
                not: { const: "None" },
              },
              [onboardingPropKey]: {
                not: { const: false },
              },
            },
          };
          questionnaireThresholdProp.then = questionnaireThresholdProp.else;
          delete questionnaireThresholdProp.else;

          [questionnairePropKey, onboardingPropKey].forEach(key => {
            if (form.properties[key]) return; // We only need to add them to the data if they won't be there
            const value = getProperty(allowedData, key);
            if (value !== undefined) data[key] = value;
          });
        }

        const questionnaireProp = form.properties[questionnairePropKey];
        if (questionnaireProp) {
          const questionnaireAllOfProp = {
            if: {
              properties: {
                [onboardingPropKey]: {
                  const: false,
                }
              },
            },
            then: {
              properties: {
                [questionnairePropKey]: { ...questionnaireProp, enum: questionnaireProp.enum.filter(v => v !== "STOPBang"), isGroupOwner: true  }, // For some reason isGroupOwner prevents it from appearing twice
              },
            },
            else: {
              properties: {
                [questionnairePropKey]: { ...questionnaireProp, isGroupOwner: true },  // For some reason isGroupOwner prevents it from appearing twice
              },
            },
          };
          form.allOf.push(questionnaireAllOfProp);
          delete form.properties[questionnairePropKey];
          [onboardingPropKey].forEach(key => {
            if (form.properties[key]) return; // We only need to add them to the data if they won't be there
            const value = getProperty(allowedData, key);
            if (value !== undefined) data[key] = value;
          });
        }
      },
      getSpecialFields: (isCreate) => {
        if (isCreate) return {
          questionnaireThreshold: "patient.instructions.questionnaireThreshold",
          questionnaire: "patient.instructions.fillQuestionnaire",
          onboarding: "patient.instructions.requiresOnboarding",
        };
        return {
          questionnaireThreshold: "patient.instructions.questionnaireThreshold",
          questionnaire: "questionnaire",
          onboarding: "onboarding",
        };
      },
      onModifyStateToPersist: (specialFields) => (dataCausedByUser = {}) => {
        const questionnaireThresholdPropKey = specialFields.questionnaireThreshold;
        const questionnairePropKey = specialFields.questionnaire;
        const onboardingPropKey = specialFields.onboarding;
        
        if (dataCausedByUser[onboardingPropKey] === false) {
          if (dataCausedByUser[questionnairePropKey] === "STOPBang") dataCausedByUser[questionnairePropKey] = undefined;
          if (dataCausedByUser[questionnaireThresholdPropKey] !== undefined) dataCausedByUser[questionnaireThresholdPropKey] = undefined;
        }

        if ((dataCausedByUser[questionnairePropKey] === "STOPBang" || dataCausedByUser[questionnaireThresholdPropKey] !== undefined) && dataCausedByUser[onboardingPropKey] !== false) {
          dataCausedByUser[onboardingPropKey] = false;
        }

        return dataCausedByUser;
      },
    },
    StudyTemplateSettings: {
      get fields() {
        delete this.fields;
        // CAREFUL: clinicianAnalysis is an open input type (a string)!
        this.fields = SchemaExtractor.getWrappedFields(ProductStudy.Study.commands.CREATE_STUDY.schema, meta => meta?.preferences?.id === "StudyTemplateSettings");
        return this.fields;
      },
      getSchema: (allowedFields = []) => {
        const schemaDetails = {
          formId: 'StudyTemplateSettings',
          fields: ProductStudy.schemas.StudyTemplateSettings.fields,
          schema: ProductStudy.Study.commands.CREATE_STUDY.schema,
        };
        return getSchema(schemaDetails, allowedFields);
      },
      // maps schema settings to data
      mapToData: (data) => {
        const flattennedData = flattenObject(data);
        const fieldsByGroup = groupBy(ProductStudy.schemas.StudyTemplateSettings.fields, "group");

        const mappedData = Object.keys(fieldsByGroup).reduce((acc, group) => {
          const groupFields = fieldsByGroup[group];
          groupFields.forEach((field) => {
            const fieldValue = getValue(field, group, flattennedData);
            if (fieldValue === undefined) return;
            setProperty(acc, field.field, fieldValue);
          });
          return acc;
        }, {});

        return mappedData;
      },
      mapToSchema: (data = {}, fieldToAttributeRights) => {
        const schemaDetails = {
          fields: ProductStudy.schemas.StudyTemplateSettings.fields,
        };
        return mapToSchema(data, schemaDetails, fieldToAttributeRights);
      },
      // It doesn't strictly for read/write, just that it has access to that field, hence, mainly read
      getSupportedSettings: (product) => {
        return getAllowedFieldsForAttributeRights(product.attributeRights, ProductStudy.schemas.StudyTemplateSettings.fields.map(f => f.field));
      },
      isCompatible: () => {
        return true;
      },
    },
  },
};

export default ProductStudy;