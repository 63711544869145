import { memo, useRef, useMemo, useState, useEffect } from 'react';
import { withTheme } from '@rjsf/core';
import validator from "@rjsf/validator-ajv8";

import { baseSchemaFormTheme } from './theme';
import { useSchemaFormHandler } from './hooks/useSchemaFormHandler';
import { ActionsTemplate } from './templates/ButtonTemplates/ActionsTemplate';
import { getSchemaFormConfig } from './utils/formUtils';
import { getFormOptionsFromSchema } from './utils/uiUtils';

export const SchemaForm = memo(({
    formRef,
    id,
    schema,
    uiSchema = {},
    properties = [],
    data,
    actions = [],
    formContext = {},
    formOpts = {},
    onChange = (e, metadata) => console.log("changed", e, metadata),
    onModifyStateToPersist,
    onClickAction = (e) => console.log("onClickAction", e),
    onError = (e) => console.log("errors", e),
    cleanable = true,
    theme
}) => {
    const toRef = formRef || useRef();
    const [liveValidation, setLiveValidation] = useState(false);

    // The use of useMemo is super important here! Otherwise it causes a re-render of the entire form on each change!
    const [customTheme, customUiSchema = {}] = useMemo(() => theme || baseSchemaFormTheme({ properties }), [theme, properties]);
    const ThemedForm = useMemo(() => withTheme(customTheme), [customTheme]);
    const {
        formData,
        status,
        handleClear,
        handleChange,
        handleError,
        handleAction
    } = useSchemaFormHandler({ id, data, properties, onChange, onModifyStateToPersist, onClickAction, onError, cleanable });
    const generateActions = (props) => (
        <ActionsTemplate
            {...props}
            formId={id}
            cleanable={cleanable}
            formData={formData}
            status={props.registry.formContext.status}
            actions={actions}
            handleClear={handleClear}
            onClickAction={handleAction}
            disabled={props.registry.formContext.status.pristine || !props.registry.formContext.status.allDefinedValues}
            formRef={toRef}
        />
    );
    const submitOptions = { props: { generateActions } };
    const finalUISchema = {
        ...uiSchema,
        // merges properties uiSchema with customUiSchema
        ...Object.keys(customUiSchema).reduce((acc, k) => {
            acc[k] = customUiSchema[k];
            if (properties.some(p => p.prop === k)) acc[k] = { ...acc[k], ...uiSchema[k] };
            return acc;
        }, {}),
        'ui:submitButtonOptions': submitOptions,
    };
    useEffect(() => {
        if (toRef.current?.validateForm && !liveValidation) {
            const handler = {
                apply: function() {
                    setLiveValidation(true);
                    return Reflect.apply(...arguments);
                },
            };
            toRef.current.validateForm = new Proxy(toRef.current.validateForm, handler);
        }
    }, [toRef.current?.validateForm, liveValidation]);
    return (
        <>
            <ThemedForm
                id={id}
                ref={toRef}
                schema={schema}
                validator={validator}
                formContext={{
                    ...formContext,
                    formOpts,
                    status, // had to add the status here, otherwise the buttons are not properly updated since there are no changes in its props
                }}
                formData={formData}
                uiSchema={finalUISchema}
                onChange={handleChange}
                onError={handleError}
                liveValidate={liveValidation}
            />
        </>
    );
});

export const SchemaFormList = ({ schemas = {}, formProps, labelsPerForm = {} }) => (
    <>
        {Object.keys(schemas)?.map((k) => {
            const schema = schemas[k];
            const id = k;
            const schemaFormProps = schema?.formProps || {};
            const formOpts = getFormOptionsFromSchema(id, formProps);
            const labels = labelsPerForm[k];
            return schema?.form ? (
                <div key={k} className="pageSection" id="templateSettings">
                    {
                        labels?.title && (
                            <div className="sectionHeader">
                                <div className="title">
                                    {labels.title}
                                </div>
                            </div>
                        )
                    }
                    <SchemaForm
                        {...getSchemaFormConfig({ schema: schema.form, id })}
                        id={id}
                        schema={schema.form}
                        data={schema?.data}
                        actions={schema?.actions}
                        {...formProps}
                        {...schemaFormProps}
                        formOpts={formOpts}
                    />
                </div>
            ) : null
        })}
    </>
);