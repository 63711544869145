import { FlexBox, flexRowCenter } from "../Layout";
import { AppColors } from "../../themes";

export const LinkInfo = ({ label, Icon, ...props }) => {
    const sx = {
        ...flexRowCenter, padding: '8px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: AppColors.acuLink
        }
    };
    return (
        <FlexBox sx={sx} {...props} >
            {Icon && <FlexBox><Icon width="20px" height="20px" /></FlexBox>}
            <FlexBox sx={{ color: AppColors.acuLink }}>
                {label}
            </FlexBox>
        </FlexBox>
    );
};