import { rxExecute as execute, rxExecuteBatch as executeBatch } from 'modules/executor';

import { publish } from 'modules/router';
import Config from './config';
import { OrderPage } from './Order';
import { OrderLanding as OrderLandingPage } from './Order/Landing';
import { ProcessOrder as ProcessOrderPage } from './Order/Process';
import { ViewOrder as ViewOrderPage } from './Order/View';

import { KitPage } from './Kit';
import { CreateKit as CreateKitPage } from './Kit/Create';
import { ReturnedKit as ReturnedKitPage } from './Kit/Returned';


const getModel = (aggrModels, aggr) => aggr.split('/').reduce((model, aggrName, idx) => idx ? model.entities[aggrName] : model[aggrName], aggrModels);
const toAction = (aggr, action) => (args, options) => (dispatch) => import('services/server/functions/model/fulfilment/model').then(model => execute(getModel(model, aggr).actions[action].newRequest(args), options)(dispatch)).catch(e => console.error(e) || Promise.reject(e));
const toRequest = (aggr, action) => (args) => import('services/server/functions/model/fulfilment/model').then(model => model[aggr].actions[action].newRequest(args)).catch(e => console.error(e) || Promise.reject(e))
const actionAsBatch = (aggr, action) => (data, onProgress, prevResults) => {
    return (dispatch) => {
        return Promise.all(data.map(d => toRequest(aggr, action)(d)))
            .then(async reqs => {
                const fn = executeBatch(reqs, { batch: { onProgress, results: prevResults } });
                const res = await fn(dispatch);
                return res;
            });
    };
};

const cleanSetCourierLabelsRequestDetails = (reqDetails) => {
    const data = { id: reqDetails.id, shipping: {} };
    // To omit the request mapping undefined to null values
    if (reqDetails.shipping?.inbound) data.shipping.inbound = reqDetails.shipping.inbound;
    if (reqDetails.shipping?.outbound) data.shipping.outbound = reqDetails.shipping.outbound;

    return data;
};

const actions = {
    // Kit
    prepareNewKitBatch: actionAsBatch('Kit', 'PREPARE_NEW_KIT'),
    markKitAsValidated: toAction('Kit', 'MARK_AS_VALIDATED'),

    // Order
    setCourierLabels: (order) => {
        const data = cleanSetCourierLabelsRequestDetails(order);
        return toAction('Order', 'SET_COURIER_LABELS')(data);
    },
    setCourierLabelsBatch: (orders, onProgress, prevResults) => {
        const data = orders.map(cleanSetCourierLabelsRequestDetails);
        return actionAsBatch('Order', 'SET_COURIER_LABELS')(data, onProgress, prevResults);
    },
    processOrder: toAction('Order', 'PROCESS_ORDER'),
    markOrderAsValidated: toAction('Order', 'MARK_AS_VALIDATED'),
    addOrderComment: toAction('Order/Comment', 'SET_COMMENT'),
};

export const OrderLanding = publish(Config.routes.Landing, actions)(OrderLandingPage, { page: "OrderLanding" });

export const Kit = publish(Config.routes.Kits, actions)(KitPage, { page: "KitPage" });
export const CreateKit = publish(Config.routes.CreateKit, actions)(CreateKitPage, { page: "CreateKitPage" });
export const ReturnedKit = publish(Config.routes.ReturnedKit, actions)(ReturnedKitPage, { page: "ReturnedKitPage" });

export const Order = publish(Config.routes.Orders, actions)(OrderPage, { page: "FindOrder" });
export const ProcessOrder = publish(Config.routes.ProcessOrder, actions)(ProcessOrderPage, { page: "ProcessOrderPage" });
export const ViewOrder = publish(Config.routes.ViewOrder, actions)(ViewOrderPage, { page: "ViewOrder" });
