////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////
import { isBrowser } from '../env';

// TODO: define smart Error class to handle errors as defined in docs requirements
/*class MyError extend Error {
  isBusinessError: false,
  isSystemError: false,
  isTransient: false,
  constructor(id, code, cause, resolution, details) {
    ...
  }

  is(errorType) {
    return errorType.isAn(this)
  },

  toUserFriendlyMessage() {
    return i18n(...)
  }

  toDetailedMessage() {
    return ... 
  }
}

class BusinessError extend MyError {
  isBusinessError: true
}
class SystemError extend MyError {
  isSystemError: true
}
class RetriableError extend SystemError {
  isTransient: true,
  retry(maxretries) {
    this.details.action.metadata.retries += 1;
    if (maxretries > this.request.metadata.retries) {
      return exec(this.request)
    } else {
      return this;
    }
  }
}

const ErrorType = (uniqueCode, errorClass, defaultCauseMessage, defaultResolutionMessage) => Object.freeze({
  code: uniqueCode,
  class: errorClass,
  likelyCause: defaultCauseMessage,
  likelyResolution: defaultResolutionMessage,
  raise: (details, cause=defaultCauseMessage, resolution=defaultResolutionMessage) => throw new errorClass(ulid(), uniqueCode, cause, resolution, details)
  is: (error) => error insteanceof errorClass
})

const BusinessErrorType = (code, cause, resolution) => ErrorType(code, BusinessError, cause, resolution)
const SystemErrorType = (code, cause, resolution) => ErrorType(code, SystemError, cause, resolution)
const RetriableErrorType = (code, cause, resolution) => ErrorType(code, RetriableError, cause, resolution)

export const PERMISSION_DENIED = SystemErrorType("PERMISSION_DENIED", "You nasty boy!", "Ask your manager");
export const NETWORK_ERROR = RetriableErrorType("NETWORK_ERROR", "Upps... dam slow internet connection", "Maybe retry later");

export default Object.freeze({
  PERMISSION_DENIED,
  NETWORK_ERROR,
  ...
})

// Usage Examples
try {
  PERMISSION_DENIED.raise({action, user})

} catch (error) {
  if (error.isTransient) {
    tellUser("something happened, let me retry it for you ...")
    error.retry().then(
      tellUser("sorted!")      
    ).catch(e => 
      tellUser(error.toMaxRetriesReachedUserFriendlyMessage()
    )
  } esle {
    tellUser(error.toUserFriendlyMessage())
  }
}*/

export let reportError = async (e) => console.error(e);

if (isBrowser()) {
  const StackdriverErrorReporter = require('stackdriver-errors-js');

  require('../firebase/config/config.client').onRegionChanged((_region, config) => {
    console.debug("Starting error reporting service")
    const {apiKey: key, projectId, appVersion: version, isStandardEnv} = config; 
    const errorHandler = isStandardEnv && window.location.hostname !== 'localhost' ? new StackdriverErrorReporter() : undefined; // eslint-disable-line no-undef
    if (errorHandler) errorHandler.start({key, projectId, version});

    return reportError = async (e) => {
      require('@sentry/browser').captureException(e);
      const user = await require('../../../iam').currentUser();
      if (errorHandler) {
        errorHandler.setUser(user?.data.id || "Anonymous");
        errorHandler.report(e);
      } else {
        console.error("Error reporting handler not initialised, error could not be reported")
        console.error(e);
      }
      return e;
    }
  });
  
}

////////////////
////////////////////////////////////////////////////////////

///////////////////
/////////////////////////////////////////////////////////////////////////////////
 
//////////

export const getErrorData = (err, field) => {
  try {
    const data = err?.data || err;
    const parsedData = typeof(data) === "string" ? JSON.parse(data) : data;
    return field ? parsedData?.[field] : parsedData;
  } catch (e) {
    return undefined;
  }
}

export const wrapError = (message, origError) => {
  // From https://stackoverflow.com/questions/42754270/re-throwing-exception-in-nodejs-and-not-losing-stack-trace
  let newError = new Error(`${message}: ${origError?.message || "Unknown error"}`);
  newError.stack = newError.stack.split('\n').slice(0,1).join('\n') + '\n' + origError.stack;
  return newError;
}

export const newError = (code, sourceError, reason, resolution) => ({
  code: code,
  reason: reason || require('./messages').getReason(code),
  resolution: resolution || require('./messages').getResolution(code),
  details: sourceError.message || sourceError.details
});

let _HttpsError = () => {};
////////////////
///////////////////
/////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
////
 
//////////

export const HttpsError = _HttpsError;
